import api from "./api";

class CouponService {
    async createCoupon(couponData, departments) {
        const requestBody = {
            ...couponData,
            departments
        }
        
        const response = await api.post("admin/coupon/create", requestBody);
        return response;        
    }

    async availableDepartments() {
        const response = await api.get("admin/coupon/available-departments");
        return response;
    }

    async getCouponById(id) {
        const response = await api.get(`admin/coupon/show?id=${id}`);
        return response;
    }

    async getAllCoupons(page, postsPerPage) {
        const response = await api.get(`admin/coupon/list?page=${page}&per_page=${postsPerPage}`);
        return response;
    }

    async editCoupon(couponData) {
        const response = await api.put("admin/coupon/edit", couponData);
        return response;
    }


    async deleteCouponById(couponId) {
        const response = await api.delete(`admin/coupon/delete?id=${couponId}`);
        return response;
    }
}

export default new CouponService();