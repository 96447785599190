import { useState } from "react";
import UserService from "../../services/UserService";
import AuthContext from "./AuthContext";

const AuthProvider = ({ children }) => {
    const [authData, setAuthData] = useState({
        token: null,
        user: null,
        isAuthenticated: null
    });

    const handleAuthenticateUser = atuhenticationData  => {
        const user = atuhenticationData?.user;
        const token = atuhenticationData?.token;
        localStorage.setItem("auth-token", token);

        setAuthData(prevState => {
            return {
                ...prevState,
                token: token,
                user: user,
                isAuthenticated: true
            }
        });
    }

    const handleGetUserSession = () => {
        UserService.session().then((response) => {
            const { data } = response || {};
            
            if(data) {
                setAuthData(prevState => {
                    return {
                        ...prevState,
                        user: data,
                        isAuthenticated: true
                    }
                })
            }
        }, () => {
            setAuthData(prevState => {
                return {
                    ...prevState,
                    isAuthenticated: false
                }
            });
        });
    }

    const handleSignOutUser = () => {
        setAuthData({
            token: null,
            user: null,
            isAuthenticated: null
        });

        localStorage.removeItem("auth-token");
        window.location.href = "/auth/login";
    }

    const authContext = {
        token: authData.token,
        user: authData.user,
        isAuthenticated: authData.isAuthenticated,
        authenticateUser: handleAuthenticateUser,
        getUserSession: handleGetUserSession,
        signOutUser: handleSignOutUser
    }

    return (
        <AuthContext.Provider value={authContext}>
            { children }
        </AuthContext.Provider>
    )
}

export default AuthProvider;