import api from "./api";

class CeltaProductService {
    /**
     * Get all products (paginated)
     * @param {number} page 
     * @param {number} per_page 
     * @param {string} query 
     * @param {string} ordenation
     * @return {Promise}
     */
    getAllProducts = (page, per_page, query, ordenation = 'ASC') => {
        return api.get(`/celta/products/index?page=${page}&per_page=${per_page}&query=${query}&order=${ordenation}`);
    }

    /**
     * Get product by 'product_internal_code'
     * @param {string} product_internal_code
     * @return {Promise}
     */
    getProductByCode = (product_internal_code) => {
        return api.get(`/celta/products/${product_internal_code}/show`);
    }

    /**
     * Update product by 'product_internal_code'
     * @param {string} product_internal_code
     * @param {object} data
     * @return {Promise}
     */
    updateProduct = (product_internal_code, data) => {
        return api.post(`products/${product_internal_code}/update/`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }

    /**
    * Get the departments which has available products in e-commerce
    * 
    * @return {Promise}
    */
    getAvailableDepartments = () => {
        return api.get("/celta/products/available-departments");
    }

    /**
    * Get an product of an department using his name
    * @param {string} userSearch
    * @return {Promise}
    */
    searchProductByNameOrCode = (userSearch) =>  {
        return api.get(`/celta/products/search?query=${userSearch}`);
    }
}

export default new CeltaProductService();