import toast from "react-hot-toast";

class CkEditorUploadAdapter {
    constructor(loader) {
        this.loader = loader;
        this.imageUrl = process.env.REACT_APP_IMAGE_BASE_URL;
        this.url = process.env.REACT_APP_API_BASE_URL + '/store/blog/image/store';
    }

    upload() {
        return new Promise((resolve, reject) => {
            this._initRequest();
            this._initListeners(resolve, reject);
            this._sendRequest();
        });
    }

    abort() {
        if (this.xhr) {
            this.xhr.abort();
        }
    }

    _initRequest() {
        const xhr = this.xhr = new XMLHttpRequest();

        xhr.responseType = 'json';
        xhr.open('POST', this.url, true);
        xhr.setRequestHeader('accept', 'application/json;charset=UTF-8');
    }

    _initListeners(resolve, reject) {
        const xhr = this.xhr;
        const loader = this.loader;

        xhr.addEventListener('error', () => toast.error('Erro ao salvar a imagem'));
        xhr.addEventListener('abort', () => toast.error('Erro ao salvar a imagem'));
        xhr.addEventListener('load', () => {
            const response = xhr.response; 
            if (!response || response.errors) {
                return toast.error('Erro ao salvar a imagem');
            }
            
            response.default = this.imageUrl + response.image_path;
            resolve(response);
        });

        if(xhr.upload) {
            xhr.upload.addEventListener('progress', evt => {
                if(evt.lengthComputable) {
                    loader.uploadTotal = evt.total;
                    loader.uploaded = evt.loaded;
                }
            });
        }
    }

    _sendRequest() {
        this.loader.file.then((blogImage) => {
            const data = new FormData();

            data.append('blogImage', blogImage);
            this.xhr.send(data);
        });
    }   
}

export default CkEditorUploadAdapter;