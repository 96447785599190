import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";

import OnlyBannerSection from "./OnlyBannerSection";
import SingleBannerSection from "./SingleBannerSection";
import MultipleBannerSection from "./MultipleBannerSection";

function CreateCustomizedSection() {
    const [sectionType, setSectionType] = useState(1);

    return(
        <div className="content">
            <Row>
                <Col>
                    <h3>Seção customizada</h3>
                    <Card>
                        <CardHeader>
                            <h6>Criar uma seção customizada na página principal</h6>
                            <hr />    
                        </CardHeader>
                        <CardBody>
                            <Col md="12" className="mb-5">
                                <Link to="../pagina-principal">
                                    <Button>Editar a seção customizada</Button>
                                </Link>
                            </Col>
                            <Row>
                                <Col>
                                    <h6>Tipo da seção</h6>
                                </Col>
                            </Row>
                            <Row className="mb-5">
                                <Col md="4" className="d-flex">
                                    <Button className="mt-1" onClick={() => setSectionType(3)}>
                                       1 banners apenas 
                                    </Button>
                                </Col>

                                <Col md="4" className="d-flex">
                                    <Button  className="mt-1" onClick={() => setSectionType(1)}>
                                         1 Banner e 6 produtos
                                    </Button>
                                </Col>

                                <Col md="4" className="d-flex">
                                    <Button className="mt-1" onClick={() => setSectionType(2)}>
                                       2 banners e 3 produtos 
                                    </Button>
                                </Col>
                            </Row>
                            {sectionType === 1 && <SingleBannerSection />}
                            {sectionType === 2 && <MultipleBannerSection />}
                            {sectionType === 3 && <OnlyBannerSection />}
                        </CardBody>       
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default CreateCustomizedSection;