import { useEffect, useState } from "react";
import Switch from "react-switch";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { Button, Card, CardHeader, CardBody, CardFooter, FormGroup, Form, Input, Row, Col, Label } from "reactstrap";
import CouponService from "services/CouponService";
import datehelper from "helpers/datehelper";

function EditCoupon(props) {
    // const [departments, setDepartments] = useState([]);
    // const [availableDepartments, setAvailableDepartments] = useState([]);
    const [couponData, setCouponData] = useState({
        id: props.match.params.id,
        coupon_name: "",
        type: "",
        value: "",
        quantity: "",
        start_date: "",
        discount_value: 0,
        expire_date: "",
        is_active: true,
        is_single_use: false,
        all_departments: true
    });

    useEffect(() => {
        getCouponData();
        // getAvailableDepartments();
    }, []);

    const getCouponData = () => {
        const id = props.match.params.id;

        CouponService.getCouponById(id).then(response => {
            const { data } = response;
            setCouponData(data)
        });
    }

    // const getAvailableDepartments = () => {
    //     CouponService.availableDepartments().then(response => {
    //         const { data } = response;
    //         setAvailableDepartments(data);
    //     });
    // }

    const handleCouponDataChange = (couponDataKey, value) => {
        setCouponData(prevState => {
            return {
                ...prevState,
                [couponDataKey]: value
            }
        });
    }

    const handleCreateCoupon = () => {
        if(couponData?.type === "") {
            toast.error("Campo tipo de desconto obrigatório.");
            return;
        }

        if(Date.parse(couponData.start_date) > Date.parse(couponData.expire_date)){
            toast.error('A data inicial não pode ser maior que a final');
            return;
        }

        // if(departments.length === 0) {
        //     toast.error('Selecione pelo menos um departamento.');
        //     return;
        // }

        toast.promise(CouponService.editCoupon(couponData), {
            loading: "Editando cupom",
            success: "Cupom editado com sucesso",
            error: "Não foi possível editar o cupom"
        }).then(response => {
            setTimeout(() => {
                props.history.push("/admin/descontos/cupons")
            }, 2000);
        });
    }

    // const handleAddDepartmentToCoupon = (departmentId) => {
    //     const departmentAlreadyAdded = departments.includes(departmentId);

    //     if(departmentAlreadyAdded) {
    //         removeDepartment(departmentId);
    //     } else {
    //         addDepartment(departmentId);
    //     }
    // }

    // const removeDepartment = (departmentId) => {
    //     if(departments.length === availableDepartments.length) {
    //         setCouponData(prevState => {
    //             return {
    //                 ...prevState,
    //                 all_departments: false
    //             }
    //         });
    //     }

    //     setDepartments(departments.filter(value => value !== departmentId));
    // }

    // const addDepartment = (departmentId) => {
    //     if(departments.length + 1 === availableDepartments.length) {
    //         setCouponData(prevState => {
    //             return {
    //                 ...prevState,
    //                 all_departments: true
    //             }
    //         });
    //     }

    //     setDepartments(prevState => ([...prevState, departmentId]));
    // }

    // const addAllDepartments = () => {

    //     if(couponData.all_departments) {
    //         setDepartments([]);

    //         setCouponData(prevState => {
    //             return {
    //                 ...prevState,
    //                 all_departments: false
    //             }
    //         });
    //     } else {
    //         const departments = availableDepartments.map(department => department.CodigoInterno_DepartamentoCommerce);
            
    //         setDepartments(departments);
    //         setCouponData(prevState => {
    //             return {
    //                 ...prevState,
    //                 all_departments: true
    //             }
    //         });
    //     }
    // }

    return(
        <div className="content">
            <div>
                <h3 className="title">Editar cupom</h3>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <p className="category">*Preencha os campos abaixo para editar o cupom</p>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label>
                                                <h6>Nome do cupom</h6>
                                            </Label>
                                            <Input type="text" name="code" placeholder="Digite aqui o código do cupom" value={couponData?.coupon_name} onChange={event => handleCouponDataChange("coupon_name", event.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3" className="d-flex justify-content-center align-items-center">
                                        <Label className="mt-3">
                                            <h6>
                                                Inativo / Ativo
                                            </h6>
                                        </Label>
                                        <Switch
                                            width={45}
                                            height={20}
                                            className="ml-2"
                                            onColor="#8c5899"
                                            checked={couponData?.is_active}
                                            onChange={value => handleCouponDataChange('is_active', value)}
                                        />
                                    </Col>
                                    <Col md="3" className="d-flex justify-content-center align-items-center">
                                        <Label className="mt-3">
                                            <h6>
                                                Apenas um uso por usuário
                                            </h6>
                                        </Label>
                                        <Switch
                                            width={45}
                                            height={20}
                                            className="ml-1"
                                            onColor="#8c5899"
                                            checked={couponData?.is_single_use}
                                            onChange={value => handleCouponDataChange('is_single_use', value)}
                                        />
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    {/* 
                                    <Col md="12">
                                        <Label>
                                            <h6>Departamentos</h6>
                                        </Label>
                                    </Col>
                                    <Col md="12" className="my-3">
                                        <Label>
                                            <h6>Disponível em todos os departamentos</h6>
                                        </Label>
                                        <Input 
                                            type="checkbox" 
                                            className="ml-2" 
                                            checked={couponData.all_departments}
                                            onChange={addAllDepartments} 
                                        />
                                    </Col>

                                    {availableDepartments?.map(department => (
                                        <Col md="4" key={department?.CodigoInterno_DepartamentoCommerce}>
                                            <Label>
                                                <h6>{department?.Nome_DepartamentoCommerce}</h6>
                                            </Label>
                                            <Input 
                                                type="checkbox"
                                                className="ml-2"
                                                checked={departments?.includes(department?.CodigoInterno_DepartamentoCommerce)}
                                                onChange={() => handleAddDepartmentToCoupon(department?.CodigoInterno_DepartamentoCommerce)}
                                            />
                                        </Col>
                                    ))} */}
                                </Row>
                                {/* <hr /> */}
                                <Row>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label>
                                                <h6>Tipo de desconto</h6>
                                            </Label>
                                            <select className="form-control" name="discount-type" value={couponData?.discount_type} required onChange={event => handleCouponDataChange("discount_type", event.target.value)}>
                                                <option disabled value="">- Escolha o tipo do desconto</option>
                                                <option value="value">Desconto por valor líquido</option>
                                                <option value="percentage">Desconto por porcentagem</option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md="4" className="d-flex align-items-center">
                                        <Col md="11">
                                            <FormGroup>
                                                <Label>
                                                    <h6>Valor do cupom</h6>
                                                </Label>
                                                <Input type="number" name="value" placeholder="Insira o valor do cupom" min="1" value={couponData?.discount_value}  onChange={event => handleCouponDataChange("discount_value", event.target.value)}/>
                                            </FormGroup>
                                        </Col>
                                        <Col md="1" className="m-0 p-0">
                                            {couponData?.type !== "" &&
                                                <div className={couponData?.type === "value" ? "badge badge-pill badge-success" : "badge badge-pill badge-primary"}>
                                                    <span>{couponData?.type === "value" ? "R$" : "%"}</span>
                                                </div>                                                
                                            }
                                        </Col>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label>
                                                <h6>Quantidade máxima para uso</h6>
                                            </Label>
                                            <Input type="number" name="usage_amount" placeholder="Insira a quantidade de uso" min="1" value={couponData?.quantity} onChange={event => handleCouponDataChange("quantity", event.target.value)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label>
                                                <h6>Data de início</h6>
                                            </Label>
                                            <Input type="date" name="start_date" placeholder="Insira a data de início de uso do cupom" value={datehelper.toYYYYMMDD(couponData?.start_date)} onChange={event => handleCouponDataChange("start_date", event.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6">
                                        <FormGroup>
                                            <Label>
                                                <h6>Data de término</h6>
                                            </Label>
                                            <Input type="date" name="end_date" placeholder="Insira a data de término de uso do cupom" value={datehelper.toYYYYMMDD(couponData?.expire_date)} onChange={event => handleCouponDataChange("expire_date", event.target.value)} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <CardFooter className="text-center">
                                    <Link to="/admin/descontos/cupons">
                                        <Button className="btn-danger mr-5">
                                           Voltar
                                        </Button>
                                    </Link>
                                    <Button className="btn-success" onClick={handleCreateCoupon}>Editar</Button>
                                </CardFooter>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default EditCoupon;