import toast from "react-hot-toast";
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ModalWindow from 'components/ModalWindow/ModalWindow';
import { Button, Card, CardBody, CardHeader, Col, Row, Table} from 'reactstrap';

import ImageService from 'services/ImageService';

const logosUrl = process.env.REACT_APP_IMAGE_BASE_URL;

function Index() {
    const [logos, setLogos] = useState(null);
    const [deleteLogo, setDeleteLogo] = useState(
        {
            logoToDelete: null,
            showModal: false
        }
    );
    useEffect(() => {
        getAllLogos();
    }, []);

    const getAllLogos = () => {
        toast.promise(ImageService.getAllLogos(), {
            loading: 'Buscando...',
            success: 'Pronto!'
        }).then(data => {
            setLogos(data);
        });
    }

    const handleDeleteLogo = () => {
        if (!deleteLogo.showModal) {
            return null;
        }

        return (
            <ModalWindow show={deleteLogo.showModal} printable={false} width="300px">
                <Card>
                    <CardHeader>
                        <Row>
                            <Col style={{ textAlign: 'end', marginTop: '-5px' }}>
                                <Button className="btn btn-danger btn-icon lanamaria-icons icon-simple-remove" title="Fechar" onClick={() => setDeleteLogo({ ...deleteLogo, logoToDelete: null, showModal: false })} />
                            </Col>
                            <Col md="12">
                                <h6 style={{ fontSize: '26px', textTransform: 'initial', marginTop: '4px' }}>Confirmar exclusão</h6>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <p>Você confirma a exclusão do logo nº {deleteLogo?.logoToDelete?.id}</p>
                        <small style={{ color: 'black' }}>
                            <b>*Obs.: ao confirmar, o logo será deletado permanentemente</b>
                        </small>
                        <hr />
                        <Button className="btn btn-blue" type="submit" title="Excluir logo" style={{ width: '100%' }} onClick={() => {
                            setDeleteLogo({ ...deleteLogo, logoToDelete: null, showModal: false })
                            deleteLogoById(deleteLogo?.logoToDelete?.id);
                        }}>Sim</Button>
                    </CardBody>
                </Card>
            </ModalWindow>
        )
    }

    const deleteLogoById = (id) => {
        toast.promise(ImageService.deleteLogoById(id), {
            loading: 'Deletando banner...',
            success: (data) => 'Banner deletado com sucesso!',
        }).then((data) => {
            setTimeout(() => {
                getAllLogos();
            }, 1000);
        });
    }

    return (
        <div className="content">
            <Row>
                <Col>
                    <h3 className="title">Logos</h3>
                    <div className="text-left">
                        {handleDeleteLogo()}
                        <Link to={'/admin/imagens/logo/cadastrar'} className="btn btn-blue mb-20">Cadastrar nova logo</Link>
                    </div>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <Table hover striped responsive>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '5%' }}>Código</th>
                                                <th style={{ width: '65%' }}>Logo</th>
                                                <th style={{ width: '10%', textAlign: 'center' }}>Situação</th>
                                                <th style={{ width: '20%', textAlign: 'center' }}>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {logos?.map((logo, index) => (
                                                <tr key={index}>
                                                    <td>{logo.id}</td>
                                                    <td>
                                                        <img src={`${logosUrl}${logo.image_path}`} className="logo-img" width={170} height={60} alt={`Logo do ID nº ${logo.id}`} />
                                                    </td>
                                                    <td className="text-center">
                                                        <div className={logo.active ? 'badge badge-info badge-pill' : 'badge badge-danger badge-pill'}>
                                                            <span>{logo.active ? 'ATIVO' : 'INATIVO'}</span>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <Link to={`logo/${logo.id}/editar`}>
                                                            <Button className="btn btn-icon btn-secondary lanamaria-icons icon-pencil mr-2 mt-2" title="Editar Logo" />
                                                        </Link>
                                                        <Button
                                                            title="Excluir logo Logo"
                                                            className="btn btn-icon btn-danger lanamaria-icons icon-trash-simple mr-2 mt-2"
                                                            onClick={() => setDeleteLogo({ ...deleteLogo, logoToDelete: logo, showModal: true })} />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Index;