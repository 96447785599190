import { useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import toast from "react-hot-toast";
import { FaSearch } from "react-icons/fa";
import { Button, Card, CardBody, CardHeader, Col, Form, Input, Row } from "reactstrap";
import CeltaProductService from "services/CeltaProductService";
import NotFoundImage from '../../../assets/img/no-product-image.jpeg'
import HomePageService from "services/HomePageService";

const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;


function EditCustomSection(props) {
    const [newProducts, setNewProducts] = useState([]);
    const [customSection, setCustomSection] = useState({});
    const [productsSearch, setProductsSearch] = useState([]);
    const [newMainBannerUrl, setNewMainBannerUrl] = useState("");
    const [newMainBannerBlob, setNewMainBannerBlob] = useState("");
    const [newSecondaryBannerUrl, setNewSecondaryBannerUrl] = useState({});
    const [newSecondaryBannerBlob, setNewSecondaryBannerBlob] = useState("");
    const [newBackgroundColor, setNewBackgroundColor] = useState("")
    const [newFontColor, setNewFontColor] = useState("");

    useEffect(() => {
        const customSectionId = props.match.params.id;

        toast.promise(HomePageService.getCustomSectionById(customSectionId), {
            loading: 'Buscando seção customizada',
            error: 'Não foi possível buscar a seção',
            success: 'Pronto'
        }).then(response => {
            const customSectionData = response.data;

            setCustomSection(customSectionData);
        });
    }, []);

    const handleNewMainBannerUpload = event => {
		const file = event.target.files[0];
		const fileReader = new FileReader();

		fileReader.readAsArrayBuffer(file);
		fileReader.onload = function() {
			const blob = new Blob([fileReader.result]);
			const imageUrl = URL.createObjectURL(blob);

			setNewMainBannerBlob(blob);
			setNewMainBannerUrl(imageUrl);
		}
    }

    const handleNewSecondaryBannerUpload = event => {
		const file = event.target.files[0];
		const fileReader = new FileReader();

		fileReader.readAsArrayBuffer(file);
		fileReader.onload = function() {
			const blob = new Blob([fileReader.result]);
			const imageUrl = URL.createObjectURL(blob);

			setNewSecondaryBannerBlob(blob);
			setNewSecondaryBannerUrl(imageUrl);
		}
    }

    const getMainBannerUrl = () => {
        if(newMainBannerUrl.length > 0) {
            return newMainBannerUrl
        }
        
        return imageBaseUrl + customSection?.main_banner?.path + customSection?.main_banner?.name;
    }


    const getSecondaryBannerUrl = () => {
        if(newSecondaryBannerUrl.length > 0) {
            return newSecondaryBannerUrl
        }
        
        return imageBaseUrl + customSection?.secondary_banner?.path + customSection?.secondary_banner?.name;
    }

    const handleProductSearch = (event, listId) => {
        const userSearch = event.target.value;

        setProductsSearch(prevState => {
            return {
                ...prevState,
                [listId]: userSearch
            }
        })
    }

    const handleSearch = (event, searchListId, customSectionProductId) => {
        event.preventDefault();

        const userSearch = productsSearch[searchListId];
        
        toast.promise(CeltaProductService.searchProductByNameOrCode(userSearch), {
            loading: 'Pesquisando produto',
            success: 'Pronto!',
            error: 'O produto não foi encontrado'
        }).then(response => {
            const product = response.data;

            const duplicatedProductsExists = customSection?.banner_product?.some(prod => prod?.external_code === product?.CodigoInterno_Produto);

            if(duplicatedProductsExists) {
				toast.error('Produto já inserido.');
				return;
			}

            updateProductList(customSectionProductId, product);
            insertNewProducts(customSectionProductId, product);
        });
    }

    const insertNewProducts = (customSectionProductId, newProduct) => {
        setNewProducts(prevState => {
            return {
                ...prevState,
                [customSectionProductId]: {
                    customSectionProductId,
                    newProduct
                } 
            }
        });
    }

    const updateProductList = (customSectionProductId, newProduct) => {
        const updatedProductList = customSection?.banner_product?.map(product => {
            if(product?.id === customSectionProductId) {
                return {
                    ...product,
                    external_code: newProduct?.CodigoInterno_Produto,
                    product_data: newProduct
                }            
            }

            return product;
        });

        setCustomSection(prevState => {
            return {
                ...prevState,
                banner_product: updatedProductList
            }
        });
    }
    
    const getProductName = (productId) => {
        const productData  = customSection?.banner_product?.find(product => product?.id === productId);   
        return productData?.product_data?.Nome_Produto;
    }

    const getProductImage = (productId) => {
        const productData = customSection?.banner_product?.find(product => product?.id === productId); 

        if(productData && productData?.product_data?.image) {
            const imagePath = productData?.product_data?.image?.path;
            const imageName = productData?.product_data?.image?.name;
 
            return imageBaseUrl + imagePath + imageName;
        } else {
            return NotFoundImage;
        }
    }

    const updateCustomSection = () => {
        const customSectionId = props.match.params.id;

        const formData = new FormData();

        formData.append('id', customSectionId);
        formData.append('main_banner', newMainBannerBlob);
        formData.append('secondary_banner', newSecondaryBannerBlob);
        formData.append('background_color', newBackgroundColor);
        formData.append('font_color', newFontColor)
        formData.append('products', JSON.stringify(newProducts));

        toast.promise(HomePageService.updateCustomSection(formData), {
            loading: 'Atualizando a seção customizada',
            success: 'Pronto!',
            error: 'Não foi possível aualizar a seção customizada'
        });
    }

    return(
        <div className="content">
           <Card>
               <CardHeader>
                   <CardBody>
                   <Row>
                        <Col md="12" className="d-flex justify-content-center">
                            <Col md="10" className="p-0" style={{backgroundColor: newBackgroundColor ? newBackgroundColor : customSection?.background_color, maxWidth: "820px"}}>
                                {customSection.main_banner
                                    ? <img width="100%" height="400px" src={getMainBannerUrl()} /> 
                                    : 
                                    <div className="custom-section-banner">
                                        <p>Banner principal</p>
                                        </div>
                                }
                            </Col>
                        </Col>
			        </Row>
                    <Row>
                        {customSection?.section_type === 1 
                        ?
                            <Col md="12" className="d-flex mb-5 justify-content-center">
                                <Col md="12" className="p-0 d-flex" style={{backgroundColor: newBackgroundColor ? newBackgroundColor : customSection?.background_color, maxWidth: "820px"}}>
                                    {customSection?.banner_product?.length > 0
                                        &&
                                            <>
                                                <Row className="py-5 d-flex justify-content-center">
                                                    {customSection?.banner_product?.map(product => (
                                                        <Col key={product?.id} md="5" className="d-flex align-items-center">
                                                            <Col>
                                                                <img 
                                                                    width="90"
                                                                    height="90"
                                                                    src={imageBaseUrl + product?.product_data?.image?.path + product?.product_data?.image?.name}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <h6 style={{color: newFontColor ? newFontColor : customSection?.font_color}}> {product?.product_data?.Nome_Produto} </h6>
                                                            </Col>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </>
                                    }
                                </Col>
                            </Col>
                        :
                            <Col md="12" className="d-flex justify-content-center">
                                <Col md="5" className="p-0" style={{backgroundColor: newBackgroundColor ? newBackgroundColor : customSection?.background_color, maxWidth: "420px"}}>
                                    {customSection?.secondary_banner
                                        ? <img width="100%" height="700px" src={getSecondaryBannerUrl()} className="p-3" />
                                        : 
                                            <div className="custom-section-banner">
                                                <p>Banner secundário</p>
                                            </div>
                                    }
                                    
                                </Col>
                                <Col md="5" className="p-0 d-flex flex-column align-items-center justify-content-center" style={{backgroundColor: newBackgroundColor ? newBackgroundColor : customSection?.background_color, maxWidth: "400px"}}>
                                    {customSection?.banner_product?.length > 0
                                        ?
                                            <>
                                                {customSection?.banner_product?.map(product => (
                                                    <Row key={product?.id} className="d-flex align-items-center p-3">
                                                        <Col md="4">
                                                            <img 
                                                                width="90"
                                                                height="90"
                                                                src={imageBaseUrl + product?.product_data?.image?.path + product?.product_data?.image?.name}
                                                            />
                                                        </Col>
                                                        <Col md="8">
                                                            <h6 style={{color: newFontColor ? newFontColor : customSection?.font_color}}> {product?.product_data?.Nome_Produto} </h6>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </>
                                        :                                        
                                            <div className="custom-section-banner">
                                            <p>Escolha 3 produtos </p>
                                            </div>
                                    }
                                </Col>
                            </Col>
                        }
                    </Row>
                    {customSection?.section_type !== 1   &&
                        <Row className="mt-5">
                            <Col md="3">
                                <label htmlFor="background-color">
                                    <h6>Cor de fundo: </h6>
                                </label>

                                <ChromePicker
                                    isOpen={true}
                                    color={newBackgroundColor || customSection?.background_color}
                                    onChangeComplete={color => setNewBackgroundColor(color?.hex)}
                                />
                            </Col>
                            <Col md="3">
                                <label htmlFor="background-color">
                                    <h6>Cor da fonte: </h6>
                                </label>
                                <ChromePicker
                                    isOpen={true}
                                    color={newFontColor || customSection?.font_color}
                                    onChangeComplete={color => setNewFontColor(color?.hex)}
                                />
                            </Col>
                        </Row>
                    }
                    <Row className="mt-5">
                        <Col md="12" className="d-flex justify-content-center mt-5">
                            <h5>* É recomendado o uso de uma imagem Full HD (1920x1080)</h5>
                        </Col>
                        <Col md="4">
                            <Col md="12">
                                <label htmlFor="main-banner">
                                    <h6>Banner principal:</h6>
                                </label>    
                            </Col>
                            <Col>
                                <Input 
                                    required 
                                    type="file"
                                    accept="image/*"
                                    id="main-banner"
                                    name="image_preview_url"
                                    className="form-control"
                                    onChange={event => handleNewMainBannerUpload(event)}
                                />
                            </Col>
                        </Col>

                        {customSection?.section_type === 2 &&
                            <Col md="6">
                                <Col md="12">
                                    <label htmlFor="main-banner">
                                        <h6>Banner secundário:</h6>
                                    </label>    
                                </Col>
                                <Col>
                                    <Input 
                                        required 
                                        type="file"
                                        accept="image/*"
                                        id="main-banner"
                                        name="image_preview_url"
                                        className="form-control"
                                        onChange={event => handleNewSecondaryBannerUpload(event)}
                                        />
                                </Col>
                            </Col>
                        
                        }
                    </Row>
                     <Row className="mt-4">
                        {customSection?.banner_product?.map((product, index) => (
                            <Col key={index} md="4" className="mt-5">
                                <form onSubmit={event => handleSearch(event, index, product?.id)}>
                                    <label className="mt-5" htmlFor="highlight-first-product">
                                    <h6>Alterar produto</h6>
                                    </label>
                                    <div className="d-flex align-items-center">
                                        <input
                                            type="text"
                                            className="custom-search-input"
                                            placeholder="Pesquise por nome do produto ou código"
                                            onChange={event => handleProductSearch(event, index)}
                                        />
                                        <button 
                                            type="submit"
                                            className="custom-search-button"
                                        >
                                            <FaSearch />
                                        </button>
                                    </div>
                                </form>
                                <Row className="border rounded m-1 my-5 d-flex flex-column justify-content-center align-items-center">
                                    <Col md="12" className="d-flex mt-3 justify-content-center">
                                        <h6>Produto</h6>
                                    </Col>
                                    <Col md="12" className="mx-2 mt-3 d-flex justify-content-center" >
                                        <h6> {getProductName(product?.id)} </h6>
                                    </Col>
                                    <Col md="12" className="d-flex justify-content-center">
                                        <img
                                            height={150}
                                            width={150}
                                            className="border m-3"
                                            src={getProductImage(product?.id)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        ))}
                    </Row>
                    <hr />
                    <Row className="d-flex justify-content-center">
                        <Col md="2">
                            <Button onClick={updateCustomSection}>
                                Salvar
                            </Button>
                        </Col>
                    </Row>  
                   </CardBody>
               </CardHeader>
           </Card>
        </div>
    );
}

export default EditCustomSection;