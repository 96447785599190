import React, { useState, useEffect } from 'react';
import Loader from 'components/Loader/Loader';
import toast from 'react-hot-toast';

import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';

import ImageService from '../../../../services/ImageService';

import Cropper from "react-cropper";
import imgNoBackground from "../../../../assets/img/no_background.png";
import imgHeaderHome from "../../../../assets/img/home-header-1.png";
import { ChromePicker } from 'react-color';

function Edit(props) {
    const id = props.match.params.id;

    const [loading, setLoading] = useState(true);

    const [banner, setBanner] = useState({});

    const [cropper, setCropper] = useState({
        image: banner?.image_path ? process.env.REACT_APP_IMAGE_BASE_URL + banner?.image_path : imgNoBackground,
        src: '',
        cropResult: null,
        sendImage: null
    });

    useEffect(() => {
        getBannerById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const getBannerById = () => {
        ImageService.getBannerById(id).then((data) => {
            setBanner(data);
            setCropper({
                ...cropper,
                image: process.env.REACT_APP_IMAGE_BASE_URL + data?.image_path
            })
            setLoading(false);
        }, (error) => {
            toast.remove();
            setLoading(false);
            // if 404, redirect to index
            if (error.response.status === 404) {
                toast.error('Banner não encontrado');
                // redirect after 3 seconds
                setTimeout(() => {
                    props.history.push('/admin/images/banners');
                }, 2000);
                return;
            }
            toast.error(error.response.data.message);
        });
    }

    const onChange = (e) => {
        const files = e.target.files;

        if (files && files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                setCropper({ ...cropper, src: reader.result })
            );
            reader.readAsDataURL(files[0]);
        }
    }

    const cropImage = () => {
        if (!cropper.cropper.getCroppedCanvas() || cropper.cropper.getCroppedCanvas().toDataURL() === null) {
            toast.error('Selecione uma imagem');
            return;
        }

        if (typeof cropper.cropper.getCroppedCanvas() === 'undefined') {
            return;
        }

        cropper.cropper.getCroppedCanvas().toBlob((blob) => {
            let imageUrl = URL.createObjectURL(blob);
            setCropper({
                ...cropper,
                cropResult: imageUrl,
                sendImage: blob
            });
        }, 'image/jpeg');

        toast.success('Imagem cortada com sucesso');
    }

    const handleBanner = () => {
        let form = new FormData();

        form.append('id', id);
        cropper?.sendImage && form.append('image', cropper?.sendImage);
        form.append('title', banner?.title ?? '');
        form.append('hex_color_title', banner?.hex_color_title ?? '');
        form.append('text', banner?.text ?? '');
        form.append('hex_color_text', banner?.hex_color_text ?? '');
        form.append('font_family_title_name', banner?.font_family_title_name ?? '');
        form.append('font_family_text_name', banner?.font_family_text_name ?? '');
        form.append('position', banner?.position);
        form.append('redirect_to', banner?.redirect_to ?? '');
        // form.append('section_id', banner?.section_id ?? null);
        // form.append('active', banner?.active ?? true);

        toast.promise(ImageService.updateBannerById(id, form), {
            loading: 'Salvando...',
        }).then((data) => {
            console.log('data', data)
            toast.remove();
            getBannerById();
            toast.success('Banner atualizado com sucesso');
            //props.history.push('/admin/images/banners');
        });
    }

    return (
        <div className="content">
            {loading ?
                <Loader loading={loading} />
                :
                <Row>
                    <Col>
                        <h3 className="title">Editar banner</h3>
                        <Card>
                            <CardHeader>
                                <p className="category">*Editando o banner com ID nº {id}</p>
                            </CardHeader>
                            <CardBody>
                                <hr className='mtn-10' />
                                <>
                                    <Row>
                                        <Col>
                                            <Row>
                                                <Col md="6">
                                                    <div className="content-preview-img-form">
                                                        {!cropper?.src &&
                                                            <div className="cropper-bg"></div>
                                                        }

                                                        <Cropper
                                                            //aspectRatio={623 / 250}
                                                            preview=".img-preview"
                                                            responsive={true}
                                                            guides={true}
                                                            // viewMode={0}
                                                            minCropBoxHeight={10}
                                                            minCropBoxWitdh={10}
                                                            src={cropper?.src}
                                                            onInitialized={(instance) => {
                                                                setCropper({
                                                                    ...cropper,
                                                                    cropper: instance
                                                                });
                                                            }}
                                                            cropBoxResizable={true}
                                                            viewMode={1}
                                                        // dragMode="move"
                                                        // cropBoxMovable={false}
                                                        />

                                                        <FormGroup>
                                                            <Input type="file" className="form-control" name="image_preview_url" accept="image/*" onLoad={(event) => { event.target.value = null }} onChange={onChange} style={{ border: '1px solid rgba(0, 0, 0, 0.5)', borderRadius: '5px', fontSize: '13px', padding: '7px', marginTop: '4px', width: '55%', float: 'left', opacity: 1, position: 'initial' }} required />
                                                            <Button className="btn btn-primary" onClick={cropImage} style={{ width: '40%', float: 'right', fontWeight: '600' }}>Cortar</Button>
                                                        </FormGroup>
                                                    </div>
                                                </Col>
                                                <Col md="6">
                                                    <div className="content-preview-img-form">
                                                        <img src={imgHeaderHome} style={{ width: "100%" }} alt="Imagem do 'header home'" />

                                                        <img style={{ width: "100%", height: '219px' }} src={cropper?.cropResult || cropper?.image} alt="Imagem cortada" />

                                                        <div className="text-cropped">
                                                            <div className="text-image-cropped">
                                                                <p className="title-cropped title-carrossel" style={{ fontWeight: "bold", color: banner?.hex_color_title }}>
                                                                    <b>{banner?.title}</b>
                                                                </p>
                                                                <p className="desc-cropped title-sale" style={{ fontWeight: "bold", color: banner?.hex_color_text }}>
                                                                    <b>{banner?.text}</b>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col md="4" style={{ borderRight: '2px solid' }}>
                                            <FormGroup>
                                                <Row>
                                                    <Col md="12">
                                                        <Label for="titulo">
                                                            <h6 style={{ textTransform: 'uppercase', fontWeight: '600', fontSize: '14px' }}>Título</h6>
                                                        </Label>
                                                        <Input type="type" name="text" style={{ color: banner?.hex_color_title, fontWeight: "Bold", /* fontFamily: cropper?.fontTitle */ }} placeholder="Insira o título do banner" value={banner?.title} onChange={(e) => setBanner({ ...banner, title: e.target.value })} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col style={{ textAlign: 'center', marginLeft: '3px', marginTop: '5px' }}>
                                                        <ChromePicker
                                                            isOpen={true}
                                                            color={banner?.hex_color_title || '#000000'}
                                                            onChangeComplete={(color) => setBanner({ ...banner, hex_color_title: color.hex })}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4" style={{ borderRight: '2px solid' }}>
                                            <FormGroup>
                                                <Row>
                                                    <Col md="12">
                                                        <Label>
                                                            <h6 style={{ textTransform: 'uppercase', fontWeight: '600', fontSize: '14px' }}>Texto</h6>
                                                        </Label>
                                                        <Input type="text" name="text" style={{ color: banner?.hex_color_text, fontWeight: "Bold", /* fontFamily: cropper?.fontDesc */ }} placeholder="Insira o texto do banner" value={banner?.text} onChange={(e) => setBanner({ ...banner, text: e.target.value })} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col style={{ textAlign: 'center', marginLeft: '3px', marginTop: '5px' }}>
                                                        <ChromePicker
                                                            isOpen={true}
                                                            color={banner?.hex_color_text || '#000000'}
                                                            onChangeComplete={(color) => setBanner({ ...banner, hex_color_text: color.hex })}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4">
                                            <FormGroup>
                                                <Label>
                                                    <h6 style={{ textTransform: 'uppercase', fontWeight: '600', fontSize: '14px' }}>Redirecionar para (opcional)</h6>
                                                </Label>
                                                <Input type="url" name="text" pattern="https://.*" placeholder="Insira o link para redirecionar ao clicar" value={banner?.redirect_to} onChange={(e) => setBanner({ ...banner, redirect_to: e.target.value })} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col md="3">
                                            <Button onClick={handleBanner} style={{ width: '100%' }}>Salvar</Button>
                                        </Col>
                                    </Row>
                                </>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </div>
    )
}


export default Edit;