import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, Col, Input, Label, Row } from "reactstrap";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br';

import PageService from "services/PageService";

function EditPage(props) {
  const [pageData, setPageData] = useState({});
  const [pageTitle, setPageTitle] = useState('')
  const [pageContent, setPageContent] = useState('');

  useEffect(() => {
    getPage();
  }, []);

  const getPage = () => {
    const pageId = props.match.params.id;

    toast.promise(PageService.getPageById(pageId), {
      loading: 'Buscando página...',
      success: 'Pronto!',
      error: 'Falha na comunicação com o servidor'
    }).then(data => {
      setPageData(data);
    });
  }

  const savePage = () => {
    const requestData = {
      title: pageTitle === '' ? pageData.title : pageTitle,
      content: pageContent
    }

    toast.promise(PageService.updatePageById(pageData.id, requestData), {
      loading: 'Salvando página...',
      success: 'Pronto!',
      error: 'Houve uma falha ao salvar a página'
    }).then(() => {
      setTimeout(() => {
        props.history.push('/admin/paginas/');
      }, 1500);
    });
  }

  return(
    <div className="content">
      <Row>
          <Col>
            <h3 className="title">Edição de página</h3>
            <Card>
              <CardHeader>
                <Row>
                  <Col md="10">
                    <Label className="mr-2" style={{fontSize: 18, fontWeight: 900}}>
                      #Id {pageData.id}
                    </Label>
                  </Col>
                </Row>
              <hr />
              </CardHeader>
              <CardBody>
                <Row>
                <Col>
                  <Label className="font-weight-bolder">
                      Titulo
                  </Label>
                  <Input defaultValue={pageData.title}  onChange={event => setPageTitle(event.target.value)}/>
                </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <Label className="font-weight-bolder">
                      Texto
                    </Label>
                    <CKEditor
                      editor={ ClassicEditor }
                      config={{language: 'pt-br'}}
                      data={pageData.content ? pageData.content : ''}
                      onChange={(event, editor) => setPageContent(editor.getData())}
                    />
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col className="d-flex flex-row justify-content-center">
                    <Button onClick={savePage}>
                        Salvar
                    </Button>   
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
    </div>
  );
}

export default EditPage;