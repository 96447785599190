import Icons from './views/Admin/Icons/Index.js';

// Auth
import Login from './views/Auth/Login.js';

import IndexDashboard from './views/Admin/Dashboard/Index.js';

// Products
import IndexProducts from './views/Admin/Products/Index.js';
import MainProductVariants from './views/Admin/Products/Variants.js';
import EditProduct from 'views/Admin/Products/Edit.js';
import EditImage from "views/Admin/ProductVariants/EditImage.js";
import EditMainProductImage from "views/Admin/Products/EditImage.js";

// Images
import IndexBanner from 'views/Admin/Images/Banner/Index.js';
import IndexLogo from 'views/Admin/Images/Logo/Index.js';
import EditBanner from 'views/Admin/Images/Banner/Edit.js';
import EditLogo from 'views/Admin/Images/Logo/EditLogo.js';
import StoreBanner from 'views/Admin/Images/Banner/Store.js';
import CreateLogo from 'views/Admin/Images/Logo/CreateLogo.js';
import CreateDepartmentBanner from "views/Admin/Images/Banner/DepartmentBanner/Create.js";
import ListDepartmentBanner from "views/Admin/Images/Banner/DepartmentBanner/Index.js";
import EditDepartmentBanner from "views/Admin/Images/Banner/DepartmentBanner/Edit.js";

// Pages
import IndexPage from 'views/Admin/Pages/Index.js';
import EditPage from 'views/Admin/Pages/EditPage.js';
import EditHomePage from 'views/Admin/Pages/EditHomePage.js';
import CreateNewHighlightDepartment from 'views/Admin/Pages/CreateNewHighlightDepartment.js'; 
import CreateCustomizedSection from 'views/Admin/Pages/CreateCustomizedSection.js';
import EditCustomSection from 'views/Admin/Pages/EditCustomSection.js';

// Blog
import IndexPost from 'views/Admin/Blog/IndexPost.js';
import EditPost from 'views/Admin/Blog/EditPost.js';
import CreateCategory from 'views/Admin/Blog/CreateCategory';
import CreatePost from 'views/Admin/Blog/CreatePost.js';
import PostDetails from 'views/Admin/Blog/PostDetails.js';
import ManageStatus from 'views/Admin/Blog/ManageStatus.js';
import IndexProductVariants from 'views/Admin/ProductVariants/Index.js';
import EditProductVariant from 'views/Admin/ProductVariants/Edit.js';
import ManageCategories from 'views/Admin/Blog/ManageCategories.js';
import EditCategory from 'views/Admin/Blog/EditCategory.js';

// Discounts
import CreateCoupon from "views/Admin/Discounts/Coupon/Create";
import ListCoupons from "views/Admin/Discounts/Coupon/Index";
import EditCoupon  from "views/Admin/Discounts/Coupon/Edit"

// Users
import CreateUser from 'views/Admin/Users/Create.js';
import EditUser from 'views/Admin/Users/Edit.js';
import ListUsers from 'views/Admin/Users/Index';

// Orders
import ListOrders from 'views/Admin/Orders/ListOrders';
import OrderDetails from 'views/Admin/Orders/OrderDetails';

var routes = [
    {
        path: '/login',
        name: 'Login',
        pathname: 'icons',
        icon: 'lanamaria-icons icon-single-02',
        component: Login,
        layout: '/auth',
        visible: false,
        subcategory: false,
        subcategories: []
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        pathname: 'dashboard',
        icon: 'casa-icons icon-chart-pie-36',
        component: IndexDashboard,
        layout: '/admin',
        visible: false,
        subcategory: false,
        subcategories: []
    },
    {
        path: '/produtos',
        name: 'Produtos',
        pathname: 'products',
        icon: 'lanamaria-icons icon-basket-simple',
        layout: '/admin',
        visible: true,
        subcategory: true,
        subcategories: [
            {
                path: '/principais',
                name: 'Consultar principais',
                pathname: '.manage',
                component: IndexProducts,
                visible: true,
                subsegment: true,
                subsegments: [
                    {
                        path: '/:code/editar',
                        name: 'Editar',
                        pathname: '.edit',
                        component: EditProduct,
                    },
                    {
                        path: '/:code/variantes',
                        name: 'Variantes',
                        pathname: '.variants',
                        component: MainProductVariants
                    },
                    {
                        path: '/:id/editar-imagem',
                        name: 'Editar imagem',
                        pathname: '.editImage',
                        component: EditMainProductImage
                    }
                ]
            },
            {
                path: '/variantes',
                name: 'Consultar variantes',
                pathname: '.manage',
                component: IndexProductVariants,
                visible: true,
                subsegment: false,
                subsegments: [
                    {
                        path: '/:id/editar-imagem',
                        name: 'Editar Imagem',
                        pathname: '.editImage',
                        component: EditImage
                    },
                    {
                        path: '/:code/editar',
                        name: 'Editar',
                        pathname: '.edit',
                        component: EditProductVariant,
                    }
                ]
            }
        ]
    },
    {
        path: '/imagens',
        name: 'Imagens',
        pathname: 'images',
        icon: 'lanamaria-icons icon-camera-18',
        component: Icons,
        layout: '/admin',
        visible: true,
        subcategory: true,
        subcategories: [
            {
                path: '/logo',
                name: 'Logo',
                pathname: '.IndexLogo',
                component: IndexLogo,
                visible: true,
                subsegment: true,
                subsegments: [
                    {
                        path: '/:code/editar',
                        name: 'Editar',
                        pathname: '.edit',
                        component: EditLogo,
                        visible: false,
                        subsegment: false
                    },
                    {
                        path: '/cadastrar',
                        name: 'Cadastrar',
                        pathname: '.register',
                        component: CreateLogo,
                        visible: false,
                        subsegment: false
                    }
                ]
            },

            {
                path: '/banners',
                name: 'Banners',
                pathname: '.manage',
                component: IndexBanner,
                visible: true,
                subsegment: true,
                subsegments: [
                    {
                        path: '/:id/editar',
                        name: 'Editar banner',
                        pathname: '.edit',
                        component: EditBanner
                    },
                    {
                        path: '/cadastrar',
                        name: 'Cadastrar banner',
                        pathname: '.store',
                        component: StoreBanner
                    }
                ]
            },
            
            {
                path: '/banner-departamento',
                name: 'Banners de departamento',
                pathname: '.manage',
                component: ListDepartmentBanner,
                visible: true,
                subsegment: true,
                subsegments: [
                    {
                        path: '/criar',
                        name: 'Criar banner de departamento',
                        pathname: '.createDepartmentBanner',
                        component: CreateDepartmentBanner
                    },

                    {
                        path: '/:id/editar',
                        name: 'Editar banner de departamento',
                        pathname: '.editDepartmentBanner',
                        component: EditDepartmentBanner
                    }
                ]
            },
        ]
    },
    {
        path: '/paginas',
        name: 'Páginas',
        pathname: 'pages',
        icon: 'lanamaria-icons icon-paper',
        layout: '/admin',
        visible: true,
        subcategory: true,
        subcategories: [
            {
                path: '/',
                name: 'Consultar',
                pathname: '.edit',
                component: IndexPage,
                visible: true,
                subsegment: false,
            },
            {
                path: '/pagina-principal',
                name: 'Página principal',
                pathname: '.createCategory',
                component: EditHomePage,
                visible: true,
                subsegment: true,
                subsegments: [
                    {
                        path: '/departamento-destaque',
                        name: 'Criar departamento de destaque',
                        pathname: '.highlightDepartment',
                        component: CreateNewHighlightDepartment,
                        visible: false, 
                        subsegment: false
                    },
                    {
                        path: '/secao-customizada',
                        name: 'Criar seção customizada',
                        pathname: '.CustomizedSection',
                        component: CreateCustomizedSection,
                        visible: false,
                        subsegment: true,
                        subsegments: false
                    },
                    {
                        path: '/secao-customizada/:id/editar',
                        name: 'Editar seção customizada',
                        pathname: '.editCustomSection',
                        component: EditCustomSection,
                        visible: false, 
                        subsegment: false
                    }       
                ]
            },
            {
                path: '/:id/editar',
                name: 'Editar',
                pathname: '.edit',
                component: EditPage,
                visible: false,
                subsegment: false,
            }
        ]
    },
    {
        path: '/blog',
        name: 'Blog',
        pathname: 'blog',
        icon: 'lanamaria-icons icon-mobile',
        layout: '/admin',
        visible: true,
        subcategory: true,
        subcategories: [
            {
                path: '/gerenciar',
                name: 'Gerenciar publicações',
                pathname: '.manage',
                component: IndexPost,
                visible: true,
                subsegment: false,
            },
            {
                path: '/criar',
                name: 'Nova publicação',
                pathname: '.create',
                component: CreatePost,
                visible: true,
                subsegment: false,
            },
            {
                path: '/categoria/gerenciar',
                name: 'Gerenciar categorias',
                pathname: '.createCategory',
                component: ManageCategories,
                visible: true,
                subsegment: false
            },
            {
                path: '/categoria/criar',
                name: 'Criar categoria',
                pathname: '.createCategory',
                component: CreateCategory,
                visible: false,
                subsegment: false
            },
            {
                path: '/categoria/:id/editar',
                name: 'Editar categoria',
                pathname: '.createCategory',
                component: EditCategory,
                visible: false,
                subsegment: false
            },
            {
                path: '/:id/editar',
                name: 'Editar publicação',
                pathname: '.edit',
                component: EditPost,
                visible: false,
                subsegment: false
            },
            {
                path: '/gerenciar/status',
                name: 'Gerenciar status',
                pathname: '.status',
                component: ManageStatus,
                visible: true,
                subsegment: false,
            },
            {
                path: '/post/:id/detalhes',
                name: 'Gerenciar status',
                pathname: '.status',
                component: PostDetails,
                visible: false,
                subsegment: false,
            }
        ]
    },
    {
        path: '/usuarios',
        name: 'Usuários',
        pathname: 'users',
        icon: 'lanamaria-icons icon-single-02',
        component: '',
        layout: '/admin',
        visible: true,
        subcategory: true,
        subcategories: [
            {
                path: '/',
                name: 'Gerenciar',
                pathname: '.manage',
                component: ListUsers,
                visible: true,
                subsegment: false,
            },
            {
                path: '/criar',
                name: 'Criar usuário',
                pathname: '.createCoupon',
                component: CreateUser,
                visible: false, 
                subsegment: false
            },
            {
                path: '/:id/editar',
                name: 'Editar usuário',
                pathname: '.editUser',
                component: EditUser,
                visible: false, 
                subsegment: false
            }                    
        ]
    },
    {
        path: '/descontos',
        name: 'Descontos',
        pathname: 'discounts',
        icon: 'lanamaria-icons icon-coins',
        component: '',
        layout: '/admin',
        visible: true,
        subcategory: true,
        subcategories: [
            {
                path: '/cupons',
                name: 'Cupons',
                pathname: '.list',
                component: ListCoupons,
                visible: true,
                subsegment: true,
                subsegments: [
                    {
                        path: '/criar',
                        name: 'Criar cupom de desconto',
                        pathname: '.createCoupon',
                        component: CreateCoupon,
                        visible: false, 
                        subsegment: false
                    },
                    {
                        path: '/:id/editar',
                        name: 'Editar cupom de desconto',
                        pathname: '.editCoupn',
                        component: EditCoupon,
                        visible: false, 
                        subsegment: false
                    }                    
                ]
            },
        ]
    },
    {
        path: '/pedidos',
        name: 'Pedidos',
        pathname: 'orders',
        icon: 'lanamaria-icons icon-cart',
        component: '',
        layout: '/admin',
        visible: true,
        subcategory: true,
        subcategories: [
            {
                path: '/',
                name: 'Gerenciar',
                pathname: '.manage',
                component: ListOrders,
                visible: true,
                subsegment: false,
            },
            {
                path: '/:id/detalhes',
                name: 'Visualizar',
                pathname: '.details',
                component: OrderDetails,
                visible: false,
                subsegment: false,
            },
        ]
    }
];

export default routes;