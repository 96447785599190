/**
 * Authors: @caiommachado and @marcosppastor
*/

import moment from 'moment';

/**
 * Handle date format
 * @param {string} date
 * @returns {string}
 * @example
 * date.handle('2019-01-01') // 01/01/2019
 * date.handle('2019-01-01', 'DD/MM/YYYY') // 01/01/2019
*/

const datehelper = {};

/**
 * Transform date to dd/mm/yyyy format
 * @param {string} date
 * @returns {string}
 */
 datehelper.toDDMMYYYY = (date) => {
    return moment(date).format('DD/MM/YYYY');
}

/**
 * Transform date to yyyy/mm/dd format
 * @param {string} date
 * @returns {string}
 */
 datehelper.toYYYYMMDD = (date) => {
    return moment(date).format('YYYY-MM-DD');
}

/**
 * Transform date to dd/mm/yyyy às hh::mm format
 * @param {string} date
 * @returns {string}
 */
datehelper.formatDateWithHours = (date) => {
    return moment(date).format('DD/MM/YYYY')+ ' às ' + moment(date).format('HH:MM')
}

datehelper.fromFormat = (datevalue, format = 'YYYY-MM-DD hh:mm:ss') => {
    return moment(datevalue, format);
}

datehelper.changeFormat = (datevalue, fmtsrc = 'YYYY-MM-DD hh:mm:ss', fmtdst = 'DD/MM/YYYY') => {
    return datehelper.fromFormat(datevalue, fmtsrc).format(fmtdst)
}

datehelper.tryformat = (date, format = 'DD/MM/YYYY') => {
    let regexp = [
        { reg: '([0-9]{4})' }
    ]
    return regexp;
}

export default datehelper;