import api from './api';

class ImageService {
    /**
     * Get all banners
     * @returns {Promise}
     */
    async getAllBanners() {
        const response = await api.get('admin/images/banners/index');
        return response.data;
    }

    /**
     * Store new banner
     * @param {Object} bannerData
     * @returns {Promise}
     */
    async storeBanner(bannerData) {
        const response = await api.post('admin/images/banners/store', bannerData, {
            headers: {
                'Content-Type': 'binary'
                // 'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    }

    /**
     * Get banner by id
     * @param {Number} id
     * @returns {Promise}
     */
    async getBannerById(id) {
        const response = await api.get(`admin/images/banners/${id}/show`);
        return response.data;
    }

    /**
     * Update banner by id
     * @param {Number} id
     * @param {Object} bannerData
     * @returns {Promise}
     */
    async updateBannerById(id, bannerData) {
        const response = await api.post(`admin/images/banners/${id}/update`, bannerData, {
            headers: {
                'Content-Type': 'binary'
                // 'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    }

    /**
     * Delete banner by id
     * @param {Number} id
     * @returns {Promise}
     */
    async deleteBannerById(id) {
        const response = await api.delete(`admin/images/banners/${id}/delete`);
        return response.data;
    }

    /**
     * Get all banners
     * @returns {Promise}
     */
    async getAllLogos() {
        const response = await api.get('admin/images/logos/index');
        return response.data;
    }

    /**
     * Delete banner by id
     * @returns {Promise}
     */
    async deleteLogoById(id) {
        const response = await api.delete(`admin/images/logos/${id}/delete`);
        return response.data;
    }

    /**
     * Get active logo
     * @returns {Promise}
     */
    async getActiveLogo() {
        const response = await api.get('admin/images/logos/active');
        return response.data;
    }

    getLogoById = logoId => {
        return api.get(`admin/images/logos/${logoId}/show`);
    }

    createLogo = (logoData) => {
        return api.post(`admin/images/logos/index`, logoData);
    }

    updateLogoById = (logoId, logoData) => {
        return api.post(`admin/images/logos/${logoId}/update`, logoData);
    }

    async getAvailableCommerceDepartments() {
        const response = await api.get("admin/images/banners/department-banner/available-commerce-departments");
        return response.data;
    }

    async getAvailableDepartments() {
        const response = await api.get("admin/images/banners/department-banner/available-departments");
        return response.data;
    }

    async storeDepartmentBanner(requestBody) {
        const response = await api.post("admin/images/banners/department-banner/create", requestBody);
        return response.data;
    }

    async getDepartmentBanner() {
        const response = await api.get("admin/images/banners/department-banner/list");
        return response.data;
    }

    async getDepartmentBannerById(id) {
        const response = await api.get(`admin/images/banners/department-banner?id=${id}`);
        return response.data;
    }

    async updateDepartmentBannerById(requestBody) {
        const response = await api.post("admin/images/banners/department-banner/update", requestBody);
        return response.data;
    }

    async deleteDepartmentBannerById(id) {
        const response = await api.delete(`admin/images/banners/department-banner/delete?id=${id}`)
        return response.data;
    }
}

export default new ImageService();
