import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import {FaSearch } from "react-icons/fa";
import { useState, useEffect } from "react";
import { Col, Row, Card, CardHeader, CardBody, Input, Button} from "reactstrap";
import NotFoundImage from '../../../assets/img/no-product-image.jpeg'
import HomePageService from "services/HomePageService";
import CeltaProductService from "services/CeltaProductService";

const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

function CreateNewHighlightDepartment() {
    const [totalProducts, setTotalAditionalProducts] = useState(3);
    const [highlightProducts, setHighlightProducts] = useState([]);
    const [highlightProductsSearch, setHighlightProductsSearch] = useState([]);
    const [selectedHighlightDepartment, setSelectedHighlightDepartment] = useState("");
    const [availableHighlightDepartments, setAvailableHighlightDepartments] = useState([]);

    useEffect(() => {
        getAvailableDepartments();
    }, []);
    
    const getAvailableDepartments = () => {
        HomePageService.getAvailableHighlightDepartments().then(response => {
            const availableHighlightDepartments = response.data;
            setAvailableHighlightDepartments(availableHighlightDepartments);
        });
    }

    const handleSelectedHighlightDepartment = event => {
        const highlightDepartmentId = event.target.value;
        const highlightDepartmentData = availableHighlightDepartments.find(department => {
            if(department.CodigoInterno_Secao == highlightDepartmentId) {
                return department.Descricao_Secao;
            }
        });
        
        setSelectedHighlightDepartment(prevState => {
            return {
                ...prevState,
                highlightDepartmentData
            }
        });
    }

    const handleSearchProduct = (event, highlightProductId) => {
        event.preventDefault();
        
        const userSearch = highlightProductsSearch[highlightProductId];

        toast.promise(CeltaProductService.searchProductByNameOrCode(userSearch), {
            loading: 'Carregando...',
            error: 'O produto não pode ser encontrado',
            success: 'Pronto!'
        }).then(response => {
            const searchedProduct = response.data;

            const searchedProductDepartments = searchedProduct?.product_classification;
            const selectedDepartment = selectedHighlightDepartment?.highlightDepartmentData?.CodigoInterno_Secao;
            
            const searchedProductExistsInDepartment = searchedProductDepartments?.find(product => product?.CodigoInterno_Secao === selectedDepartment);
            if(!searchedProductExistsInDepartment) {
                toast.error('O departamento do produto pesquisado é diferente do departamento de destaque');
                return;
            }
            
            const shouldUpdateProducts = highlightProducts.find(product => product.highlightProductId === highlightProductId);
            if(shouldUpdateProducts) {
                updateHighlightProducts(highlightProductId, searchedProduct);
            } else {
                addHighlightProduct(highlightProductId, searchedProduct);
            };
        });
    }


    const addHighlightProduct = (highlightProductId, product) => {
        setHighlightProducts(prevState => {
            return [
                ...prevState,
                {
                    highlightProductId,
                    product
                }
            ];
        });
    }

    const updateHighlightProducts = (highlightProductId, newProduct) => {
        setHighlightProducts(prevState => {
            return prevState.map(product => {
                if(product.highlightProductId === highlightProductId) {
                    return {
                        highlightProductId,
                        product: newProduct
                    };
                }

                return product;
            });
        });
    }

    const getHighlightProductImage = (highlightProductId) => {
       const insertedProduct = highlightProducts?.find(product => product?.highlightProductId === highlightProductId); 
        
       if(insertedProduct && insertedProduct?.product?.image) {
            const imagePath = insertedProduct?.product?.image?.path;
            const imageName = insertedProduct?.product?.image?.name;

            return imageBaseUrl + imagePath + imageName;
       } else {
            return NotFoundImage;
       }
    }

    const handleCreateHighlightDepartment = () => {
        if(highlightProducts.length ===  6 || highlightProducts.length ===  3) {
            const productDepartment  = selectedHighlightDepartment?.highlightDepartmentData?.CodigoInterno_Secao;
            const products = highlightProducts.slice(0, totalProducts);

            toast.promise(HomePageService.createHighlightDepartment(productDepartment, products), {
                loading: 'Criando...',
                success: 'Pronto!',
                error: 'Falha na criação de departamento de destaque!'
            });
        } else {
            toast.error('É obrigatório ter 3 ou 6 produtos por departamento de destaque');
        }

    }

    return (
        <div className="content">
            <Row>
                <Col>
                    <h3>Página principal</h3>
                </Col>
            </Row>
            <Card>
                <CardHeader>
                    <h6>Criação de departamento de destaque</h6>
                    <hr />
                </CardHeader>
                <CardBody>
                    <Row className="mt-1">
                        <Col>
                            <Link to="/admin/paginas/pagina-principal">
                                <Button>Editar departamentos de destaque</Button>
                            </Link>           
                        </Col>
                    </Row>
                    
                    <Row className="mt-5">
                        <Col md="4">
                            <form>
                                <label htmlFor="select-highlight-department">
                                    <h6>Novo departamento de destaque</h6>
                                </label>
                                <Input 
                                    type="select" 
                                    defaultValue={selectedHighlightDepartment}
                                    onChange={(event) => handleSelectedHighlightDepartment(event)}
                                >
                                   <option disabled={true} value="">Selecione um departamento disponível</option>
                                   {availableHighlightDepartments?.map(department => (
                                        <option 
                                            key={department?.CodigoInterno_Secao}
                                            value={department?.CodigoInterno_Secao}
                                        >
                                            {department?.Descricao_Secao}
                                        </option>
                                   ))}
                                </Input>
                            </form>
                        </Col>
                    </Row>

                    {selectedHighlightDepartment && 
                        <Row className="mt-5">
                            <Col className="d-flex justify-content-center">
                                <h4>
                                    <b>Departamento selecionado: {selectedHighlightDepartment?.highlightDepartmentData?.Descricao_Secao}</b>
                                </h4>
                            </Col>
                        </Row>
                    }
                    
                    {selectedHighlightDepartment && 
                        <>
                            <Row className="mt-4">
                                <Col>
                                    <h6>Quantidade de produtos a serem exibidos</h6>
                                    <Button className="mr-2" onClick={() => setTotalAditionalProducts(3)}>3 Produtos</Button>
                                    <Button onClick={() => setTotalAditionalProducts(6)}>6 Produtos</Button>
                                </Col>
                            </Row>
                            <Row className="mt-4 d-flex">
                                {[...Array(totalProducts)].map((value, index) => (
                                    <Col md="4" className="mt-5" key={index}>
                                        <form onSubmit={event => handleSearchProduct(event, index)}>
                                            <label className="mt-5" htmlFor="highlight-first-product">
                                               <h6>Alterar produto</h6>
                                            </label>
                                            <div className="d-flex align-items-center">
                                                <input
                                                    type="text"
                                                    className="custom-search-input"
                                                    placeholder="Pesquise por nome do produto ou código"
                                                    onChange={event => setHighlightProductsSearch(prevState => ({...prevState, [index]: event.target.value }))}
                                                />
                                                <button 
                                                    type="submit"
                                                    className="custom-search-button"
                                                >
                                                    <FaSearch />
                                                </button>
                                            </div>
                                        </form>
                                        <Row className="border rounded m-3 my-5">
                                            <Col md="12" className="d-flex justify-content-center mt-4">
                                                <h6>Produto</h6>
                                            </Col>
                                            <Row className="mt-4">
                                                <Col md="12" className="mx-2 d-flex justify-content-center">
                                                    <h6>
                                                        {highlightProducts.map(productData => {
                                                            if(productData?.highlightProductId === index) {
                                                                return productData?.product?.Nome_Produto;
                                                            }
                                                        })}
                                                    </h6>
                                                </Col>
                                                <Col md="11" className="d-flex justify-content-center ml-2">
                                                    <img
                                                        height={150}
                                                        width={150}
                                                        className="border mx-5 my-4"
                                                        src={getHighlightProductImage(index)}
                                                     />
                                                </Col>
                                            </Row>
                                        </Row>
                                    </Col>
                                ))}
                            </Row>
                        </>
                    }
                    {(highlightProducts.length === 6 || highlightProducts.length === 3) &&
                        <Row className="mt-5">
                            <Col className="d-flex justify-content-center">
                               <Button onClick={() => handleCreateHighlightDepartment()}>
                                    Criar
                              </Button>
                            </Col>
                        </Row>
                    } 
                </CardBody>
            </Card>
        </div>
    );
}

export default CreateNewHighlightDepartment;