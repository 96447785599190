import React, { Component } from "react";
import Loader from '../../../loader.gif';
import classNames from "classnames";
import { Line, Bar } from "react-chartjs-2";
import { Button, ButtonGroup, Card, CardHeader, CardBody, CardTitle, Row, Col } from "reactstrap";
import { chartExample1, chartExample2, chartExample3, chartExample4 } from "variables/charts.js";
import statistics from "../../../statistics.svg";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            big_chart_data: 'data1'
        }
    }

    componentDidMount() {
        // loader
        this.timerHandle = setTimeout(() => this.setState({ loading: false }), 500);
    }

    componentWillUnmount() {
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
            this.timerHandle = 0;
        }
    }

    setBigChartData = (data_name) => {
        return () => {
            let big_chart_data = this.state.big_chart_data;
            big_chart_data = data_name;
            this.setState({ big_chart_data: big_chart_data });
        }
    }

    render() {
        return (
            <div className="content">
                {this.state.loading ?
                    <div>
                        <Row>
                            <Col>
                                <img src={Loader} className={`search-loading ${this.state.loading ? 'show' : 'hide'}`} alt="loader" />
                            </Col>
                        </Row>
                    </div>
                    :
                    <div>
                        <Row>
                            <Col xs="12">
                                <Card className="card-chart">
                                    <CardBody>
                                        <Col>
                                            <Card>
                                                <CardBody>
                                                    <Row className="align-items-center">
                                                        <Col className="text-center" xl="8">
                                                            <h1 className="text-primary">Olá!</h1>
                                                            <p>Veja abaixo as métricas e estatísticas disponíveis!</p>
                                                        </Col>
                                                        <Col className="text-center">
                                                            <img src={statistics} alt="Estatísticas" />
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs="12">
                                <Card className="card-chart">
                                    <CardHeader>
                                        <Row>
                                            <Col className="text-left" sm="6">
                                                <h5 className="card-category">Remessas</h5>
                                                <CardTitle tag="h2">Performance</CardTitle>
                                            </Col>
                                            <Col sm="6">
                                                <ButtonGroup
                                                    className="btn-group-toggle float-right"
                                                    data-toggle="buttons"
                                                >
                                                    <Button
                                                        tag="label"
                                                        className={classNames("btn-simple", { active: this.state.big_chart_data })}
                                                        color="primary"
                                                        id="0"
                                                        size="sm"
                                                        onClick={this.setBigChartData('data1')}
                                                    >
                                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                            Clientes
                                                        </span>
                                                        <span className="d-block d-sm-none">
                                                            <i className="lanamaria-icons icon-single-02" />
                                                        </span>
                                                    </Button>
                                                    <Button
                                                        color="primary"
                                                        id="1"
                                                        size="sm"
                                                        tag="label"
                                                        className={classNames("btn-simple", { active: this.state.big_chart_data })}
                                                        onClick={this.setBigChartData('data2')}
                                                    >
                                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                            Compras
                                                        </span>
                                                        <span className="d-block d-sm-none">
                                                            <i className="lanamaria-icons icon-gift-2" />
                                                        </span>
                                                    </Button>
                                                    <Button
                                                        color="primary"
                                                        id="2"
                                                        size="sm"
                                                        tag="label"
                                                        className={classNames("btn-simple", { active: this.state.big_chart_data })}
                                                        onClick={this.setBigChartData('data3')}
                                                    >
                                                        <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                                                            Acessos ao site
                                                        </span>
                                                        <span className="d-block d-sm-none">
                                                            <i className="lanamaria-icons icon-tap-02" />
                                                        </span>
                                                    </Button>
                                                </ButtonGroup>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="chart-area">
                                            <Line data={chartExample1[this.state.big_chart_data]} options={chartExample1.options}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4">
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Remessas</h5>
                                        <CardTitle tag="h3">
                                            <i className="lanamaria-icons icon-bell-55 text-info" /> 763,215
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="chart-area">
                                            <Line
                                                data={chartExample2.data}
                                                options={chartExample2.options}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="4">
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Vendas diárias</h5>
                                        <CardTitle tag="h3">
                                            <i className="lanamaria-icons icon-delivery-fast text-primary" />{" "}
                                            R$ 33.500,00
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="chart-area">
                                            <Bar
                                                data={chartExample3.data}
                                                options={chartExample3.options}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg="4">
                                <Card className="card-chart">
                                    <CardHeader>
                                        <h5 className="card-category">Crescimento de vendas (ano)</h5>
                                        <CardTitle tag="h3">
                                            <i className="lanamaria-icons icon-money-coins text-success" /> 70%
                                        </CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="chart-area">
                                            <Line
                                                data={chartExample4.data}
                                                options={chartExample4.options}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }
}

export default Index;
