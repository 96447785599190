import React from "react";
import ReactDOM from "react-dom";
//import { BrowserRouter, Route, Switch } from "react-router-dom";
// Layout
import App from "App";
// Helpers
import 'polyfill.js';
import AuthProvider from "contexts/Auth/AuthProvider";
//import AuthLayout from "layouts/Auth/Auth.js";
//import AdminLayout from "layouts/Admin/Admin.js";
//import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
//import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";

ReactDOM.render(
    <AuthProvider>
        <App />
    </AuthProvider>
    , document.getElementById("root")
);

/*
ReactDOM.render(
    <ThemeContextWrapper>
        <BackgroundColorWrapper>
            <BrowserRouter>
                <Switch>
                    <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                    <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
                </Switch>
            </BrowserRouter>
        </BackgroundColorWrapper>
    </ThemeContextWrapper>,
    document.getElementById("root")
);*/
