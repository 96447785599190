import { MDBBtn } from 'mdbreact';
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table, FormGroup, Label, Input, Button } from 'reactstrap';

import datehelper from "helpers/datehelper";
import ModalWindow from 'components/ModalWindow/ModalWindow';
import OrderService from 'services/OrderService';

const pagesAvailable = [10, 20, 25, 50, 100];

const defaultDate = new Date();
const todayDate = defaultDate.toISOString().substr(0, 10);

function ManageStatus() {
    const [orders, setOrders] = useState([]);
    const [updatePosts, setUpdatePosts] = useState(false);

    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [totalOrders, setTotalOrders] = useState(0);
    const [ordersPerPage, setOrdersPerPage] = useState(10);
    
    const [orderStatuses, setOrderStatuses] = useState([]);

    const [activeStatus, setActiveStatus] = useState(1);

    const [queryFilter, setQueryFilter] = useState({
        query: '',
        status: '',
        orderBy: 'desc',
        initialDate: '',
        finalDate: todayDate,
        showClearFiltersButton: false
    });

    useEffect(() => {
        getOrderAvailableStatus();
    }, []);

    useEffect(() => {
        getOrders();
    }, [page, activeStatus, ordersPerPage, updatePosts]);

    const getOrderAvailableStatus = async () => {
        const status = await OrderService.getOrderStatuses();
        setOrderStatuses(status);
    }

    const getOrders = () => {
        toast.promise(OrderService.getOrders(page, ordersPerPage, queryFilter), {
            loading: 'Carregando Pedidos...',
            success: 'Pronto!',
            error: 'Não foi possível carregar os pedidos.'
        }).then(orders => {
            const ordersData = orders.data;
            const lastPage = orders.last_page;
            const totalOrders = orders.total;

            setOrders(ordersData);
            setLastPage(lastPage);
            setTotalOrders(totalOrders);
        });
    }

    const handleFilters = () => {
        if(Date.parse(queryFilter.initialDate) > Date.parse(queryFilter.finalDate)){
            return toast.error('A data inicial não pode ser maior que a final');
        }
        
        setQueryFilter(prevState => ({
            ...prevState,
             showClearFiltersButton: true
        }));

        setUpdatePosts(prevState => !prevState)
    }

    const handleClearFilterButton = () => {
        setQueryFilter({
            query: '',
            status: '',
            orderBy: 'desc',
            initialDate: '',
            finalDate: todayDate,
            showClearFiltersButton: false
        });

        if(page !== 1) {
            setPage(1);
            return;
        }
        
        setUpdatePosts(prevState => !prevState);
        toast.success('Filtros removidos')
    }

    function calculateOrderTotal(totalPrice, totalWithDiscounts, freight) {
        if(!totalPrice) return; 

        const cartTotal = parseFloat(totalPrice);
        const cartTotalWithDiscounts =  parseFloat(totalWithDiscounts);
        const freightValue = freight ? parseFloat(freight) : 0;

        
        if(cartTotalWithDiscounts > 0) {
            return (cartTotalWithDiscounts + freightValue)?.toFixed(2)?.replace('.', ',');
        } else {
            return (cartTotal + freightValue)?.toFixed(2)?.replace('.', ',');
        }
    }

    return (
        <div className="content">
            <Row>
                <Col>
                    <h3 className="title">Gerenciar pedidos</h3>
                    <Card>
                        <CardHeader>
                            <p className="category">* Listagem de todas os pedidos</p>
                        </CardHeader>
                        <CardBody>
                            <Label>
                                <h5 style={{ fontSize: '20px' }}><b>Filtro</b></h5>
                            </Label>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>
                                            <h6>Pesquisar por ID</h6>
                                        </Label>
                                        <Input type="text" name="product_name_code" placeholder="Busque por título ou código do pedido" value={queryFilter?.query} onChange={event => setQueryFilter(prevState => ({...prevState, query: event.target.value}))}/>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>
                                            <h6>Pesquisar por status de pedido</h6>
                                        </Label>
                                        <Input 
                                            type="select" 
                                            value={queryFilter?.status}
                                            onChange={event => setQueryFilter(prevState => ({...prevState, status: event.target.value}))}
                                        >
                                            <option disabled={true} value={""}>Escolha um status de pedido</option>
                                            {orderStatuses?.map(orderStatus => {
                                              return (
                                                <option key={orderStatus.id} value={orderStatus.id}>{orderStatus.status_name}</option>
                                              );
                                            })}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                                <Row className="mt-3">
                                    <Col md="4">
                                        <FormGroup>
                                            <Label>
                                                <h6>Ordenar por data do pedido</h6>
                                            </Label>
                                            <Input 
                                                type="select" 
                                                defaultValue="desc"
                                                onChange={event => setQueryFilter(prevState => ({...prevState, orderBy: event.target.value}))}
                                                >
                                                <option value="desc">Ordem decrescente</option>
                                                <option value="asc">Ordem crescente</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label>
                                                <h6>Data inicial</h6>
                                            </Label>
                                            <Input 
                                                type="date" 
                                                value={queryFilter?.initialDate}
                                                onChange={event => setQueryFilter(prevState => ({...prevState, initialDate: event.target.value}))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label>
                                                <h6>Data final</h6>
                                            </Label>
                                            <Input 
                                                type="date" 
                                                value={queryFilter?.finalDate}
                                                onChange={event => setQueryFilter(prevState => ({...prevState, finalDate: event.target.value}))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            <Row>
                                <Col className="mt-2">
                                    <Button className="btn btn-success" type="submit" title="Pesquisar" style={{ width: '130px' }} onClick={handleFilters}>Filtrar</Button>
                                </Col>
                                {queryFilter?.showClearFiltersButton && 
                                    <Col className="col-sm-4 mt-2">
                                        <Button className="btn btn-danger" type="submit" title="Pesquisar" style={{ width: '200px' }} onClick={handleClearFilterButton}>Limpar Filtros</Button>
                                    </Col>
                                }
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <Table hover striped responsive>
                                         <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Cliente</th>
                                                <th className="text-center">Total</th>
                                                <th className="text-center">Status</th>
                                                <th className="text-center">Criado em</th>
                                                <th className="text-center">Visualizar</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orders?.map(order => (
                                                <tr key={order?.id}>
                                                    <td>{order?.id}</td>
                                                    <td className="py-5">
                                                        <Row>
                                                            <Col><b className="mr-2">NOME:</b>{order?.customer?.user_firstname} {order?.customer?.user_lastname}</Col>
                                                        </Row>
                                                        <hr />
                                                        <Row>
                                                            <Col><b className="mr-2">EMAIL:</b>{order?.customer?.email}</Col>
                                                        </Row>
                                                        <hr />
                                                        <Row>
                                                            <Col><b className="mr-2">TELEFONE:</b> {order?.customer?.telephone}</Col>
                                                        </Row>
                                                    </td>
                                                    <td className="text-center">R$ {calculateOrderTotal(order?.total, order?.total_with_discounts, order?.freight?.freight_value)}</td>
                                                    <td className="text-center">
                                                        <Row>
                                                            <Col>
                                                                {order?.order_status?.status_name}
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                    <td className="text-center">{datehelper.formatDateWithHours(order.created_at)}</td>
                                                    <td className="text-center">
                                                        <Link to={`/admin/pedidos/${order.id}/detalhes`}>
                                                            <MDBBtn className="btn btn-primary btn-icon lanamaria-icons icon-zoom-split" title="Visualizar"></MDBBtn>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <select 
                                        onChange={event => setOrdersPerPage(parseInt(event.target.value))}
                                        className="custom-select custom-select-sm form-control form-control-sm" 
                                        style={{ width: '200px', position: 'relative', float: 'left', marginRight: '10px' }}>
                                        {pagesAvailable.map((p, index) =>
                                            <option key={index}>{p} resultados de {totalOrders}</option>
                                        )}
                                    </select>
                                    <ul data-test="pagination" className="pagination m-0" style={{ paddingTop: '3px' }}>
                                        <li data-test="page-item" className={"page-item " + (page === 1 ? 'disabled' : '')}>
                                            <button data-test="page-link" aria-label="Anterior" className="page-link page-link" onClick={() => setPage(Math.max(page - 1, 1))}>
                                                <span><i className="fas fa-chevron-left"></i></span>
                                            </button>
                                        </li>
                                        <span style={{ marginLeft: '5px', marginRight: '5px' }}>
                                            {page}
                                        </span>
                                        <li data-test="page-item" className={"page-item " + (page === lastPage ? 'disabled' : '')}>
                                            <button data-test="page-link" aria-label="Anterior" className="page-link page-link" onClick={() => setPage(Math.min(page + 1, lastPage))}>
                                                <span><i className="fas fa-chevron-right"></i></span>
                                            </button>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ManageStatus;