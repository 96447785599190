import Switch from "react-switch";
import toast from 'react-hot-toast';
import Cropper from "react-easy-crop";
import { Link } from "react-router-dom";
import { Slider } from '@material-ui/core';
import Loader from 'components/Loader/Loader';
import React, { useState, useEffect, useCallback } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row} from 'reactstrap';
import NotFoundImage from '../../../assets/img/no-product-image.jpeg'

import getCroppedImg from 'helpers/cropper';
import StoreProductImageService from 'services/StoreProductImageService';



const cropSizes = {
    "vertical": 1 / 2,
    "horizontal": 100 / 50,
    "default": 100 / 100
};

function Edit(props) {
    const imageId = props.match.params.id;

    const [loading, setLoading] = useState(true);

    const [externalCode, setExternalCode] = useState(0);
    const [productImage, setProductImage] = useState("");
    const [productNewImage, setProductNewImage] = useState("");
    
    const [isImageHorizontal, setIsImageHorizontal] = useState(false);
    
    const [zoom, setZoom] = useState(1.2);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedImage, setCroppedImage] = useState({binary: null, preview: null});
    const [cropSize, setCropSize] = useState(cropSizes?.default);

    useEffect(() => {
        setLoading(false);
        StoreProductImageService.getProductImageById(imageId).then(response => {
            const { data } = response; 
            const imageBaseUrl = process.env.REACT_APP_IMAGE_BASE_URL;
            const imagePath = data?.path;
            const imageName = data?.name;

            const imageLink = imageBaseUrl + imagePath + imageName;
            setProductImage(imageLink);
            setExternalCode(data?.external_code);
        });
    }, []);

    const handleUserFile = (event) => {
        setProductNewImage(URL.createObjectURL(event.target.files[0]));
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const cropImage = useCallback(async () => {
        try {
           getCroppedImg(productNewImage,croppedAreaPixels)
           .then(image => {
               setCroppedImage({
                   preview: image?.previewImage,
                   binary: image?.binaryImage
               });

               toast.success("Imagem cortada com sucesso!");
           });
        } catch (error) {
            console.log(error);
            toast.error("Selecione uma imagem");
        }
    }, [croppedAreaPixels, productNewImage]);
    
    const handleProductImage = () => {
        if(croppedImage?.binary === null) {
            toast.error('Selecione uma imagem');
            return;
        }

        let form = new FormData();
        form.append('id', imageId);
        form.append('image', croppedImage?.binary);

        toast.promise(StoreProductImageService.updateProductImageById(form), {
            loading: 'Salvando...',
            success: 'Imagem salva com sucesso!'
        }).then((response) => {
            setTimeout(() => {
                props.history.push(`/admin/produtos/variantes/${externalCode}/editar`);
            }, 1500);
        });
    }

    const handleCropSizeChange = inputEvent => {
        const selectedCropSize = inputEvent?.target?.value;

        setCropSize(cropSizes[selectedCropSize]);
    }

    return (
        <div className="content">
            {loading ?
                <Loader loading={loading} />
                :
                <Row>
                    <Col>
                        <h3 className="title">Editar imagem do produto</h3>
                        <Card>
                            <CardHeader>
                                <p className="category">* Editando a imagem do produto</p>
                            </CardHeader>
                            <CardBody>
                                <hr className='mtn-10' />
                                <Row>
                                    {productImage?.department &&
                                        <Col>
                                            <Label>
                                                <h6>Departamento</h6>
                                            </Label>
                                            <Input type="text" value={`${productImage?.department?.CodigoInterno_Departamento}- ${productImage?.department?.Descricao_Departamento}`} readOnly disabled />
                                        </Col>
                                    }

                                    {productImage?.section &&
                                        <Col>
                                            <Label>
                                                <h6>Sessão</h6>
                                            </Label>
                                            <Input type="text" value={`${productImage?.section?.CodigoInterno_Secao}- ${productImage?.section?.Descricao_Secao}`} readOnly disabled />
                                        </Col>
                                    }
                                    
                                    {productImage?.group &&
                                        <Col>
                                            <Label>
                                                <h6>Grupo</h6>
                                            </Label>
                                            <Input type="text" value={`${productImage?.group?.CodigoInterno_Grupo}- ${productImage?.group?.Descricao_Grupo}`} readOnly disabled />
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    <Col md="12" className="d-flex justify-content-center mt-4 mb-5">
                                        <div className="product-image-preview d-flex flex-column align-items-center">
                                            <Label>
                                                <h6>Imagem do produto</h6>
                                            </Label>
                                            <img src={productImage ? productImage : NotFoundImage} alt="Imagem cortada" style={{maxHeight: 300}}/>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="d-flex justify-content-center">
                                    <Col md="6">
                                        <div className="content-preview-img-form">
                                            <div className="cropper-bg" style={{height: 300}}/>
                                            <Cropper 
                                                crop={crop}
                                                zoom={zoom}
                                                showGrid={true}
                                                objectFit="contain"
                                                onZoomChange={setZoom}
                                                onCropChange={setCrop}
                                                image={productNewImage}
                                                onCropComplete={onCropComplete}
                                                aspect={cropSize}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="6" className="d-flex justify-content-center my-5">
                                        {croppedImage?.preview &&
                                            <div className="product-image-preview d-flex flex-column align-items-center">
                                                <Label>
                                                    <h6>Pré-visualização da imagem</h6>
                                                </Label>
                                                <img src={croppedImage?.preview} alt="Imagem cortada" style={{maxHeight: 300}}/>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col md="4" className="mb-4">
                                        <Label>
                                            <h6>Zoom da imagem</h6>
                                        </Label>
                                        <Slider 
                                            min={1}
                                            max={3}
                                            step={0.1}
                                            zoom={zoom}
                                            value={zoom}
                                            valueLabelDisplay="auto"
                                            className="custom-slider"
                                            aria-label="Zoom da imagem"
                                            onChange={(e, zoom) => setZoom(zoom)}
                                        />
                                    </Col>
                                    <Col md="8" className="d-flex flex-column align-items-center mt-1">
                                        <Col md="5">
                                            <Label for="">
                                                <h6>Tamanho do corte</h6>
                                            </Label>
                                            <Input id="crop-size" type="select" defaultChecked="default" onChange={(event) => handleCropSizeChange(event)}>
                                                <option value="default">Tamanho padrão</option>
                                                <option value="vertical">Tamanho vertical</option>
                                                <option value="horizontal">Tamanho horizontal</option>
                                            </Input>
                                        </Col>
                                    </Col>
                                </Row>
                                <Row className="mt-5">
                                    <Col md="6">
                                        <FormGroup>
                                            <Col>
                                                <Row>
                                                    <Input type="file" className="form-control" name="image_preview_url" accept="image/*" onLoad={(event) => { event.target.value = null }} onChange={handleUserFile} style={{ border: '1px solid rgba(0, 0, 0, 0.5)', borderRadius: '5px', fontSize: '13px', padding: '10px', marginTop: '4px', width: '100%', float: 'left', opacity: 1, position: 'initial' }} required />
                                                </Row>
                                                <Row>
                                                    <Button className="btn btn-primary mt-2" onClick={cropImage} style={{ width: '140px', fontWeight: '600' }}>Cortar</Button>
                                                </Row>
                                            </Col>
                                        </FormGroup>
                                    </Col> 
                                </Row> 
                                <Row>
                                    <Col>
                                        <Link to={`/admin/produtos/variantes/${externalCode}/editar`}>
                                            <Button className="btn-danger mr-5">Voltar ao produto</Button>
                                        </Link>
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col className="d-flex justify-content-center">
                                        <Button onClick={handleProductImage} style={{ width: '140px' }}>Salvar</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </div>
    )
}


export default Edit;