import { Col, Row } from "reactstrap";

const transactionStatus = {
    "approved": "Aprovado",
    "denied": "Reprovado",
    "canceled": "Cancelado",
    "pending": "Pendente"
};

const transactionStatusColors = {
    "approved": "success",
    "denied": "danger",
    "canceled": "danger",
    "pending": "primary"
}


export default function CreditCard({ orderPayment }) {
    const formatNumberToBrl = number => {
        const numberSize = number?.toString()?.length
        const numberLastTwoDigits = numberSize - 2;

        const n1 = number?.toString()?.slice(0, numberLastTwoDigits);
        const n2 = number?.toString()?.slice(numberLastTwoDigits)
        return `${n1},${n2}`
    }
    
    return  (
        <>
            <Row className="mt-3">
                <Col md="4">
                    <h4 className="title">Pagamento (Crédito)</h4>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md="6">
                    <h5><b>Nome do portador:  </b> {orderPayment?.authorization?.cardHolderName}</h5>
                </Col>
                <Col md="6">
                    <h5><b>Número: </b> {orderPayment?.authorization?.cardBin} ***** {orderPayment?.authorization?.last4}</h5>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md="3">
                    <h5><b>Parcelas: </b>{orderPayment?.authorization?.installments === 0 || orderPayment?.authorization?.installments === 1 ? '1' : orderPayment?.authorization?.installments}X</h5>
                </Col>
                <Col md="3">
                    <h5><b>Total: </b>{formatNumberToBrl(orderPayment?.authorization?.amount)}</h5>
                </Col>
                
                <Col md="3" className="d-flex">
                    <div>
                        <h5><b>Status: </b></h5>
                    </div>
                    <div className="ml-2">
                        <span className={`badge badge-pill badge-${transactionStatusColors?.[orderPayment?.authorization?.status?.toLowerCase()]}`}>
                            {transactionStatus?.[orderPayment?.authorization?.status?.toLowerCase()]}
                        </span>
                    </div>
                </Col>
            </Row>
            <hr />
        </>
    );
}

