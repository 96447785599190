/**
 * Author: @marcosppastor
*/

/**
 * Handle currency
 * @param {number} currency
 * @returns {string}
 * @example
 * currency.handle(100.00) // 100,00
*/

const currency = {};

currency.int = (currency) => {
    return parseInt(currency);
}

currency.float = (currency) => {
    return parseFloat(currency);
}

currency.fixed = (currency) => {
    return currency.toFixed(2);
}

currency.handle = () => {
    return currency.toLocaleString('pt-br', { minimumFractionDigits: 2 });
}

currency.handleToBRL = (currency) => {
    return parseFloat(currency).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

export default currency;