import toast from "react-hot-toast";
import Cropper from "react-cropper";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/pt-br";

import BlogService from "services/BlogService";
import CkEditorUploadAdapter from "../../../helpers/CkEditorUploadAdapter";


function CreatePost(props) {
  const [categories, setCategories] = useState([]);

  const [post, setPost] = useState({
    title: "",
    subtitle: "",
    content: "",
    category: ""
  });

  const [cropper, setCropper] = useState({
    image: null,
    src: '',
    cropResult: null,
    sendImage: null
  });


  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = () => {
    toast.promise(BlogService.getPostCategories(), {
      loading: 'Carregando',
      success: 'Pronto!',
      error: 'Falha na comunicação com o servidor'
    }).then(data => {
      setCategories(data);
    });
  }

  const savePost = () => {
    let form = new FormData();

    if(!cropper?.sendImage) {
      toast.error('Selecione uma imagem!')
      return;
    }

    form.append('title', post?.title);
    form.append('subtitle', post?.subtitle);
    form.append('content', post?.content);
    form.append('category', post?.category);
    form.append('image', cropper?.sendImage);

    toast.promise(BlogService.storePost(form), {
      loading: 'Salvando...',
      error: 'Falha na comunicação com o servidor',
      success: 'Publicação cadastrada com sucesso!'
    }).then(() => {
      setTimeout(() => {
        props.history.push('/admin/blog/gerenciar');
      }, 1500);
    });
  }

  const onChange = (e) => {
    const files = e.target.files;

    if (files && files.length > 0) {
        const reader = new FileReader();
        reader.addEventListener('load', () =>
            setCropper({ ...cropper, src: reader.result })
        );
        reader.readAsDataURL(files[0]);
    }
  }

  const cropImage = () => {
    if (!cropper.cropper.getCroppedCanvas() || cropper.cropper.getCroppedCanvas().toDataURL() === null) {
        toast.error('Selecione uma imagem');
        return;
    }

    if (typeof cropper.cropper.getCroppedCanvas() === 'undefined') {
        return;
    }

    cropper.cropper.getCroppedCanvas().toBlob((blob) => {
        let imageUrl = URL.createObjectURL(blob);
        setCropper({
            ...cropper,
            cropResult: imageUrl,
            sendImage: blob
        });
    }, 'image/jpeg');

    toast.success('Imagem cortada com sucesso');
  }
  return(
    <div className="content">
      <Row>
        <Col>
          <h3 className="title">Criar publicação</h3>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <Label className="font-weight-bolder text-dark">
                    Titulo
                  </Label>
                  <Input onChange={(e) => setPost({...post, title: e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label className="font-weight-bolder mt-5 text-dark">
                    Sub título
                  </Label>
                  <Input  onChange={(e) => setPost({...post, subtitle: e.target.value})}/>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col>
                  <Label className="font-weight-bolder mt-5 text-dark">
                    Categoria
                  </Label>
                  <Input
                    type="select" 
                    defaultValue=""
                    onChange={(e) => setPost({...post, category: e.target.value})}>
                    <option disabled={true} value="">Escolha uma categoria</option>
                    {categories.map(category => {
                      return (
                        <option key={category.id} value={category.id}>{category.name}</option>
                      );
                    })}
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col>
                    <Row>
                      <Col md="6" className="mt-5">
                        {!cropper?.src && <div className="cropper-bg"></div>}
                        <Cropper
                          responsive={true}
                          viewMode={1}
                          minCropBoxHeight={10}
                          minCropBoxWitdh={10}
                          src={cropper?.src}
                          cropBoxResizable={false}
                          dragMode="move"
                          zoomOnWheel={false}
                          onInitialized={(instance) => {
                              setCropper({
                                  ...cropper,
                                  cropper: instance
                              });
                          }}
                        />
                      </Col>
                      <Col>
                        {cropper?.cropResult && 
                          <div className="mt-5 d-flex flex-column align-items-center">
                           <Label>
                              <h6>Pré-visualização do Banner da postagem</h6>
                           </Label>
                           <div className="content-preview-img-form mt-4">
                              <img style={{ width: "100%", height: '250px' }} src={cropper?.cropResult || cropper?.image} alt="Imagem cortada" />
                            </div>

                          </div>
                        }
                      </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col md="9">
                            <Input type="file" className="edit-image-input" name="image_preview_url" accept="image/*" onLoad={(event) => { event.target.value = null }} onChange={onChange} required />
                        </Col>
                        {cropper?.src &&
                          <Col md="3">
                              <Button className="btn btn-primary" onClick={cropImage} style={{width: 200}}>Cortar imagem</Button>
                          </Col>
                        }
                    </Row>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <Label className="font-weight-bolder text-dark">
                    Texto
                  </Label>
                  <CKEditor
                    editor={ClassicEditor}
                    onChange={(event, editor) => setPost({...post, content: editor.getData()})}
                    onReady={editor => {
                      editor.plugins.get("FileRepository").createUploadAdapter = loader => {
                        return new CkEditorUploadAdapter(loader);
                      }
                    }}
                    config={{
                      toolbar: {
                        items: [
                          'heading',
                          '|',
                          'link',
                          '|',
                          'bold',
                          'italic',
                          '|',
                          'bulletedList',
                          'numberedList',
                          '|',
                          'imageUpload',
                          '|',
                          'undo',
                          'redo'
                        ]
                      },
                      image: {
                        toolbar: [
                            'imageStyle:alignLeft',
                            'imageStyle:alignCenter',
                            'imageStyle:alignRight',
                            '|',
                            'imageStyle:full',
                            'imageStyle:side',
                            '|',
                            'imageTextAlternative'
                          ],
                          styles: [
                            'full',
                            'side',
                            'alignLeft', 'alignCenter', 'alignRight'
                          ],
                          resizeOptions: [
                            {
                                name: 'imageResize:original',
                                label: 'Original',
                                value: null
                            },
                            {
                                name: 'imageResize:50',
                                label: '50%',
                                value: '50'
                            },
                            {
                                name: 'imageResize:75',
                                label: '75%',
                                value: '75'
                            }
                        ]
                      },
                      table: {
                        contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
                      },                
                      language: "pt-br",
                    }}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col className="d-flex flex-row justify-content-center">
                  <Button onClick={savePost}>
                      Salvar
                  </Button>   
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default CreatePost;