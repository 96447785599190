function ModalWindow(props) {

    const windowStyle = {
        position: 'fixed', /* Stay in place */
        zIndex: 1, /* Sit on top */
        left: 0,
        top: 0,
        width: '100%', /* Full width */
        height: '100%', /* Full height */
        overflow: 'auto', /* Enable scroll if needed */
        backgroundColor: 'rgba(0,0,0,0.4)' /* Black w/ opacity */
    }

    const containerStyle = {
        position: 'absolute',
        left: '50%',
        top: '50%',
        WebkitTransform: 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)',
        width: props.width,
        maxHeight: '72%',
        overflowY: 'auto'
    }


    return props.show ? <div style={windowStyle} className={!props.printable && 'non-printable'}>
        <div style={containerStyle}>
            {props.children}
        </div>
    </div>
        :
        null
}

export default ModalWindow;
