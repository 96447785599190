import { Col, Row } from 'reactstrap';
import LoaderGif from '../../loader.gif';

function Loader (props) {
    const { loading } = props;

    return (
        <Row>
            <Col>
                <img src={LoaderGif} className={`search-loading ${loading ? 'show' : 'hide'}`} alt="loader" />
            </Col>
        </Row>
    )
}

export default Loader;