import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Input, Label, Row } from 'reactstrap';

import BlogService from 'services/BlogService';
import imgHeaderHome from "../../../assets/img/home-header-1.png";

const imageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

function PostDetails(props) {
    const postId = props.match.params.id;

    const [post, setPost] = useState({});

    useEffect(() => {
        getPostData();
      }, []);
    
      const getPostData = () => {
        toast.promise(BlogService.getPostData(postId), {
            loading: 'Carregando dados da postagem',
            success: 'Pronto!',
            error: 'Falha na comunicação com o servidor'
        }).then(data => {
          setPost(data);
        });
    }

    const approvePost = () => {
        toast.promise(BlogService.approvePostById(postId), {
            loading: 'Aprovando postagem',
            success: 'Pronto!',
            error: 'Falha na comunicação com o servidor'
        }).then(() => {
            setTimeout(() => {
                props.history.push("/admin/blog/gerenciar/status");
            }, 2000);
        });
    }

    const disapprovePost = () => {
        toast.promise(BlogService.disapprovePostById(postId), {
            loading: 'Reprovando postagem',
            success: 'Pronto!',
            error: 'Falha na comunicação com o servidor'
        }).then(() => {
            setTimeout(() => {
                props.history.push("/admin/blog/gerenciar/status");
            }, 1500);
        });
    }

    return(
        <div className="content">
            <Row>
                <Col>
                    <h3 className="title">Detalhes da postagem</h3>
                    <Card>
                        <CardHeader>
                            <p className="category">*detalhes da postagem nº "{post?.id}"</p>
                        </CardHeader>
                        <CardBody>
                            <hr className='mtn-10' />
                                <Row>
                                    <Col md="4" className="mt-4">
                                        <Label>
                                            <h6>Código</h6>
                                        </Label>
                                        <Input type="number" defaultValue={post?.id} readOnly disabled />
                                    </Col>
                                    <Col md="4" className="mt-4">
                                        <Label>
                                            <h6>Título</h6>
                                        </Label>
                                        <Input type="text" defaultValue={post?.title} readOnly disabled />
                                    </Col>
                                    <Col md="4" className="mt-4">
                                        <Label>
                                            <h6>Subtítulo</h6>
                                        </Label>
                                        <Input defaultValue={post?.subtitle} type="text" readOnly disabled />
                                    </Col>
                                </Row>
                                <hr />
                                <Row>
                                    <Col md="12" style={{ textAlign: 'center' }}>
                                        <div className="content-preview-img-form">
                                            {post?.image && 
                                                <>
                                                    <img src={imgHeaderHome} alt="Imagem do 'header home'" className="blog-banner-post-preview"/>
                                                    <div className="post-preview-banner-container">
                                                        <img src={`${imageUrl}${post?.image?.path}${post?.image?.name}`} height={270} alt="Banner da publicação" className="blog-banner-post-preview post-banner" />
                                                        <div className="post-preview-title">
                                                            {post?.title}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                        <div className="mt-4 blog-content-post-preview" dangerouslySetInnerHTML={{__html: post?.content }}></div>
                                    </Col>
                                </Row>
                                <hr />
                                <Row className="d-flex justify-content-center">
                                    <Col md="4" className="mt-5">
                                        <Link  to={`/admin/blog/${postId}/editar`}> 
                                            <Button style={{ width: '100%' }}>Editar postagem</Button>
                                        </Link>
                                    </Col>
                                    {post?.post_statuses_id !== 2   && 
                                        <Col md="4" className="mt-5">
                                            <Button 
                                                style={{ width: '100%' }}
                                                className="btn btn-success"
                                                onClick={approvePost}>Aprovar postagem</Button>
                                        </Col>                                    
                                    }

                                    {post?.post_statuses_id !== 3 &&
                                        <Col md="4" className="mt-5">
                                            <Button 
                                                style={{ width: '100%' }}
                                                className="btn btn-danger" 
                                                onClick={disapprovePost}>Reprovar postagem</Button>
                                        </Col>
                                    }
                                </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default PostDetails;