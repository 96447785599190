import Switch from "react-switch";
import toast from "react-hot-toast";
import { Cropper } from "react-cropper";
import { useState, useRef } from "react";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import { Button, Card, CardBody, CardHeader, Col, Collapse, Input, Label, Row } from "reactstrap";

import imgNoBackground from "../../../../assets/img/no_background.png"
import ImageService from "services/ImageService";

function CreateLogo(props) {
  const [newLogo, setNewLogo] = useState(null);
  const [croppedLogo, setCroppedLogo] = useState(null);
  const [croppedLogoPreview, setCroppedLogoPreview] = useState(null);
  const [isLogoActiveChecked, setIsLogoActiveChecked] = useState(false);
  const [isLoadLogoDropdownOpen, setIsLoadLogoDropdownOpen] = useState(false);


  const cropperRef = useRef(null);

  const handleLogoImageChange = (event) => {
    event.preventDefault();
    const files = event.target.files;

    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
          setNewLogo(reader.result)
      );
      reader.readAsDataURL(files[0]);
    }
  }

  const handleCropLogo = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    if (cropper?.cropped) {
      if (typeof cropper !== "undefined" || typeof cropper !== "") {
          return new Promise(
              resolve => {
                  cropper.getCroppedCanvas().toBlob(file => {
                    const blobUrl = URL.createObjectURL(file);
                    setCroppedLogoPreview(cropper.getCroppedCanvas().toDataURL());
                    setCroppedLogo(blobUrl);
                    resolve(file)
                  }, 'image/jpeg')
              }
          )
      }
    } else {
        toast.error("É necessário carregar a imagem");
    }
  };

  const handleSaveLogo = () => {
    const formImage = new FormData();

    if(croppedLogo !== null) {
      const file = dataURLtoFile(croppedLogoPreview, "logo");
      if (typeof file !== 'undefined') {
        formImage.append('image', file);
        formImage.append('active', isLogoActiveChecked ? true : false);
      }
      toast.promise(ImageService.createLogo(formImage), {
        loading: 'Criando logo ...',
        success: 'Logo criada com sucesso!'
      }).then(() => {
        setTimeout(() => {
          props.history.push('/admin/imagens/logo');
        }, 1500);
      });
    }
  }


  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const base64MimeType = encoded => {
    let result = null;

    if (typeof encoded !== 'string') {
        return result;
    }

    let mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if (mime && mime.length) {
        result = mime[1].replace('image/', '');
    }

    return result;
  }

  return(
    <div className="content">
      <div>
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <Row>
                  <Col md="10">
                    <h3 className="title">Cadastrar Logo</h3>
                  </Col>
                </Row>
              <hr />
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <button
                      className="btn-dropdown"
                      onClick={() => setIsLoadLogoDropdownOpen(prevState => !prevState)}
                    >
                      Seleção de logo
                      {isLoadLogoDropdownOpen ? <FaMinusCircle size={18}/> : <FaPlusCircle size={18}/>}
                    </button>
                  </Col>
                </Row>
                <Collapse isOpen={isLoadLogoDropdownOpen} >
                  <Row>
                    <Col md="6">
                      {newLogo === null
                        ?
                          <img src={imgNoBackground} alt="" width="100%" height={"100%"}/>
                        : 
                        <Cropper 
                          responsive={true}
                          viewMode={0}
                          minCropBoxHeight={10}
                          minCropBoxWitdh={1000}
                          src={newLogo}
                          cropBoxResizable={true}
                          dragMode="move"
                          zoomOnWheel={false}
                          ref={cropperRef}/>
                      }
                    </Col>
                    <Col md="6">
                      {croppedLogo !== null &&
                        <div>
                          <img id="cropped" 
                            alt="Imagem cortada"
                            src={croppedLogo} 
                            style={{ padding: '45px', border: '1px solid #d5d5d5', borderRadius: '0.2857rem', boxShadow: '0 1px 20px 0 rgb(0 0 0 / 10%)' }}/>
                        </div>}
                    </Col>
                  </Row>
                  <Row className="d-flex flex-row justify-content-center align-items-center">
                    <Col className="my-3">
                      <Input 
                        type="file"
                        name="logoImage"
                        className="edit-image-input"
                        onLoad={event => event.target.value}
                        onChange={handleLogoImageChange}/>
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col md="6">
                      <Button size="sm" onClick={handleCropLogo}>
                        Cortar imagem
                      </Button>
                    </Col>
                    <Col md="6">
                      {croppedLogo && 
                        <Label >
                          <h6>Ativo/Inativo</h6>
                            <Switch onChange={checked => setIsLogoActiveChecked(checked)} checked={isLogoActiveChecked}/>
                        </Label>}
                    </Col>
                  </Row>
                </Collapse>
                <hr />
                <Row>
                  <Col className="d-flex flex-row justify-content-center">
                  {croppedLogo && 
                    <Button onClick={handleSaveLogo}>
                        Salvar
                    </Button>}      
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default CreateLogo;