import { createContext } from "react";

const authContext = createContext({
    token: "",
    user: {},
    isAuthenticated: null,
    authenticateUser: () => {},
    getUserSession: () => {},
    signOutUser: () => {}
});

export default authContext;