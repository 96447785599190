import api from "./api";

class StoreProductService {
    /**
     * Store product image
     * @param {object} data
     * @return {Promise}
     */
    storeProductImage = (data) => {
        return api.post(`/store/product-images/save`, data, {
            headers: {
                'Content-Type': 'binary'
                // 'Content-Type': 'multipart/form-data'
            }
        });
    }

    saveMainProductImage = data => {
        return api.post('/store/product-images/main-product/save', data, {
            headers: {
                'Content-Type': 'binary' 
            }
        });
    }

    /**
     * Get product image by external code
     * @param {integer} externalCode
     * @return {Promise}
    */
    getProductImageByExternalCode = (externalCode) => {
        const response = api.get(`/store/product-images/show?id=${externalCode}`);
        return response;
    }


    /**
    * Delete product image by external code
    * @param {integer} externalCode
    * @return {Promise}
    */
    deleteProductImageByExternalCode = (externalCode) => {
        const response = api.delete(`/store/product-images/${externalCode}/delete`);
        return response;
    }

    /**
     * Get product variant image by his id. 
     *
     * @param {Integer} id 
     * @returns 
     */
    getProductImageById = id => {
        const response = api.get(`/store/product-images?id=${id}`);
        return response;
    }

    /**
     * Delete product variant image by id 
     *
     * @param {Object} requestBody 
     * @returns 
     */
    updateProductImageById = requestBody => {
        const response = api.post("/store/product-images/update", requestBody);
        return response;
    }

    deleteProductImageByImageId = imageId => {
        const response = api.delete(`/store/product-images/delete?id=${imageId}`);
        return response;
    }
}

export default new StoreProductService();