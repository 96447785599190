import toast from "react-hot-toast";
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ModalWindow from 'components/ModalWindow/ModalWindow';
import { Button, Card, CardBody, CardHeader, Col, Row, Table} from 'reactstrap';

import UserService from 'services/UserService';
import datehelper from "helpers/datehelper";

const pagesAvailable = [10, 20, 25, 50, 100];

function CouponIndex() {
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [totalCoupons, setTotalCoupons] = useState(0)
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [users, setUsers] = useState(null);
    const [deleteUser, setDeleteUser] = useState(
        {
            userToDelete: null,
            showModal: false
        }
    );

    useEffect(() => {
        getUsers();
    }, [page, postsPerPage]);

    const getUsers = () => {
        toast.promise(UserService.getUsers(page, postsPerPage), {
            loading: 'Buscando...',
            success: 'Pronto!',
            error: "Não foi possível buscar os usuários"
        }).then(response => {
            const { data, total, last_page } = response.data;

            setUsers(data);
            setTotalCoupons(total);
            setLastPage(last_page);
        });
    }

    const handleDeleteCoupon = () => {
        if (!deleteUser.showModal) {
            return null;
        }

        return (
            <ModalWindow show={deleteUser.showModal} printable={false} width="300px">
                <Card>
                    <CardHeader>
                        <Row>
                            <Col style={{ textAlign: 'end', marginTop: '-5px' }}>
                                <Button className="btn btn-danger btn-icon lanamaria-icons icon-simple-remove" title="Fechar" onClick={() => setDeleteUser({ ...deleteUser, userToDelete: null, showModal: false })} />
                            </Col>
                            <Col md="12">
                                <h6 style={{ fontSize: '26px', textTransform: 'initial', marginTop: '4px' }}>Confirmar exclusão</h6>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <p>Você confirma a exclusão do usuario nº {deleteUser?.userToDelete?.id}</p>
                        <small style={{ color: 'black' }}>
                            <b>*Obs.: ao confirmar, o usuário será deletado permanentemente</b>
                        </small>
                        <hr />
                        <Button className="btn btn-blue" type="submit" title="Excluir usuário" style={{ width: '100%' }} onClick={() => {
                            setDeleteUser({ ...deleteUser, userToDelete: null, showModal: false });
                            deleteUserByUserId(deleteUser?.userToDelete?.id);
                        }}>Sim</Button>
                    </CardBody>
                </Card>
            </ModalWindow>
        )
    }

    const deleteUserByUserId = (id) => {
        toast.promise(UserService.deleteUserByUserId(id), {
            loading: 'Deletando deletando cupom...',
            success: 'Usuário deletado com sucesso!',
            error: 'Não foi possível deletar o cupom'
        }).then(() => {
            setTimeout(() => {
                getUsers();
            }, 1000);
        });
    }

    return (
        <div className="content">
            <Row>
                <Col>
                    <h3 className="title">Usuários</h3>
                    <div className="text-left">
                        {handleDeleteCoupon()}
                        <Link to={'/admin/usuarios/criar'} className="btn btn-blue mb-20">Criar novo usuário</Link>
                    </div>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <Table hover striped responsive>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '5%' }}># ID</th>
                                                <th style={{ width: '15%' }}>Nome </th>
                                                <th style={{ width: '10%', textAlign: 'center' }}>Email</th>
                                                <th style={{ width: '10%', textAlign: 'center' }}>Criado em</th>
                                                <th style={{ width: '10%', textAlign: 'center' }}>Verificado em</th>
                                                <th style={{ width: '10%', textAlign: 'center' }}>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users?.map(user => (
                                                <tr key={user?.id}>
                                                    <td>{user?.id}</td>
                                                    <td>
                                                        <p>{user?.user_firstname} {user?.user_lastname}</p>
                                                    </td>
                                                    <td className="text-center">
                                                        <p>
                                                            {user?.email}
                                                        </p>
                                                    </td>                                                    
                                                    <td className="text-center">
                                                        <p>{datehelper.formatDateWithHours(user?.created_at)}</p>
                                                    </td>
                                                    <td className="text-center">
                                                        {user?.verificated_at 
                                                            ? <p>{datehelper.formatDateWithHours(user?.verificated_at)}</p>
                                                            : <p>Email ainda não verificado</p>
                                                        }
                                                    </td>
                                                    <td className="text-center">
                                                        <Link to={`/admin/usuarios/${user?.id}/editar`}>
                                                            <Button className="btn btn-icon btn-secondary lanamaria-icons icon-pencil mr-2 mt-2" title="Editar Logo" />
                                                        </Link>
                                                        <Button
                                                            title="Excluir logo Logo"
                                                            className="btn btn-icon btn-danger lanamaria-icons icon-trash-simple mr-2 mt-2"
                                                            onClick={() => setDeleteUser({ ...deleteUser, userToDelete: user, showModal: true })} 
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <select 
                                        onChange={event => setPostsPerPage(parseInt(event.target.value))}
                                        className="custom-select custom-select-sm form-control form-control-sm" 
                                        style={{ width: '200px', position: 'relative', float: 'left', marginRight: '10px' }}>
                                        {pagesAvailable.map((p, index) =>
                                            <option key={index}>{p} resultados de {totalCoupons}</option>
                                        )}
                                    </select>
                                    <ul data-test="pagination" className="pagination m-0" style={{ paddingTop: '3px' }}>
                                        <li data-test="page-item" className={"page-item " + (page === 1 ? 'disabled' : '')}>
                                            <button data-test="page-link" aria-label="Anterior" className="page-link page-link" onClick={() => setPage(Math.max(page - 1, 1))}>
                                                <span><i className="fas fa-chevron-left"></i></span>
                                            </button>
                                        </li>
                                        <span style={{ marginLeft: '5px', marginRight: '5px' }}>
                                            {page}
                                        </span>
                                        <li data-test="page-item" className={"page-item " + (page === lastPage ? 'disabled' : '')}>
                                            <button data-test="page-link" aria-label="Próximo" className="page-link page-link" onClick={() => setPage(Math.min(page + 1, lastPage))}>
                                                <span><i className="fas fa-chevron-right"></i></span>
                                            </button>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default CouponIndex;