import { MDBBtn } from 'mdbreact';

import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import ModalWindow from 'components/ModalWindow/ModalWindow';
import { Card, CardBody, CardHeader, Col, Row, Table, FormGroup, Label, Input, Button } from 'reactstrap';

import datehelper from "helpers/datehelper";
import BlogService from 'services/BlogService';

const pagesAvailable = [10, 20, 25, 50, 100];

const defaultDate = new Date();
const todayDate = defaultDate.toISOString().substr(0, 10);

function ManageCategories() {
    const [page, setPage] = useState(1);
    const [updatePosts, setUpdatePosts] = useState(false);

    const [lastPage, setLastPage] = useState(0);
    const [totalPosts, setTotalPosts] = useState(0)
    const [perPage, setPerPage] = useState(10);

    const [categories, setCategories] = useState([]);

    const [queryFilter, setQueryFilter] = useState({
        filter: '',
        initialDate: '',
        finalDate: todayDate,
        showClearFiltersButton: false
    });

    const [deleteCategory, setDeleteCategory] = useState({
            categoryToDelete: null,
            showModal: false
        }
    );

    useEffect(() => {
       getCategories();
    }, [page, perPage, updatePosts]);

    const getCategories =  () => {
        toast.promise( BlogService.getFilteredCategories(page, perPage, queryFilter), {
            loading: 'Carregando categorias',
            success: 'Pronto',
            error: 'Falha na comunicação com o servidor'
        }).then((response) => {
            setCategories(response.data);
            setTotalPosts(response.total);
            setLastPage(response.last_page);
        });
    }

    const handleFilters = () => {
        setQueryFilter(prevState => ({
            ...prevState,
             showClearFiltersButton: true
        }));

        setUpdatePosts(prevState => !prevState)
    }

    const handleClearFilterButton = () => {
        setQueryFilter({
            filter: '',
            initialDate: '',
            finalDate: todayDate,
            showClearFiltersButton: false
        });

        if(page !== 1) {
            setPage(1);
            return;
        }
        
        setUpdatePosts(prevState => !prevState);
        toast.success('Filtros removidos')
    }

    const deleteCategoryById = (categoryId) => {
        toast.promise(BlogService.deleteCategoryById(categoryId), {
            loading: 'Excluindo categoria',
            success: 'Pronto!',
            error: 'Falha na comunicação com o servidor'
        }).then(() => {
            setUpdatePosts(prevState => !prevState)
        });
    }

    const handleDeleteCategory = () => {
        if (!deleteCategory.showModal) {
            return null;
        }

        return (
            <ModalWindow show={deleteCategory.showModal} printable={false} width="300px">
                <Card>
                    <CardHeader>
                        <Row>
                            <Col style={{ textAlign: 'end', marginTop: '-5px' }}>
                                <Button className="btn btn-danger btn-icon lanamaria-icons icon-simple-remove" title="Fechar" onClick={() => setDeleteCategory({ ...deleteCategory, categoryToDelete: null, showModal: false })} />
                            </Col>
                            <Col md="12">
                                <h6 style={{ fontSize: '26px', textTransform: 'initial', marginTop: '4px' }}>Confirmar exclusão</h6>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <p>Você confirma a exclusão da categoria nº {deleteCategory?.categoryToDelete?.id}</p>
                        <small style={{ color: 'black' }}>
                            <b>*Obs.: ao confirmar, a categoria será deletado permanentemente</b>
                        </small>
                        <hr />
                        <Button className="btn btn-blue" type="submit" title="Excluir logo" style={{ width: '100%' }} onClick={() => {
                            setDeleteCategory({ ...deleteCategory, logoToDelete: null, showModal: false })
                            deleteCategoryById(deleteCategory?.categoryToDelete?.id);
                        }}>Sim</Button>
                    </CardBody>
                </Card>
            </ModalWindow>
        )
    }
    
    return(
        <div className="content">
            {handleDeleteCategory()}
            <Row>
                <Col>
                    <h3 className="title">Categorias</h3>
                    <Link to="/admin/blog/categoria/criar">
                        <Button className="my-3" style={{width: 240}}>Criar nova categoria</Button>
                    </Link>
                    <Card>
                        <CardHeader>
                            <p className="category">* Lista de todas as categorias</p>
                        </CardHeader>
                        <CardBody>
                            <Label>
                                <h5 style={{ fontSize: '20px' }}><b>Filtro</b></h5>
                            </Label>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>
                                            <h6>Pesquisar por nome ou código da categoria</h6>
                                        </Label>
                                        <Input type="text" name="product_name_code" placeholder="Busque por título ou código da publicação" value={queryFilter?.filter} onChange={event => setQueryFilter(prevState => ({...prevState, filter: event.target.value}))}/>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>
                                            <h6>Data inicial</h6>
                                        </Label>
                                        <Input 
                                            type="date" 
                                            value={queryFilter?.initialDate}
                                            onChange={event => setQueryFilter(prevState => ({...prevState, initialDate: event.target.value}))}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>
                                            <h6>Data final</h6>
                                        </Label>
                                        <Input 
                                            type="date" 
                                            value={queryFilter?.finalDate}
                                            onChange={event => setQueryFilter(prevState => ({...prevState, finalDate: event.target.value}))}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                            <Col className="mt-2">
                                <Button className="btn btn-primary" type="submit" title="Pesquisar" style={{ width: '130px' }} onClick={handleFilters}>Filtrar</Button>
                            </Col>
                            {queryFilter?.showClearFiltersButton && 
                                <Col className="col-sm-4 mt-2">
                                    <Button className="btn btn-danger" type="submit" title="Pesquisar" style={{ width: '200px' }} onClick={handleClearFilterButton}>Limpar Filtros</Button>
                                </Col>
                            }
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <Table hover striped responsive>
                                         <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Nome da categoria</th>
                                                <th>Criado em</th>
                                                <th>Atualizado em</th>
                                                <th>Editar</th>
                                                <th>Excluir</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {categories?.map((category) => (
                                                <tr key={category.id}>
                                                    <td>{category.id}</td>
                                                    <td>{category.name}</td>
                                                    <td>{datehelper.formatDateWithHours(category.created_at)}</td>
                                                    <td>{datehelper.formatDateWithHours(category.updated_at)}</td>
                                                    <td>
                                                        <Link to={`/admin/blog/categoria/${category.id}/editar`}>
                                                            <MDBBtn className="btn btn-blue btn-icon lanamaria-icons icon-pencil" title="Visualizar"></MDBBtn>
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <MDBBtn className="btn btn-icon btn-danger lanamaria-icons icon-trash-simple" title="Excluir postagem" onClick={() => setDeleteCategory({ ...deleteCategory, categoryToDelete: category, showModal: true })}></MDBBtn>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <select 
                                        onChange={event => setPerPage(parseInt(event.target.value))}
                                        className="custom-select custom-select-sm form-control form-control-sm" 
                                        style={{ width: '200px', position: 'relative', float: 'left', marginRight: '10px' }}>
                                        {pagesAvailable.map((p, index) =>
                                            <option key={index}>{p} resultados de {totalPosts}</option>
                                        )}
                                    </select>
                                    <ul data-test="pagination" className="pagination m-0" style={{ paddingTop: '3px' }}>
                                        <li data-test="page-item" className={"page-item " + (page === 1 ? 'disabled' : '')}>
                                            <button data-test="page-link" aria-label="Anterior" className="page-link page-link" onClick={() => setPage(Math.max(page - 1, 1))}>
                                                <span><i className="fas fa-chevron-left"></i></span>
                                            </button>
                                        </li>
                                        <span style={{ marginLeft: '5px', marginRight: '5px' }}>
                                            {page}
                                        </span>
                                        <li data-test="page-item" className={"page-item " + (page === lastPage ? 'disabled' : '')}>
                                            <button data-test="page-link" aria-label="Próximo" className="page-link page-link" onClick={() => setPage(Math.min(page + 1, lastPage))}>
                                                <span><i className="fas fa-chevron-right"></i></span>
                                            </button>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ManageCategories;