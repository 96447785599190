import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import BlogService from "services/BlogService";
import { Card, CardHeader,CardBody, Row, Col, Label, FormGroup, Input, Button } from "reactstrap";

function EditCategory(props) {
    const categoryId = props.match.params.id;
    
    const [category, setCategory] = useState({});
    const [categoryName, setCategoryName] = useState("");

    useEffect(() => {
        getCategory();
    }, []);
    
    const getCategory = () => {
        toast.promise(BlogService.getCategoryById(categoryId), {
            loading: "Buscando a categoria",
            success: "Pronto!",
            error: "Falha na comunicação como servidor"
        }).then((response) => {
            setCategory(response);
        });
    }

    const handleEditCategory = () => {
        if(!categoryName) {
            toast.error('Digite uma categoria');
            return;
        }

        const requestData = {
            id: categoryId, 
            category_name: capitalizeFirstLetter(categoryName)
        };
       
        toast.promise(BlogService.updateCategoryById(requestData), {
            loading: "Criando nova categoria",
            success: "Categoria criada",
            error: "Falha na comunicação com o servidor"
        }).then(() => {
            setTimeout(() => {
                props.history.push('/admin/blog/categoria/gerenciar');
            }, 1500);
        });
    }

    const capitalizeFirstLetter = (value) => {
        return value[0].toUpperCase() + value.slice(1);
    } 

    return (
        <div className="content">
            <Row>
                <Col>
                  <h3 className="title">Editar categoria</h3>
                    <Card>
                        <CardHeader>
                            <h5>Editar categoria nº {category?.id}</h5>
                        </CardHeader>
                        <CardBody>
                            <FormGroup>
                                <Label className="text-dark">
                                    Nome da categoria
                                </Label>
                                <Input type="text" defaultValue={category?.name} onChange={event => setCategoryName(event.target.value)}/>
                            </FormGroup>
                            <Row>
                                <Col className="d-flex justify-content-center mt-3">
                                    <Button onClick={handleEditCategory}>Editar categoria</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default EditCategory;