import api from "./api";

class OrderService {
   async getOrders(page, perPage, queryFilter) {
        const { query, orderBy, status, initialDate, finalDate } = queryFilter;
            
        const response = await api.get(`/admin/orders?query=${query}&page=${page}&per_page=${perPage}&order_by=${orderBy}&status=${status}&initial_date=${initialDate}&final_date=${finalDate}`);
        return response.data;
    }

    async getOrderById(orderId) {
        const response = await api.get(`/admin/orders/details?order_id=${orderId}`);
        return response.data;
    }

    async getOrderStatuses() {
        const response = await api.get("/admin/orders/available-statuses");
        return response.data;
    }

    async updateOrderStatus(orderId, orderStatusId) {
        await api.patch("/admin/orders/update-status", {
            orderId, 
            orderStatusId
        });
    }
}

export default new OrderService();