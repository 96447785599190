import { useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row, FormGroup, Label, Button, Input, CardHeader } from 'reactstrap';

import UserService from 'services/UserService';

const CreateUser = (props) => {
    const [userData, setUserData] = useState({
        user_firstname: "",
        user_lastname: "",
        email: "",
        password: "",
        confirm_password: ""
    });


    const handleUserDataChange = (key, value) => {
        setUserData(prevState => {
            return {
                ...prevState,
                [key]: value
            }
        });
    }    

    const handleCreateUser = () => {
        toast.promise(UserService.createUser(userData), {
            loading: "Criando usuário.",
            success: "Usuário criado.",
            error: "Não foi possível criar o usuário."
        }).then(() => {
            setTimeout(() => {
                props.history.push("/admin/usuarios");
            }, 2000);
        });
    }

    return(
        <div className="content">
            <Row>
                <Col>
                    <h3 className="title">Cadastrar Usuário</h3>
                    <Card>
                        <CardHeader>
                            <h6> * Criar usuário com acesso ao painel de administração </h6>
                            <hr />
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>
                                            <h6>Nome</h6>
                                        </Label>
                                        <Input type="text" name="firstname" placeholder="Insira o nome do cliente" onChange={event => handleUserDataChange("user_firstname", event.target.value)}   required />
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>
                                            <h6>Sobrenome</h6>
                                        </Label>
                                        <Input type="text" name="lastname" placeholder="Insira o sobrenome do cliente" required onChange={event => handleUserDataChange("user_lastname", event.target.value)}/>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>
                                            <h6>Email</h6>
                                        </Label>
                                        <Input type="email" name="email" placeholder="Insira o email do cliente" required onChange={event => handleUserDataChange("email", event.target.value)}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <FormGroup>
                                        <Label>
                                            <h6>Senha</h6>
                                        </Label>
                                        <Input type="password" name="password" placeholder="Insira a senha do cliente" required onChange={event => handleUserDataChange("password", event.target.value)} />
                                    </FormGroup>
                                </Col>
                                <Col md="6">
                                    <FormGroup>
                                        <Label>
                                            <h6>Confirmar senha</h6>
                                        </Label>
                                        <Input type="password" name="confirm-password" placeholder="Confirme a senha do cliente" required onChange={event => handleUserDataChange("confirm_password", event.target.value)} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="d-flex justify-content-center">
                                <Col md="3">
                                    <Link to="/admin/usuarios">
                                        <Button className="btn btn-danger">
                                            Voltar
                                        </Button>
                                    </Link>
                                </Col>
                                <Col md="3">
                                    <Button onClick={handleCreateUser}>
                                        Salvar
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default CreateUser;