import api from "./api";

class AuthService {

    constructor(){
        this.user = null;
    }

    async auth(email, password) {
        const requestBody = {
            email,
            password
        }

        const response = await api.post("/admin/auth/login", requestBody);
        console.log('service', response);
        return response;
    }

    async getSession () {
        return api.get('/admin/auth/session');
    }

    getUser = () => {
        return this.user;
    }

    setUser = (user) => {
        this.user = user;
    }
}

export default new AuthService();