import React from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import { PropTypes } from "prop-types";
import PerfectScrollbar from "perfect-scrollbar";
import { Nav } from "reactstrap";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";

var ps;

const Sidebar = (props) => {
    const sidebarRef = React.useRef(null);
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(sidebarRef.current, {
                suppressScrollX: true,
                suppressScrollY: false,
            });
        }
        // Specify how to clean up after this effect:
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
            }
        };
    });

    const history = useHistory();
    const goLogin = () => history.push('dashboard');

    const { routes, rtlActive, logo } = props;
    let logoImg = null;
    let logoText = null;
    if (logo !== undefined) {
        if (logo.outterLink !== undefined) {
            logoImg = (
                <button
                    /* href={logo.outterLink} */
                    /* className="simple-text logo-mini" */
                    className="simple-text nobtn"
                    /* target="_blank" */
                    /* onClick={props.toggleSidebar} */
                    onClick={goLogin}
                >
                    {/* <div className="logo-img">
            <img src={logo.imgSrc} alt="react-logo" />
          </div> */}
                </button>
            );
            logoText = (
                <button
                    /* href={logo.outterLink} */
                    /* className="simple-text logo-normal" */
                    className="simple-text nobtn"
                    /* target="_blank" */
                    /* onClick={props.toggleSidebar} */
                    onClick={goLogin}
                >
                    {logo.text}
                </button>
            );
        }
    }
    return (
        <BackgroundColorContext.Consumer>
            {({ color }) => (
                <div className="sidebar">
                    <div className="sidebar-wrapper" ref={sidebarRef}>
                        {logoImg !== null || logoText !== null ? (
                            <div className="logo">
                                {logoImg}
                                {logoText}
                            </div>
                        ) : null}
                        <Nav>
                            {
                                routes.map((prop, key) => {
                                    if (prop.redirect) {
                                        return null;
                                    } else if (prop.visible === true && prop.subcategory === true) {
                                        return (
                                            <li /* className={activeRoute(prop.path) + (prop.pro ? " active-pro" : "")} */ key={key}>
                                                <a data-toggle="collapse" href={`#${prop.pathname}`}>
                                                    <i className={prop.icon}></i>
                                                    <p>{prop.name}<b className="caret"></b></p>
                                                </a>
                                                <div className="collapse" id={prop.pathname}>
                                                    <Nav>
                                                        {prop.subcategories.map(function (subcategories, subkey) {
                                                            if (subcategories.visible === true) {
                                                                return (
                                                                    <li /* className={activeRoute(prop.path) + (prop.pro ? " active-pro" : "")} */ key={subkey}>
                                                                        <Link to={`${prop.layout}${prop.path}${subcategories.path}`}>
                                                                            <span className="sidebar-mini-icon">{subcategories.pathname}</span>
                                                                            <p>{subcategories.name}</p>
                                                                        </Link>
                                                                    </li>
                                                                );
                                                            } else {
                                                                return null;
                                                            }
                                                        })}
                                                    </Nav>
                                                </div>
                                            </li>
                                        );
                                    } else if (prop.visible === true) {
                                        return (
                                            <li /* className={activeRoute(prop.path) + (prop.pro ? " active-pro" : "")} */ key={key}>
                                                <NavLink to={prop.layout + prop.path} className="nav-link" activeClassName="active" onClick={props.toggleSidebar}>
                                                    <i className={prop.icon} />
                                                    <p>{rtlActive ? prop.rtlName : prop.name}</p>
                                                </NavLink>
                                            </li>
                                        );
                                    } else {
                                        return null;
                                    }
                                })
                            }
                        </Nav>
                    </div>
                </div>
            )}
        </BackgroundColorContext.Consumer>
    );
}

Sidebar.defaultProps = {
    visible: true,
    rtlActive: false,
    routes: [{}],
};

Sidebar.propTypes = {
    // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
    // insde the links of this component
    rtlActive: PropTypes.bool,
    routes: PropTypes.arrayOf(PropTypes.object),
    logo: PropTypes.shape({
        // innerLink is for links that will direct the user within the app
        // it will be rendered as <Link to="...">...</Link> tag
        innerLink: PropTypes.string,
        // outterLink is for links that will direct the user outside the app
        // it will be rendered as simple <a href="...">...</a> tag
        outterLink: PropTypes.string,
        // the text of the logo
        text: PropTypes.node,
        // the image src of the logo
        imgSrc: PropTypes.string,
    }),
};

export default Sidebar;
