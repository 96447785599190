import api from "./api";

class BlogService {
    /**
     * Create blog post
     * @method POST
     * @function savePost
     * @return {Promise}
    */
    async storePost(postData) {
        return await api.post('/store/blog/post/store', postData);
    }

    /**
     * Retrieve post categories
     * @method get
     * @function getPostCategories
     * @return {Promise}
    */
    async getPostCategories() {
        const response = await api.get('/store/blog/category/index');
        return response.data;
    }

    /**
     * Retrieve all blog posts by status using filters
     * @method get
     * @param statusId
     * @param perPage 
     * @param query 
     * @returns {Promise}
     */
    async getPostsByStatus(statusId, page, perPage, query) {
        const response = await api.get(
            `/store/blog/post/index/` + 
            `?page=${page}` + 
            `&per_page=${perPage}` + 
            `&query=${query.filter}` + 
            `&status=${statusId}` + 
            `&category=${query.category}` + 
            `&initial_date=${query.initialDate}` +
            `&final_date=${query.finalDate}`);
        return response.data;
    }

    /**
     * Retrieve all blog posts by filters
     * @method get
     * @param page 
     * @param perPage 
     * @param query 
     * @returns {Promise}
     */
    async getAllPosts(page, perPage, query) {
        const response = await api.get(
            `/store/blog/post/index/` + 
            `?page=${page}` + 
            `&per_page=${perPage}` + 
            `&query=${query.filter}` + 
            `&status=${query.status}` + 
            `&category=${query.category}` + 
            `&initial_date=${query.initialDate}` +
            `&final_date=${query.finalDate}`);
        return response.data;
    }

    /**
     * Get post data by id
     * @method get
     * @param postId 
     * @returns {Promise}
     */
    async getPostData(postId) {
        const response = await api.get(`store/blog/post/show?id=${postId}`);
        return response.data;
    }

    /**
    * Update post by id
    * @method post
    * @param postData
    * @param postId
    * @returns {Promise}
    */
    async updatePostById(postData, postId) {
        const response = await api.post(`store/blog/post/update?id=${postId}`, postData);
        return response.data;
    }
    
    /**
    * Delete blog post by id
    * @param postId
    * @returns {Promise}
    */
    async deletePostById(postId) {
        const response = await api.delete(`store/blog/post/delete?id=${postId}`);
        return response.data;
    }

    /**
     * Get all blog post categories
     * @returns {Promise}
     */
    async getBlogCategories() {
        const response = await api.get('store/blog/category/index');
        return response.data;
    }

    /**
     * Get all blog post status
     * @returns {Promise}
     */
    async getBlogStatuses() {
        const response = await api.get('store/blog/status/index');
        return response.data;
    }
    /**
     * Update the blog post as approved
     * @param postId
     * @returns {Promise}
     */
    async approvePostById(postId) {
        const response = await api.get(`store/blog/post/approve?post_id=${postId}`);
        return response;
    }

    /**
    * Update the blog post as repproved
    * @param postId
    * @returns {Promise}
    */
    async disapprovePostById(postId) {
        const response = await api.get(`store/blog/post/disapprove?post_id=${postId}`);
        return response;
    }

    /**
    * Get all categories with pagination an filters
    * @param page
    * @param perPage
    * @param query
    * @returns {Promise}
    */
    async getFilteredCategories(page, perPage, query) {
        const response = await api.get("store/blog/category/search"
        + `?page=${page}`
        + `&per_page=${perPage}`
        + `&query=${query.filter}`
        + `&initial_date=${query.initialDate}`
        + `&final_date=${query.finalDate}`);
        
        return response.data;
    }

    /**
    * Store a new category in database
    * @param categoryName
    * @returns {Promise}
    */
    async createCategory(category) {
        const response = await api.post(`store/blog/category/create`, category);
        return response;
    }

    /**
     * Retrieve an specific category by an given id
     * @param categoryId 
     * @return promise
     */
    async getCategoryById(categoryId) {
        const response = await api.get(`store/blog/category/show?id=${categoryId}`);
        return response.data;
    }

    /**
     * Delete an specific category by an given id
     * @param categoryId 
     * @return promise
     */
    async deleteCategoryById(categoryId) {
        const response = await api.delete(`store/blog/category/delete?id=${categoryId}`);
        return response.data;
    }

    /**
    * Edit an existent category by an given id
    * @param categoryData
    */
    async updateCategoryById(categoryData) {
        const response = await api.post("store/blog/category/update", categoryData);
        return response.data;
    }

}

export default new BlogService();