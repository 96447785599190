/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Nav, NavItem, NavLink } from "reactstrap";

function Footer() {
    return (
        <footer className="footer">
            <Container fluid>
                <Nav>
                    {/*
                    <NavItem>
                        <NavLink href="https://casadasessencias.com.br/">
                            LáNaMaria
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="https://casadasessencias.com.br/info/quem-somos">
                            Quem somos
                        </NavLink>
                    </NavItem>
                    */}
                </Nav>
                <div className="copyright">
                    © {new Date().getFullYear()}, TODOS OS DIREITOS RESERVADOS.
                </div>
            </Container>
        </footer>
    );
}

export default Footer;
