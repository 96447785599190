import React, { Component } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import routes from "routes.js";
import PerfectScrollbar from "perfect-scrollbar";
import Footer from "components/Footer/Footer.js";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import Loader from '../../loader.gif';
import { Col, Row } from "reactstrap";
import "@fortawesome/fontawesome-free/css/all.min.css";

function Admin(props) {
    var ps;

    const location = useLocation();
    const mainPanelRef = React.useRef(null);
    const [sidebarOpened, setsidebarOpened] = React.useState(
        document.documentElement.className.indexOf("nav-open") !== -1
    );
    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            document.documentElement.className += " perfect-scrollbar-on";
            document.documentElement.classList.remove("perfect-scrollbar-off");
            ps = new PerfectScrollbar(mainPanelRef.current, {
                suppressScrollX: true,
            });
            let tables = document.querySelectorAll(".table-responsive");
            for (let i = 0; i < tables.length; i++) {
                ps = new PerfectScrollbar(tables[i]);
            }
        }
        // Specify how to clean up after this effect:
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
                document.documentElement.classList.add("perfect-scrollbar-off");
                document.documentElement.classList.remove("perfect-scrollbar-on");
            }
        };
    });

    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            let tables = document.querySelectorAll(".table-responsive");
            for (let i = 0; i < tables.length; i++) {
                ps = new PerfectScrollbar(tables[i]);
            }
        }
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        if (mainPanelRef.current) {
            mainPanelRef.current.scrollTop = 0;
        }
    }, [location]);
    // this function opens and closes the sidebar on small devices
    const toggleSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        setsidebarOpened(!sidebarOpened);
    };

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {

            let validRoute = [];

            let auth_layout = prop.layout === "/auth";
            let with_subcategory = prop.subcategory === true;
            let with_subsegment = prop.subcategories.find((subcategories) => subcategories.subsegment === true);

            if (auth_layout && with_subcategory && with_subsegment) {
                validRoute.push(prop.subcategories.map((item) => {
                    return (
                        item.subsegments.map((sub, subindex) =>
                            <Route path={prop.layout + prop.path + item.path + sub.path} exact={true} component={sub.component} key={subindex} />
                        )
                    );
                }));
            }

            if (auth_layout && with_subcategory) {
                validRoute.push(prop.subcategories.map((item, index) => {
                    return (
                        <Route path={prop.layout + prop.path + item.path} exact={true} component={item.component} key={index} />
                    );
                }));
            }

            if (auth_layout) {
                validRoute.push(
                    <Route path={prop.layout + prop.path} component={prop.component} exact={true} key={key} />
                );
            }

            return validRoute;
        });
    };

    return (
        <React.Fragment>
            <div className="wrapper">
                <div className="main-panel" ref={mainPanelRef}>
                    <div className="full-page login-page">
                        <Switch>
                            {getRoutes(routes)}
                            <Redirect from="*" to="/auth/login" />
                        </Switch>
                        <Footer />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

class AdminClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }

    componentDidMount = () => {
        // loader
        this.timerHandle = setTimeout(() => this.setState({ loading: false }), 500);
    }

    componentWillUnmount() {
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
            this.timerHandle = 0;
        }
    }

    render() {
        return (this.state.loading ?
            <Row>
                <Col style={{ marginTop: '100px' }}>
                    <img src={Loader} className={`search-loading ${this.state.loading ? 'show' : 'hide'}`} alt="loader" />
                </Col>
            </Row>
            :
            <Admin />
        )
    }
}

export default AdminClass;

/* export default Admin; */