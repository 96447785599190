import toast from "react-hot-toast";
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ModalWindow from 'components/ModalWindow/ModalWindow';
import { Button, Card, CardBody, CardHeader, Col, Row, Table} from 'reactstrap';

import CouponService from 'services/CouponService';
import datehelper from "helpers/datehelper";

const pagesAvailable = [10, 20, 25, 50, 100];

function Index() {
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [totalCoupons, setTotalCoupons] = useState(0)
    const [postsPerPage, setPostsPerPage] = useState(10);
    const [coupons, setCoupons] = useState(null);
    const [deleteCoupon, setDeleteCoupon] = useState(
        {
            userToDelete: null,
            showModal: false
        }
    );

    useEffect(() => {
        getAllCoupons();
    }, [page, postsPerPage]);

    const getAllCoupons = () => {
        toast.promise(CouponService.getAllCoupons(page, postsPerPage), {
            loading: 'Buscando...',
            success: 'Pronto!'
        }).then(response => {
            const { data, total, last_page } = response.data;

            setCoupons(data);
            setTotalCoupons(total);
            setLastPage(last_page);
        });
    }

    const handleDeleteCoupon = () => {
        if (!deleteCoupon.showModal) {
            return null;
        }

        return (
            <ModalWindow show={deleteCoupon.showModal} printable={false} width="300px">
                <Card>
                    <CardHeader>
                        <Row>
                            <Col style={{ textAlign: 'end', marginTop: '-5px' }}>
                                <Button className="btn btn-danger btn-icon lanamaria-icons icon-simple-remove" title="Fechar" onClick={() => setDeleteCoupon({ ...deleteCoupon, userToDelete: null, showModal: false })} />
                            </Col>
                            <Col md="12">
                                <h6 style={{ fontSize: '26px', textTransform: 'initial', marginTop: '4px' }}>Confirmar exclusão</h6>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <p>Você confirma a exclusão do cupom nº {deleteCoupon?.userToDelete?.id}</p>
                        <small style={{ color: 'black' }}>
                            <b>*Obs.: ao confirmar, o cupom será deletado permanentemente</b>
                        </small>
                        <hr />
                        <Button className="btn btn-blue" type="submit" title="Excluir logo" style={{ width: '100%' }} onClick={() => {
                            setDeleteCoupon({ ...deleteCoupon, userToDelete: null, showModal: false });
                            deleteCouponById(deleteCoupon?.userToDelete?.id);
                        }}>Sim</Button>
                    </CardBody>
                </Card>
            </ModalWindow>
        )
    }

    const deleteCouponById = (id) => {
        toast.promise(CouponService.deleteCouponById(id), {
            loading: 'Deletando cupom...',
            success: 'Cupom deletado com sucesso!',
            error: 'Não foi possível deletar o cupom'
        }).then((data) => {
            setTimeout(() => {
                getAllCoupons();
            }, 1000);
        });
    }

    const isCouponAvailable = (coupon) => {
        const todayDate = new Date().getTime();
        const couponExpireDate = new Date(coupon?.expire_date)?.getTime();
        const isCouponDateExpired = todayDate < couponExpireDate;

        const isCouponQuantityAvailable = coupon?.quantity > 0;
        
        if (coupon?.is_active && isCouponDateExpired && isCouponQuantityAvailable) {
            return true;
        } else {
            return false;
        }
    } 
    return (
        <div className="content">
            <Row>
                <Col>
                    <h3 className="title">Cupons</h3>
                    <div className="text-left">
                        {handleDeleteCoupon()}
                        <Link to={'/admin/descontos/cupons/criar'} className="btn btn-blue mb-20">Criar novo cupom</Link>
                    </div>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <Table hover striped responsive>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '5%' }}># ID</th>
                                                <th style={{ width: '15%' }}>Nome do cupom</th>
                                                <th style={{ width: '10%', textAlign: 'center' }}>Valor do desconto</th>
                                                <th style={{ width: '10%', textAlign: 'center' }}>Tipo de desconto</th>
                                                <th style={{ width: '10%', textAlign: 'center' }}>Quantidade disponível</th>
                                                <th style={{ width: '5%', textAlign: 'center' }}>Ativo</th>
                                                {/* <th style={{ width: '20%', textAlign: 'center' }}>Disponível em todos os departamentos</th> */}
                                                <th style={{ width: '15%', textAlign: 'center' }}>Data de início</th>
                                                <th style={{ width: '15%', textAlign: 'center' }}>Data de término</th>
                                                <th style={{ width: '10%', textAlign: 'center' }}>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {coupons?.map((coupon, index) => (
                                                <tr key={index}>
                                                    <td>{coupon.id}</td>
                                                    <td>
                                                        <p>{coupon?.coupon_name}</p>
                                                    </td>
                                                    <td className="text-center">
                                                        <p>
                                                            {coupon?.discount_type === "value" && "R$ "}
                                                            {coupon?.discount_value}
                                                            {coupon?.discount_type === "percentage" && "%"}
                                                        </p>

                                                    </td>                                                    
                                                    <td className="text-center">
                                                        <div className={coupon.discount_type === "value" ? 'badge badge-success badge-pill' : 'badge badge-info badge-pill'}>
                                                            <span>{coupon.discount_type  === "value" ? 'R$' : '%'}</span>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <p>{coupon?.quantity}</p>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className={isCouponAvailable(coupon) ? 'badge badge-success badge-pill' : 'badge badge-danger badge-pill'}>
                                                            <span>{isCouponAvailable(coupon) ? 'Ativo' : 'Inativo'}</span>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <p>{datehelper.toDDMMYYYY(coupon?.start_date)}</p>
                                                    </td>
                                                    <td className="text-center">
                                                        <p>{datehelper.toDDMMYYYY(coupon?.expire_date)}</p>
                                                    </td>
                                                    <td className="text-center">
                                                        <Link to={`cupons/${coupon.id}/editar`}>
                                                            <Button className="btn btn-icon btn-secondary lanamaria-icons icon-pencil mr-2 mt-2" title="Editar Logo" />
                                                        </Link>
                                                        <Button
                                                            title="Excluir logo Logo"
                                                            className="btn btn-icon btn-danger lanamaria-icons icon-trash-simple mr-2 mt-2"
                                                            onClick={() => setDeleteCoupon({ ...deleteCoupon, userToDelete: coupon, showModal: true })} 
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <select 
                                        onChange={event => setPostsPerPage(parseInt(event.target.value))}
                                        className="custom-select custom-select-sm form-control form-control-sm" 
                                        style={{ width: '200px', position: 'relative', float: 'left', marginRight: '10px' }}>
                                        {pagesAvailable.map((p, index) =>
                                            <option key={index}>{p} resultados de {totalCoupons}</option>
                                        )}
                                    </select>
                                    <ul data-test="pagination" className="pagination m-0" style={{ paddingTop: '3px' }}>
                                        <li data-test="page-item" className={"page-item " + (page === 1 ? 'disabled' : '')}>
                                            <button data-test="page-link" aria-label="Anterior" className="page-link page-link" onClick={() => setPage(Math.max(page - 1, 1))}>
                                                <span><i className="fas fa-chevron-left"></i></span>
                                            </button>
                                        </li>
                                        <span style={{ marginLeft: '5px', marginRight: '5px' }}>
                                            {page}
                                        </span>
                                        <li data-test="page-item" className={"page-item " + (page === lastPage ? 'disabled' : '')}>
                                            <button data-test="page-link" aria-label="Próximo" className="page-link page-link" onClick={() => setPage(Math.min(page + 1, lastPage))}>
                                                <span><i className="fas fa-chevron-right"></i></span>
                                            </button>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Index;