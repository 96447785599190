import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table, FormGroup, Label, Input, Button } from 'reactstrap';

import OrderService from 'services/OrderService';
import NoImage from '../../../assets/img/no-product-image.jpeg'
import DebitCard from "./paymentMethods/DebitCard";
import CreditCard from "./paymentMethods/CreditCard";
import PixPayment from "./paymentMethods/PixPayment";

function ManageStatus(props) {
    const orderId = props.match.params.id;

    const [order, setOrder] = useState({});
    const [customerData, setCustomerData] = useState({});
    const [orderPayment, setOrderPayment] = useState({});
    const [orderedProducts, setOrderedProducts] = useState([]);   
    const [orderFreight, setOrderFreight] = useState([]) 

    useEffect(() => {
        toast.promise(OrderService.getOrderById(orderId), {
            loading: 'Carregando detalhes do pedido...',
            success: 'Pedido carregado com sucesso!',
            error: 'Não foi possível carregar o pedido.'
        }).then(order => {
            const customerData = order?.customer;
            const productsData = order?.order_product;
            const orderFreight = order?.freight;
            const orderPayment = order?.order_payment;

            setOrder(order);
            setOrderFreight(orderFreight);
            setCustomerData(customerData);
            setOrderPayment(orderPayment);
            setOrderedProducts(productsData);
        });
    }, []);

    const getImageUrl = imageData => {
        if(imageData?.[0]) {
            const imageUrl = process.env.REACT_APP_IMAGE_BASE_URL;
            const imagePath = imageData?.[0]?.path;
            const imageName = imageData?.[0]?.name;
    
            const imageLink = imageUrl + imagePath + imageName;
            return imageLink
        }

        return NoImage;
    }
    
    const handleUpdateOrderStatus = statusId => {
        toast.promise(OrderService.updateOrderStatus(orderId, statusId), {
            loading: "Atualizando status do pedido",
            success: "Pedido atualizado com sucesso",
            error: "Não foi possível atualizar o pedido"
        });
    }

    function calculateOrderTotal(totalPrice, freight) {
        if(!totalPrice) return; 

        const orderTotal = parseFloat(totalPrice);
        const freightValue = parseFloat(freight);

        return (orderTotal + freightValue)?.toFixed(2)?.replace('.', ',');
    }

    function calculateOrderTotalWithDiscounts(totalWithDiscounts, freight) {
        if(!totalWithDiscounts) return; 

        const orderTotal = parseFloat(totalWithDiscounts);
        const freightValue = parseFloat(freight);

        return (orderTotal + freightValue)?.toFixed(2)?.replace('.', ',');
    }

    const handlePaymentMethod = () => {
        const paymentMethods = {
            "credit_card": <CreditCard orderPayment={orderPayment} />,
            "debit_card": <DebitCard orderPayment={orderPayment} />,
            "pix": <PixPayment orderPayment={orderPayment} />
        }
        
        const paymentMethodExists = paymentMethods?.hasOwnProperty(orderPayment?.type);

        if(paymentMethodExists) {
            return paymentMethods[orderPayment?.type];
        }
    }

    return (
        <div className="content">
            <Row>
                <Col>
                    <h3 className="title">Detalhes de pedidos</h3>
                    <Card>
                        <CardHeader>
                            <p className="category">* Informações do pedido <b>#{orderId}</b></p>
                            <hr />
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <Link to="/admin/pedidos">
                                        <Button className="mb-5">Voltar a listagem de pedidos</Button>
                                    </Link>
                                </Col>
                            </Row>
                            <h4 className="title">Dados do cliente</h4>
                            <Row className="mb-4 mt-4">
                                <Col md="1">
                                    <Label>
                                        <h6>ID</h6>
                                    </Label>
                                    <Input type="number" value={customerData?.id} readOnly disabled />
                                </Col>
                                <Col md="3">
                                    <Label>
                                        <h6>Nome</h6>
                                    </Label>
                                    <Input type="text" value={customerData?.user_firstname && `${customerData?.user_firstname} ${customerData?.user_lastname}`} readOnly disabled />
                                </Col>
                                <Col md="3">
                                    <Label>
                                        <h6>Email</h6>
                                    </Label>
                                    <Input type="text" value={customerData?.email} readOnly disabled />
                                </Col>
                                {customerData?.cpf 
                                    ?
                                        <Col md="3">
                                            <Label>
                                                <h6>CPF</h6>
                                            </Label>
                                            <Input type="text" value={customerData?.cpf} readOnly disabled />
                                        </Col>
                                    : 
                                        <Col md="3">
                                            <Label>
                                                <h6>CNPJ</h6>
                                            </Label>
                                            <Input type="text" value={customerData?.cnpj} readOnly disabled />
                                        </Col>
                                }
                                <Col md="2">
                                    <Label>
                                        <h6>Telefone</h6>
                                    </Label>
                                    <Input type="text" value={customerData?.telephone} readOnly disabled />
                                </Col>
                            </Row>
                            <hr />
                            {handlePaymentMethod()}
                            <Row className="mt-3">
                                <Col md="4">
                                    <h4 className="title">Entrega</h4>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md="4">
                                    <h5><b>Rua: </b>{orderFreight?.address?.street}</h5>
                                </Col>
                                <Col md="2">
                                    <h5><b>Número: </b>{orderFreight?.address?.number}</h5>
                                </Col>
                                <Col md="2">
                                    <h5><b>Estado: </b>{orderFreight?.address?.state}</h5>
                                </Col>
                                <Col md="2" className="pr-5">
                                    <h5><b>CEP: </b>{orderFreight?.address?.zipcode}</h5>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md="3">
                                    <h5><b>Bairro: </b>{orderFreight?.address?.district}</h5>
                                </Col>
                                <Col md="3">
                                    <h5><b>Cidade: </b>{orderFreight?.address?.city}</h5>
                                </Col>
                            </Row>
                            <Row className="mt-3 mb-4">
                                <Col md="6">
                                    {orderFreight?.address?.reference &&
                                        <h5><b>Referencia: </b>{orderFreight?.address?.reference}</h5>
                                    }
                                </Col>
                                <Col md="6">
                                    {orderFreight?.address?.complement &&
                                        <h5><b>Complemento: </b>{orderFreight?.address?.complement}</h5>
                                    }
                                </Col>
                            </Row>

                            <Row className="my-3">
                                <Col>
                                    <h4 className="title">Embalagem sugerida</h4>
                                </Col>
                            </Row>
                            {orderFreight?.packages?.map(orderPackage => (
                                <Row className="mb-4">
                                    <Col md="12">
                                        <h5><b>Nome: </b>{orderPackage?.package_name} mm</h5>
                                    </Col>
                                    <Col md="2">
                                        <h5><b>Altura: </b>{orderPackage?.package_height} mm</h5>
                                    </Col>
                                    <Col md="2">
                                        <h5><b>Largura: </b>{orderPackage?.package_width} mm</h5>
                                    </Col>
                                    <Col md="3">
                                        <h5><b>Comprimento: </b>{orderPackage?.package_length} mm</h5>
                                    </Col>
                                    <Col md="2">
                                        <h5><b>Peso: </b>{orderPackage?.package_weight} g</h5>
                                    </Col>
                                </Row>
                            ))}
                            <Row className="mt-3">
                                <Col md="2">
                                    <h5><b>Preço: </b> R$ {orderFreight?.freight_value?.toString()?.replace('.', ',')}</h5>
                                </Col>
                                <Col md="2">
                                    <h5><b>Serviço: </b>{orderFreight?.freight_service_name}</h5>
                                </Col>
                                {orderFreight?.freight_service_code !== "loja" &&
                                    <Col md="2">
                                        <h5><b>Prazo: </b> {orderFreight?.delivery_date} dia{orderFreight?.delivery_date > 1 && "s"}</h5>
                                    </Col>
                                }
                                <Col md="3">
                                    <h5><b>Quantidade de caixas: </b> {orderFreight?.packages?.length}</h5>
                                </Col>
                            </Row>

                            <hr />
                            <Row className='mt-4'>
                                <Col>
                                    <h4 className="title">Produtos</h4>
                                    <Row className="d-flex justify-content-center my-5">
                                        {orderedProducts?.map((product, index) => (
                                            <Col key={index} md="3">
                                                <div className="product-image-view">
                                                    <span>
                                                        <p>Produto {index + 1}</p>
                                                    </span>
                                                    <img src={getImageUrl(product?.product_image)} />
                                                </div>
                                                <span>
                                                    <p className="text-center">{product?.product_data?.Nome_Produto}</p>
                                                </span>
                                                <span>
                                                    <h4 className="title text-center">
                                                        {`R$ ${product?.product_data?.PrecoVendaECommercePraticado_ProEmpEmbPre?.toFixed(2).replace('.',',')}`}
                                                    </h4>
                                                    <h5 className="text-center"><b>Quantidade</b> {product?.quantity}</h5>
                                                </span>
                                            </Col>
                                        ))}
                                    </Row>
                                    <Row className="d-flex justify-content-center">
                                        <Col md="12">
                                            <h4 className="title text-center">Total do envio: R$ {orderFreight?.freight_value?.toString()?.replace('.', ',')}</h4>                                            
                                        </Col>
                                    </Row>
                                    <Row className="d-flex justify-content-center">
                                        <Col md="12">
                                            <h4 className="title text-center">Total da compra: R$ {calculateOrderTotal(order?.total, orderFreight?.freight_value)}</h4>                                            
                                        </Col>
                                    </Row>
                                    <Row className="d-flex justify-content-center">
                                        <Col md="12">
                                            {order?.total_with_discounts > 0 && 
                                                <div className="d-flex flex-column align justify-content-center">
                                                    <h4 className="title text-center">Total com descontos: R$ {calculateOrderTotalWithDiscounts(order?.total_with_discounts, orderFreight?.freight_value)}</h4>
                                                    <h5 className="text-center">
                                                        <b>Cupom utilizado:</b> {order?.coupon?.coupon_name} 
                                                        <b className="ml-3 mr-2">Valor:</b>
                                                            {order?.coupon?.discount_type === "value" && "R$"} {order?.coupon?.discount_value} {order?.coupon?.discount_type === "percentage" && "%"}
                                                    </h5>
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="mt-4 mb-5">
                                <Col md="12">
                                   <h4 className="title">Status atual: {order?.order_status?.status_name}</h4>
                                   {order?.order_status?.id !== 7 &&
                                       <Row className="d-flex items-center">
                                            <Col className="d-flex mt-2">
                                                <h4 className="title mt-3 mr-3">Alterar o status para: </h4>
                                                <Button onClick={() => handleUpdateOrderStatus(4)}>
                                                    Em separação
                                                </Button>
                                                <Button className='btn btn-success ml-2' onClick={() => handleUpdateOrderStatus(5)}>
                                                    Enviado
                                                </Button>
                                            </Col>
                                       </Row>
                                   }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ManageStatus;