import api from "./api";

class HomePageService {
    /**
     * Update a highlight department on home page
     * 
     * @param {integer} order
     * @param {Array} products
     * @param {Array} aditionalProducts
     * @return {Promise} 
     */
    saveHighlightProducts(highlightDepartmentId, highlightProducts, aditionalProducts, activeProducts) {
        const requestBody = {
            "aditional_products": aditionalProducts?.every(product => product === undefined) ? null : aditionalProducts,
            "highlight_products": highlightProducts,
            "highlight_department_id": highlightDepartmentId,
            "active_products": activeProducts
        }
        return api.put(`/store/home-page/highlight-products`, requestBody);
    }

    /**
     * Create new highlight department id 
     *
     * @param {integer} highlightDepartmentId 
     * @returns 
     */
    createHighlightDepartment(productDepartment, highlightProducts) {
        const requestBody = {
            "product_section": productDepartment,
            "highlight_products": highlightProducts
        }

        return api.post("/store/home-page/highlight-departments", requestBody);
    }

    updateDepartmentVisibility(isActive, highlightDepartmentId) {
        const requestBody = {
            is_active: isActive,
            highlight_department_id: highlightDepartmentId
        }

        return api.put("/store/home-page/highlight-departments", requestBody)
    }

    /**
    * Get all highlighted products by highlight department id
    *
    * @param {integer} highlightId
    * @return {Promise}
    */
    getHighlightedProductsById(highlightId) {
        return api.get(`/store/home-page/highlight-products?id=${highlightId}`);
    }


    /**
    * Get the highlighted departments
    * @return {Promise} 
    */
    getHighlightedDepartments() {
        return api.get("/store/home-page/highlight-departments");
    }

    /**
    * Get the available highlight departments
    *
    * @return {Promise}
    */
    getAvailableHighlightDepartments() {
        return api.get("/store/home-page/available-highlight-departments");
    }

    /**
     * Create a custom section
     * 
     *  @param {Object} requestData
     *  
     */
    createCustomSection(requestData) {
        return api.post("/store/home-page/custom-section/create", requestData);
    }

    /**
     * Get all custom sections stored in database
     * 
     * @return {Promise} 
     */
    getAllCustomSections() {
        return api.get("/store/home-page/custom-section/index");
    }


    /**
     * Set the custom section visibility 
     *
     * @param {integer} customSectionId 
     * @param {boolean} isActive 
     * @returns {Promise}
     */
    setCustomSectionIsActive(customSectionId, isActive) {
        return api.patch(`/store/home-page/custom-section/is-active`
            + `?custom_section_id=${customSectionId}`
            + `&is_active=${isActive}`
        );
    }
    
    /**
     * Get all custom section data using his id 
     *
     * @param {integer} customSectionId 
     * @returns {Promise}
     */
    getCustomSectionById(customSectionId) {
        return api.get(`/store/home-page/custom-section/show?id=${customSectionId}`);
    }

    /**
     * Update a custom section 
     *
     * @param {Json} requestBody 
     * @returns {Promise}
     */
    updateCustomSection(requestBody) {
        return api.post("/store/home-page/custom-section/update", requestBody);
    }

    /**
     * Delete a custom section using his id 
     *
     * @param {Integer} customSectionId 
     * @returns 
     */
    deleteCustomSection(customSectionId) {
        return api.delete(`/store/home-page/custom-section/delete?id=${customSectionId}`);
    }
}

export default new HomePageService();