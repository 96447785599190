import { useState } from "react";
import toast from "react-hot-toast";
import BlogService from "services/BlogService";
import { Card, CardHeader,CardBody, Row, Col, Label, FormGroup, Input, Button } from "reactstrap";

function CreateCategory(props) {
    const [postCategory, setPostCategory] = useState("");

    const handleSaveCategory = () => {
        if(!postCategory) {
            toast.error('Digite uma categoria');
            return;
        }

        const requestData = {
            category_name: capitalizeFirstLetter(postCategory)
        };

        toast.promise(BlogService.createCategory(requestData), {
            loading: "Criando nova categoria",
            success: "Categoria criada",
            error: "Falha na comunicação com o servidor"
        }).then(() => {
            setTimeout(() => {
                props.history.push('/admin/blog/categoria/gerenciar');
            }, 1500);
        });
    }

    const capitalizeFirstLetter = (value) => {
        return value[0].toUpperCase() + value.slice(1);
    } 

    return (
        <div className="content">
            <Row>
                <Col>
                  <h3 className="title">Criar categoria</h3>
                    <Card>
                        <CardHeader>
                            <h5>Criar nova categoria no blog</h5>
                        </CardHeader>
                        <CardBody>
                            <FormGroup>
                                <Label className="text-dark">
                                    Nome da categoria
                                </Label>
                                <Input type="text" onChange={event => setPostCategory(event.target.value)}/>
                            </FormGroup>
                            <Row>
                                <Col className="d-flex justify-content-center mt-3">
                                    <Button onClick={handleSaveCategory}>Criar categoria</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default CreateCategory;