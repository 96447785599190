import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
// Helpers
import 'polyfill.js';
// Layouts
import AdminLayout from "layouts/Admin/Admin.js";
import AuthLayout from "layouts/Auth/Auth.js";

import Loader from 'loader.gif';

// Styles
import { Toaster } from "react-hot-toast";
// Components
import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import { useContext } from 'react';
import AuthContext from 'contexts/Auth/AuthContext';
import { Col, Row } from 'reactstrap';

function App() {
    const authContext = useContext(AuthContext);

    useEffect(() => {
        userHasSession()
    }, []);

    useEffect(() => {
        // get current path
        let currentPath = window.location.pathname;

        // if current path is / redirect to /admin/dashboard
        currentPath === '/' && (window.location.href = '/admin/dashboard');
    }, []);

    const userHasSession = () => {
        authContext.getUserSession();
    }

    return (
        <ThemeContextWrapper>
            <BackgroundColorWrapper>
                <Toaster
                    position="top-right"
                    reverseOrder={false}
                />
                <BrowserRouter>
                    <Switch>
                        {authContext.isAuthenticated === null &&
                            <div className="content">
                                <div className="container">
                                    <Row>
                                        <Col>
                                            <img src={Loader} alt="loader" />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        } 
                        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                        {authContext.isAuthenticated === true && <Route path="/admin" render={(props) => <AdminLayout {...props} />} />}
                        {authContext.isAuthenticated === false && <Redirect from="*" to="/auth/login" />}
                    </Switch>
                </BrowserRouter>
            </BackgroundColorWrapper>
        </ThemeContextWrapper>
    )
}

export default App;
