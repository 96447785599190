import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Link, useHistory } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "contexts/Auth/AuthContext";
import ImageService from "services/ImageService";

// reactstrap components
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Input,
    InputGroup,
    NavbarBrand,
    Navbar,
    NavLink,
    Nav,
    Container,
    Modal,
    NavbarToggler,
    ModalHeader,
} from "reactstrap";

function AdminNavbar(props) {
    const authContext = useContext(AuthContext);
    const [collapseOpen, setcollapseOpen] = React.useState(false);
    const [modalSearch, setmodalSearch] = React.useState(false);
    const [logo, setLogo] = React.useState('');
    const [color, setcolor] = React.useState("navbar-transparent");

    useEffect(() => {
        getActiveLogo();

        window.addEventListener("resize", updateColor);

        // Specify how to clean up after this effect:
        return function cleanup() {
            window.removeEventListener("resize", updateColor);
        };
    }, [updateColor]);

    const getActiveLogo = () => {
        ImageService.getActiveLogo().then((data) => {
            const path = process.env.REACT_APP_IMAGE_BASE_URL;
            setLogo(path + data.image_path);
        })
    }
    

    // function that adds color white/transparent to the navbar on resize (this is for the collapse)
    const updateColor = () => {
        if (window.innerWidth < 993 && collapseOpen) {
            setcolor("bg-white");
        } else {
            setcolor("navbar-transparent");
        }
    };
    // this function opens and closes the collapse on small devices
    const toggleCollapse = () => {
        if (collapseOpen) {
            setcolor("navbar-transparent");
        } else {
            setcolor("bg-white");
        }
        setcollapseOpen(!collapseOpen);
    };
    // this function is to open the Search modal
    const toggleModalSearch = () => {
        setmodalSearch(!modalSearch);
    };

    const handleSignOutUser = () => {
        authContext.signOutUser();
    }

    const history = useHistory();
    const goLogin = () => history.push('dashboard');

    return (
        <>
            <Navbar className={classNames("navbar-absolute", color)} expand="lg">
                <Container fluid>
                    <div className="navbar-wrapper">
                        <div
                            className={classNames("navbar-toggle d-inline", {
                                toggled: props.sidebarOpened,
                            })}
                        >
                            <NavbarToggler onClick={props.toggleSidebar}>
                                <span className="navbar-toggler-bar bar1" />
                                <span className="navbar-toggler-bar bar2" />
                                <span className="navbar-toggler-bar bar3" />
                            </NavbarToggler>
                        </div>
                        <NavbarBrand href="#" onClick={(e) => e.preventDefault()} className="logo">
                            <img onClick={goLogin} src={logo} alt="Logo do lá na maria" style={{ minWidth: '170px', marginTop: '11px' }} />
                        </NavbarBrand>
                    </div>
                    <NavbarToggler onClick={toggleCollapse}>
                        <span className="navbar-toggler-bar navbar-kebab" />
                        <span className="navbar-toggler-bar navbar-kebab" />
                        <span className="navbar-toggler-bar navbar-kebab" />
                    </NavbarToggler>
                    <Collapse navbar isOpen={collapseOpen}>
                        <Nav className="ml-auto" navbar>
                            {/* <InputGroup className="search-bar">
                                <Button color="link" onClick={toggleModalSearch}>
                                    <i className="lanamaria-icons icon-zoom-split" />
                                    <span className="d-lg-none d-md-block">O que você procura?</span>
                                </Button>
                            </InputGroup>
                            <UncontrolledDropdown nav>
                                <DropdownToggle
                                    caret
                                    color="default"
                                    data-toggle="dropdown"
                                    nav
                                >
                                    <div className="notification d-none d-lg-block d-xl-block" />
                                    <i className="lanamaria-icons icon-bell-55" />
                                    <p className="d-lg-none">Notificações</p>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-navbar" right tag="ul">
                                    <NavLink tag="li">
                                        <DropdownItem className="nav-item">
                                            O estoque de X está abaixo do recomendado.
                                        </DropdownItem>
                                    </NavLink>
                                </DropdownMenu>
                            </UncontrolledDropdown> */}
                            <UncontrolledDropdown nav>
                                <DropdownToggle
                                    caret
                                    color="default"
                                    nav
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <div className="photo">
                                        <img
                                            alt="..."
                                            src={require("assets/img/default-avatar.jpg").default}
                                        />
                                    </div>
                                    <b className="caret d-none d-lg-block d-xl-block" />
                                    <p className="d-lg-none">Sair</p>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-navbar" right tag="ul">
                                    {/* <NavLink tag="li">
                                        <Link to="/admin/perfil">
                                            <DropdownItem className="nav-item">Meu perfil</DropdownItem>
                                        </Link>
                                    </NavLink> */}
                                    {/* <DropdownItem divider tag="li" /> */}
                                    <NavLink tag="li">
                                        <DropdownItem className="nav-item" onClick={handleSignOutUser}>
                                                Sair
                                        </DropdownItem>
                                    </NavLink>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <li className="separator d-lg-none" />
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
            <Modal
                modalClassName="modal-search"
                isOpen={modalSearch}
                toggle={toggleModalSearch}
            >
                <ModalHeader>
                    <Input placeholder="O que você procura?" type="text" />
                    <button
                        aria-label="Close"
                        className="close"
                        onClick={toggleModalSearch}
                    >
                        <i className="lanamaria-icons icon-simple-remove" />
                    </button>
                </ModalHeader>
            </Modal>
        </>
    );
}

export default AdminNavbar;
