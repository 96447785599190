import api from "./api";

class CeltaProductVariantService {
    /**
     * Get all product variants (paginated)
     * @param {number} page 
     * @param {number} per_page 
     * @param {string} query 
     * @param {string} order
     * @return {Promise}
     */
    getProductVariantPaginatedByCodeOrName = (page, per_page, query, ordernation) => {
        return api.get(`/celta/product-variants/index?page=${page}&per_page=${per_page}&query=${query}&order=${ordernation}`);
    }

    /**
     * Get product by 'product_internal_code'
     * @param {string} product_internal_code
     * @return {Promise}
     */
    getProductVariantByProductInternalCode = (product_internal_code) => {
        return api.get(`/celta/product-variants/${product_internal_code}/show`);
    }

    /**
     * Update product by 'product_internal_code'
     * @param {string} product_internal_code
     * @param {object} data
     * @return {Promise}
     */
    // updateProduct = (product_internal_code, data) => {
    //     return api.post(`products/${product_internal_code}/update/`, data, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         }
    //     });
    // }

    /**
     * Get products variants by main product internal code
     * @param {integer} code
     * @param {integer} current_page
     * @param {integer} per_page
     * @param {string} query_filter
     * @param {string} order
     * @return {Promise}
     */
    getMainProductsVariants = (code,current_page, per_page, query_filter, orderBy, order) => {
        //api/celta/products/variants?product_code=1480&page=1&per_page=10&query=
        return api.get(
            `celta/products/variants` + 
            `?product_code=${code}` +
            `&page=${current_page}` + 
            `&per_page=${per_page}` + 
            `&query=${query_filter}`+
            `&order_by=${orderBy}` +
            `&order=${order}`
        );
    }
}
   

export default new CeltaProductVariantService();