import { MDBBtn } from 'mdbreact';
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { Tab, Tabs } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table, FormGroup, Label, Input, Button } from 'reactstrap';

import datehelper from "helpers/datehelper";
import BlogService from 'services/BlogService';
import ModalWindow from 'components/ModalWindow/ModalWindow';

const pagesAvailable = [10, 20, 25, 50, 100];

const availableTabs = [
    {
        id: "1",
        title: 'Pendente',
        
    },
    {
        id: "2",
        title: 'Aprovado'
    },
    {
        id: "3",
        title: 'Reprovado'
    }
];

const defaultDate = new Date();
const todayDate = defaultDate.toISOString().substr(0, 10);

function ManageStatus() {
    const [posts, setPosts] = useState();
    const [updatePosts, setUpdatePosts] = useState(false);

    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [totalPosts, setTotalPosts] = useState(0);
    const [postsPerPage, setPostsPerPage] = useState(10);
    
    const [categories, setCategories] = useState([]);

    const [activeStatus, setActiveStatus] = useState(1);

    const [queryFilter, setQueryFilter] = useState({
        filter: '',
        category: '',
        initialDate: '',
        finalDate: todayDate,
        showClearFiltersButton: false
    });

    const [deletePost, setDeletePost] = useState({
        postToDelete: null,
        showModal: false
    });

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        setPosts([]);
        getPosts();
    }, [page, activeStatus, postsPerPage, updatePosts]);

    const getCategories = async () => {
        const categories = await BlogService.getBlogCategories();
        setCategories(categories);
    }

    const getPosts = () => {
        toast.promise(BlogService.getPostsByStatus( activeStatus, page, postsPerPage, queryFilter), {
            loading: 'Carregando postagens...',
            success: 'Pronto!',
            error: 'Falha na comunicaçao com o servidor!'
        }).then(posts => {
            setPosts(posts.data);
            setTotalPosts(posts.total);
            setLastPage(posts.last_page);
        });
    }

    const updateActiveTab = (activeTab) => {
        if(page !== 1) {
            setPage(1);
        }
        
        setActiveStatus(activeTab);
    } 

    const handleFilters = () => {
        if(Date.parse(queryFilter.initialDate) > Date.parse(queryFilter.finalDate)){
            return toast.error('A data inicial não pode ser maior que a final');
        }
        
        setQueryFilter(prevState => ({
            ...prevState,
             showClearFiltersButton: true
        }));

        setUpdatePosts(prevState => !prevState)
    }

    const handleClearFilterButton = () => {
        setQueryFilter({
            filter: '',
            category: '',
            initialDate: '',
            finalDate: todayDate,
            showClearFiltersButton: false
        });

        if(page !== 1) {
            setPage(1);
            return;
        }
        
        setUpdatePosts(prevState => !prevState);
        toast.success('Filtros removidos')
    }

    const deletePostById = (postId) => {
        toast.promise(BlogService.deletePostById(postId), {
            loading: 'Deletando postagem...',
            success: 'Pronto!',
            error: 'Falha na comunicaçao com o servidor!'
        }).then(() => {
            setUpdatePosts(prevState => !prevState);
        });
    }

    const handleDeletePost = () => {
        if (!deletePost.showModal) {
            return null;
        }

        return (
            <ModalWindow show={deletePost.showModal} printable={false} width="300px">
                <Card>
                    <CardHeader>
                        <Row>
                            <Col style={{ textAlign: 'end', marginTop: '-5px' }}>
                                <Button className="btn btn-danger btn-icon lanamaria-icons icon-simple-remove" title="Fechar" onClick={() => setDeletePost({ ...deletePost, postToDelete: null, showModal: false })} />
                            </Col>
                            <Col md="12">
                                <h6 style={{ fontSize: '26px', textTransform: 'initial', marginTop: '4px' }}>Confirmar exclusão</h6>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <p>Você confirma a exclusão da postagem nº {deletePost?.postToDelete?.id}</p>
                        <small style={{ color: 'black' }}>
                            <b>*Obs.: ao confirmar, a postagem será deletada permanentemente</b>
                        </small>
                        <hr />
                        <Button className="btn btn-blue" type="submit" title="Excluir logo" style={{ width: '100%' }} onClick={() => {
                            setDeletePost({ ...deletePost, postToDelete: null, showModal: false })
                            deletePostById(deletePost?.postToDelete?.id);
                        }}>Sim</Button>
                    </CardBody>
                </Card>
            </ModalWindow>
        )
    }

    return (
        <div className="content">
            {handleDeletePost()}
            <Row>
                <Col>
                    <h3 className="title">Gerenciar status</h3>
                    <Card>
                        <CardHeader>
                            <p className="category">* Listagem de todas as postagens separadas por status</p>
                        </CardHeader>
                        <CardBody>
                            <Label>
                                <h5 style={{ fontSize: '20px' }}><b>Filtro</b></h5>
                            </Label>
                            <Row>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>
                                            <h6>Pesquisar por título ou id</h6>
                                        </Label>
                                        <Input type="text" name="product_name_code" placeholder="Busque por título ou código da publicação" value={queryFilter?.filter} onChange={event => setQueryFilter(prevState => ({...prevState, filter: event.target.value}))}/>
                                    </FormGroup>
                                </Col>
                                <Col md="4">
                                    <FormGroup>
                                        <Label>
                                            <h6>Pesquisar por categoria</h6>
                                        </Label>
                                        <Input 
                                            type="select" 
                                            defaultValue=""
                                            onChange={event => setQueryFilter(prevState => ({...prevState, category: event.target.value}))}
                                            >
                                            <option disabled={true} value="">Escolha uma categoria</option>
                                            {categories?.map(category => {
                                              return (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                              );
                                            })}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                                <Row>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label>
                                                <h6>Data inicial</h6>
                                            </Label>
                                            <Input 
                                                type="date" 
                                                value={queryFilter?.initialDate}
                                                onChange={event => setQueryFilter(prevState => ({...prevState, initialDate: event.target.value}))}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="4">
                                        <FormGroup>
                                            <Label>
                                                <h6>Data final</h6>
                                            </Label>
                                            <Input 
                                                type="date" 
                                                value={queryFilter?.finalDate}
                                                onChange={event => setQueryFilter(prevState => ({...prevState, finalDate: event.target.value}))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            <Row>
                                <Col className="mt-2">
                                    <Button className="btn btn-primary" type="submit" title="Pesquisar" style={{ width: '130px' }} onClick={handleFilters}>Filtrar</Button>
                                </Col>
                                {queryFilter?.showClearFiltersButton && 
                                    <Col className="col-sm-4 mt-2">
                                        <Button className="btn btn-danger" type="submit" title="Pesquisar" style={{ width: '200px' }} onClick={handleClearFilterButton}>Limpar Filtros</Button>
                                    </Col>
                                }
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <Tabs
                                        id="post-status-list"
                                        activeKey={queryFilter.activeTab}
                                        onSelect={key => updateActiveTab(key)}
                                    >
                                        {availableTabs?.map(tab => ( 
                                            <Tab 
                                                key={tab.id}
                                                eventKey={tab.id}
                                                title={tab.title}
                                                tabClassName="tab-primary"
                                            >
                                                <Table hover striped responsive>
                                                     <thead>
                                                        <tr>
                                                            <th>Id</th>
                                                            <th>Título</th>
                                                            <th>Categoria</th>
                                                            <th>Status</th>
                                                            <th>Criado em</th>
                                                            <th>Atualizado em</th>
                                                            <th>Visualizar</th>
                                                            {tab.id === "3" && <th>Excluir</th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {posts?.map((post) => (
                                                            <tr key={post.id}>
                                                                <td>{post.id}</td>
                                                                <td>{post.title}</td>
                                                                <td>{post.category}</td>
                                                                <td>
                                                                    <div className={`badge badge-pill badge-${post.post_statuses_id === 1 ? 'primary' : post.post_statuses_id === 2 ? 'success' : post.post_statuses_id === 3 ? 'danger' : ''}`}>
                                                                       <span> {post.status}</span>
                                                                    </div>
                                                                </td>
                                                                <td>{datehelper.formatDateWithHours(post.created_at)}</td>
                                                                <td>{datehelper.formatDateWithHours(post.updated_at)}</td>
                                                                <td>
                                                                    <Link to={`/admin/blog/post/${post.id}/detalhes`}>
                                                                        <MDBBtn className="btn btn-primary btn-icon lanamaria-icons icon-zoom-split" title="Visualizar"></MDBBtn>
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    {post.post_statuses_id === 3 &&
                                                                        <MDBBtn className="btn btn-danger btn-icon lanamaria-icons icon-trash-simple" title="Excluir postagem" onClick={() => setDeletePost({ ...deletePost, postToDelete: post, showModal: true })}></MDBBtn>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </Tab>
                                        ))}
                                    </Tabs>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <select 
                                        onChange={event => setPostsPerPage(parseInt(event.target.value))}
                                        className="custom-select custom-select-sm form-control form-control-sm" 
                                        style={{ width: '200px', position: 'relative', float: 'left', marginRight: '10px' }}>
                                        {pagesAvailable.map((p, index) =>
                                            <option key={index}>{p} resultados de {totalPosts}</option>
                                        )}
                                    </select>
                                    <ul data-test="pagination" className="pagination m-0" style={{ paddingTop: '3px' }}>
                                        <li data-test="page-item" className={"page-item " + (page === 1 ? 'disabled' : '')}>
                                            <button data-test="page-link" aria-label="Anterior" className="page-link page-link" onClick={() => setPage(Math.max(page - 1, 1))}>
                                                <span><i className="fas fa-chevron-left"></i></span>
                                            </button>
                                        </li>
                                        <span style={{ marginLeft: '5px', marginRight: '5px' }}>
                                            {page}
                                        </span>
                                        <li data-test="page-item" className={"page-item " + (page === lastPage ? 'disabled' : '')}>
                                            <button data-test="page-link" aria-label="Anterior" className="page-link page-link" onClick={() => setPage(Math.min(page + 1, lastPage))}>
                                                <span><i className="fas fa-chevron-right"></i></span>
                                            </button>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ManageStatus;