import toast from "react-hot-toast";
import Cropper from "react-cropper";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Input, Label, Row, CardHeader } from "reactstrap";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/pt-br";

import BlogService from "services/BlogService";
import CkEditorUploadAdapter from "../../../helpers/CkEditorUploadAdapter";

const baseImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

function EditPost(props) {
  const [post, setPost] = useState({});
  const [categories, setCategories] = useState([]);

  const [cropper, setCropper] = useState({
    image: null,
    src: '',
    cropResult: null,
    sendImage: null
  });

  useEffect(() => {
    getCategories();
    getPostData();
  }, []);

  const getPostData = () => {
    const postId = props.match.params.id;

    toast.promise(BlogService.getPostData(postId), {
        loading: 'Carregando dados da postagem',
        success: 'Pronto!',
        error: 'Falha na comunicação com o servidor'
    }).then(data => {
      setPost(data);
    });
  }

  const getCategories = () => {
    BlogService.getPostCategories().then(data => {
      setCategories(data);
    });
  }

  const updatePost = () => {
    let form = new FormData();
    
    form.append('title', post?.title);
    form.append('subtitle', post?.subtitle);
    form.append('content', post?.content);
    form.append('category', post?.categories_id);
    form.append('banner', cropper?.sendImage);
    form.append('banner_id', post?.post_images_id);
    form.append('status', post?.post_statuses_id);

    toast.promise(BlogService.updatePostById(form, post?.id), {
      loading: 'Salvando...',
      error: 'Falha na comunicação com o servidor',
      success: 'Publicação cadastrada com sucesso!'
    }).then(() => {
      setTimeout(() => {
        props.history.push('/admin/blog/gerenciar');
      }, 1500);
    });
  }

  const onChange = (e) => {
    const files = e.target.files;

    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
          setCropper({ ...cropper, src: reader.result })
      );
      reader.readAsDataURL(files[0]);
    }
  }

  const cropImage = () => {
    if (!cropper?.cropper?.getCroppedCanvas() || cropper?.cropper?.getCroppedCanvas().toDataURL() === null) {
        toast.error('Selecione uma imagem');
        return;
    }

    if (typeof cropper.cropper.getCroppedCanvas() === 'undefined') {
        return;
    }

    cropper.cropper.getCroppedCanvas().toBlob((blob) => {
        let imageUrl = URL.createObjectURL(blob);
        setCropper({
            ...cropper,
            cropResult: imageUrl,
            sendImage: blob
        });
    }, 'image/jpeg');

    toast.success('Imagem cortada com sucesso');
  }
  
  const isObjectEmpty = (object) => {
    return Object.keys(object).length === 0;
  }

  return(
    <div className="content">
      <Row>
        <Col>
          <h3 className="title">Editar publicação</h3>
          <Card>
            <CardHeader>
              <Row>
                <Col md="10">
                  <Label className="mr-2" style={{fontSize: 18, fontWeight: 900}}>
                    #Id {post?.id}
                  </Label>
                </Col>
                <Col md="2">
                  {isObjectEmpty(post) === false &&
                    <div className={`badge badge-pill badge-${post.post_statuses_id === 1 ? 'primary' : post.post_statuses_id === 2 ? 'success' : post.post_statuses_id === 3 ? 'danger' : ''}`}>
                      <span className="mr-2">Status:</span>
                      <span>{post?.status}</span>
                    </div>
                  }
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Label className="font-weight-bolder text-dark">
                    Titulo
                  </Label>
                  <Input
                    defaultValue={post?.title} 
                    onChange={(e) => setPost(prevState => ({...prevState, title: e.target.value}))}/>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label className="font-weight-bolder mt-5 text-dark">
                    Sub título
                  </Label>
                  <Input
                    defaultValue={post?.subtitle}  
                    onChange={(e) => setPost(prevState => ({...prevState, subtitle: e.target.value}))}/>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label className="font-weight-bolder mt-5 text-dark">
                    Categoria
                  </Label>
                  <Input type="select" defaultValue="" onChange={(e) => setPost(prevState => ({...prevState, categories_id: e.target.value}))}>
                    <option disabled={true} value="">Escolha uma categoria</option>
                    {categories.map(category => {
                      return (
                        <option key={category.id} value={category.id}>{category.name}</option>
                      );
                    })}
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label className="font-weight-bolder mt-5 text-dark">
                    Status da postagem
                  </Label>
                  <Input type="select" defaultValue="" onChange={(e) => setPost(prevState => ({...prevState, post_statuses_id: e.target.value}))}>
                    <option disabled={true} value="">Escolha um status</option>
                    <option value={1}>Pendente</option>
                    <option value={2}>Aprovado</option>
                    <option value={3}>Reprovado</option>
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col md="12" className="d-flex flex-column align-items-center my-5">
                  <Label className="font-weight-bolder mt-5 text-dark">
                    Banner carregado
                  </Label>
                  {post.image 
                    ? <img
                    width={600}
                    height={300}
                    src={`${baseImageUrl}${post?.image?.path}${post?.image?.name}`} alt="" />
                    : <p>* Banner da publicação não encontrado</p>
                  }
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <div className="content-preview-img-form">
                    <Label className="font-weight-bolder mt-5 text-dark">
                      Novo banner
                    </Label>
                    {!cropper?.src && <div className="cropper-bg"></div>}
                    <Cropper
                      viewMode={1}
                      guides={true}
                      dragMode="move"
                      responsive={true}
                      src={cropper?.src}
                      minCropBoxWitdh={10}
                      minCropBoxHeight={10}
                      preview=".img-preview"
                      cropBoxMovable={false}
                      cropBoxResizable={false}
                      onInitialized={(instance) => {
                        setCropper({
                          ...cropper,
                          cropper: instance
                        });
                      }}
                    />
                  </div>
                </Col>
                <Col md="6">
                  {cropper?.cropResult && 
                    <div className="my-5 d-flex flex-column align-items-center">
                     <Label>
                        <h6>Pré-visualização do Banner da postagem</h6>
                     </Label>
                     <div className="content-preview-img-form mt-4">
                        <img style={{ width: "100%", height: '250px' }} src={cropper?.cropResult || cropper?.image} alt="Imagem cortada" />
                      </div>

                    </div>
                  }
                </Col>
              </Row>
              <Row className="mt-4">
                  <Col md="9">
                      <Input 
                        type="file" 
                        accept="image/*" 
                        name="image_preview_url"
                        className="edit-image-input"
                        onLoad={(event) => { event.target.value = null }} onChange={onChange} required />
                  </Col>
                  {cropper?.src &&
                    <Col md="3">
                        <Button className="btn btn-primary" onClick={cropImage} style={{width: 200}}>Cortar imagem</Button>
                    </Col>
                  }
              </Row>
              <hr />
              <Row>
                <Col>
                  <Label className="font-weight-bolder text-dark">
                    Texto
                  </Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={post.content ? post.content : ''}
                    onChange={(event, editor) => setPost(prevState => ({...prevState, content: editor.getData()}))}
                    onReady={editor => {
                      editor.plugins.get("FileRepository").createUploadAdapter = loader => {
                        return new CkEditorUploadAdapter(loader);
                      }
                    }}
                    config={{
                      toolbar: {
                        items: [
                          'heading',
                          '|',
                          'link',
                          '|',
                          'bold',
                          'italic',
                          '|',
                          'bulletedList',
                          'numberedList',
                          '|',
                          'imageUpload',
                          '|',
                          'undo',
                          'redo'
                        ]
                      },
                      image: {
                        toolbar: [
                            'imageStyle:alignLeft',
                            'imageStyle:alignCenter',
                            'imageStyle:alignRight',
                            '|',
                            'imageStyle:full',
                            'imageStyle:side',
                            '|',
                            'imageTextAlternative'
                          ],
                          styles: [
                            'full',
                            'side',
                            'alignLeft', 'alignCenter', 'alignRight'
                          ],
                          resizeOptions: [
                            {
                                name: 'imageResize:original',
                                label: 'Original',
                                value: null
                            },
                            {
                                name: 'imageResize:50',
                                label: '50%',
                                value: '50'
                            },
                            {
                                name: 'imageResize:75',
                                label: '75%',
                                value: '75'
                            }
                        ]
                      },
                      table: {
                        contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
                      },                
                      language: "pt-br",
                    }}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col className="d-flex flex-row justify-content-center">
                  <Button onClick={updatePost}>
                      Salvar
                  </Button>   
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default EditPost;