import {Col, Row} from "reactstrap";
import moment from "moment";

export default function PixPayment({orderPayment}) {
    const formatPixPaymentDate = () => {
       return moment(orderPayment?.calendario?.criacao)?.utc()?.format("DD/MM/YYYY HH:mm")
    }
    const formatPixPaymentStatus = () => {
        return orderPayment?.status === "CONCLUIDA" ? "Concluída" : "Ativa";
    }

    return  (
        <>
            <Row className="mt-3">
                <Col md="4">
                    <h4 className="title">Pagamento (PIX)</h4>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md="4">
                    <h5><b>Data do pagamento: {formatPixPaymentDate()}</b> </h5>
                </Col>
                <Col md="3">
                    <h5><b>Status: {formatPixPaymentStatus()}</b></h5>
                </Col>
                <div className="ml-2">
                    <span className={`badge badge-pill badge-`}>
                    </span>
                </div>
            </Row>
            <hr />
        </>
    );
}