import toast from "react-hot-toast";
import { Link } from 'react-router-dom';
import { MDBDataTableV5 } from 'mdbreact';
import { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row, Button } from 'reactstrap';

import datehelper from "helpers/datehelper";
import PageService from 'services/PageService';

function Index() {
    const [pages, setPages] = useState();

    useEffect(() => {
        getPages();
    }, []);

    const getPages = () => {
        toast.promise(PageService.getAllPages(), {
            loading: 'Buscando...',
            success: 'Pronto!'
        }).then(data => {
            setPages(data);
        });
    }

    return(
        <div className="content">
            <Row>
                <Col>
                    <h3 className="title">Páginas</h3>
                    <Card>
                        <CardHeader>
                            <p className="category">* Lista de todas as páginas</p>
                        </CardHeader>
                        <CardBody>
                            <hr />
                            <Row>
                                <Col>
                                    <MDBDataTableV5
                                    hover
                                    striped
                                    responsive={true}
                                    exportToCSV={true}
                                    barReverse={false}
                                    displayEntries={true}
                                    autoWidth={false}
                                    bordered={false}
                                    borderless={false}
                                    btn={true}
                                    noBottomColumns={true}
                                    paging={true}
                                    searching={false}
                                    searchTop={true}
                                    searchBottom={false}
                                    theadTextWhite={true}
                                    sortable={true}
                                    className="pages-table"
                                    entriesLabel="Itens por página"
                                    searchLabel="O que você procura?"
                                    paginationLabel={['Anterior', 'Próximo']}
                                    infoLabel={["exibindo", "de", "de", "itens"]}
                                    noRecordsFoundLabel="Nenhum dado encontrado. Por favor, refina sua busca!"
                                    data={{
                                        columns: [
                                            {
                                                label: 'Código',
                                                field: 'code',
                                                sort: 'asc'
                                            },
                                            {
                                                label: 'Titulo',
                                                field: 'title',
                                                sort: 'disabled'
                                            },
                                            {
                                                label: 'Criado em',
                                                field: 'createdAt',
                                                sort: 'disabled'
                                            },
                                            {
                                                label: 'Atualizado em',
                                                field: 'updatedAt',
                                                sort: 'disabled'
                                            },
                                            {
                                                label: 'Editar',
                                                field: 'edit',
                                                sort: 'disabled'
                                            },
                                        ],
                                        rows: pages?.map(page => {
                                            return {
                                                code: page.id,
                                                title: page.title,
                                                createdAt: datehelper.formatDateWithHours(page.created_at),
                                                updatedAt: page.updated_at && datehelper.formatDateWithHours(page.updated_at),
                                                edit: <div style={{ display: 'flex' }}>
                                                    <Link  to={`${page.id}/editar`} style={{ padding: '2px' }}>
                                                        <Button className="btn btn-icon btn-secondary lanamaria-icons icon-pencil" title="Editar" />
                                                    </Link>
                                                </div>
                                            }
                                        })
                                    }}/>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Index;