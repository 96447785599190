import React, { useState, useEffect } from 'react';
import Loader from 'components/Loader/Loader';
import toast from 'react-hot-toast';

import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import { Link } from 'react-router-dom';
import { MDBDataTableV5 } from 'mdbreact';
import ImageService from '../../../../../services/ImageService';
import ModalWindow from 'components/ModalWindow/ModalWindow';

const imageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

function Index() {

    const [loading, setLoading] = useState(true);

    const [banners, setBanners] = useState([]);

    const [deleteBanner, setDeleteBanner] = useState(
        {
            bannerToDelete: null,
            showModal: false
        }
    );

    useEffect(() => {
        getAllBanners();
    }, []);

    const getAllBanners = () => {
        ImageService.getDepartmentBanner().then((data) => {
            toast.remove();
            setBanners(data);
            setLoading(false);
        });
    }

    const getImageUrl = bannerData => {
        const imagePath = bannerData?.path;
        const imageName = bannerData?.name;

        return imageUrl + imagePath + imageName;
    }

    const handleDeleteBanner = () => {
        if (!deleteBanner.showModal) {
            return null;
        }

        return (
            <ModalWindow show={deleteBanner.showModal} width="300px" printable={false}>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col style={{ textAlign: 'end', marginTop: '-5px' }}>
                                <Button className="btn btn-danger btn-icon lanamaria-icons icon-simple-remove" title="Fechar" onClick={() => setDeleteBanner({ ...deleteBanner, bannerToDelete: null, showModal: false })} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <h6 style={{ fontSize: '26px', textTransform: 'initial', marginTop: '4px' }}>Confirmar exclusão</h6>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <p>Você confirma a exclusão do banner da posição nº {deleteBanner?.bannerToDelete?.id}</p>
                        <small style={{ color: 'black' }}>
                            <b>*Obs.: ao confirmar, o banner será deletado permanentemente</b>
                        </small>
                        <hr />
                        <Button className="btn btn-blue" type="submit" title="Remover banner" style={{ width: '100%' }} onClick={() => {
                            deleteBannerById(deleteBanner?.bannerToDelete?.id);
                            setDeleteBanner({ ...deleteBanner, bannerToDelete: null, showModal: false })
                        }}>Sim</Button>
                    </CardBody>
                </Card>
            </ModalWindow>
        )
    }

    const deleteBannerById = (id) => {
        toast.promise(ImageService.deleteDepartmentBannerById(id), {
            loading: 'Deletando banner...',
            success: 'Banner deletado com sucesso!',
            error: 'Não foi possível deletar o banner.'
        }).then((data) => {
            setTimeout(() => {
                getAllBanners();
            }, 1000);
        });
    }

    return (
        <div className="content">
            {loading ?
                <Loader loading={loading} />
                :
                <Row>
                    <Col>
                        <h3 className="title">Banners</h3>
                        <div className="text-left">
                            {handleDeleteBanner()}
                            <Link to={'/admin/imagens/banner-departamento/criar'} className="btn btn-blue mb-20">Cadastrar novo banner</Link>
                        </div>
                        <Card>
                            <CardHeader>
                                <p className="category">*Lista de todos os banners cadastrados</p>
                            </CardHeader>
                            <CardBody>
                                <hr className='mtn-10' />
                                {!banners?.length
                                    ?
                                    <h6>*Não há banners cadastrados</h6>
                                    :
                                    <MDBDataTableV5
                                        striped
                                        hover
                                        responsive={true}
                                        data={
                                            {
                                                columns: [
                                                    {
                                                        label: 'ID',
                                                        field: 'position',
                                                        sort: 'disabled'
                                                    },
                                                    {
                                                        label: 'Imagem',
                                                        field: 'image_path',
                                                        sort: 'disabled',
                                                    },
                                                    {
                                                        label: 'Categoria',
                                                        field: 'category',
                                                        sort: 'disabled',
                                                    },
                                                    {
                                                        label: 'Ações',
                                                        field: 'actions',
                                                    }
                                                ],
                                                rows: banners.map((item) => {
                                                    return {
                                                        position: item?.id,
                                                        image_path: <img src={getImageUrl(item)} alt={item.title} width={500} height={120} />,
                                                        category:
                                                            <span style={{ color: item.hex_color_text, fontWeight: 'bold', fontSize: 15 }}>
                                                                {item?.commerce_department?.Nome_DepartamentoCommerce}
                                                            </span>,
                                                        actions: <div style={{ display: 'flex' }}>
                                                            <Link to={`/admin/imagens/banner-departamento/${item?.id}/editar`} style={{ padding: '2px' }}>
                                                                <Button className="btn btn-blue btn-icon lanamaria-icons icon-pencil" title="Editar" />
                                                            </Link>
                                                            <Button className="btn btn-danger btn-icon lanamaria-icons icon-trash-simple" style={{ margin: '2px' }} title="Remover" onClick={() => setDeleteBanner({ ...deleteBanner, bannerToDelete: item, showModal: true })} />
                                                        </div>
                                                    }
                                                })
                                            }
                                        }
                                        key={banners.id}
                                        exportToCSV={true}
                                        barReverse={true}
                                        displayEntries={true}
                                        autoWidth={false}
                                        bordered={false}
                                        borderless={false}
                                        btn={true}
                                        noBottomColumns={true}
                                        paging={true}
                                        searching={false}
                                        searchTop={false}
                                        searchBottom={false}
                                        theadTextWhite={true}
                                        sortable={true}
                                        entriesLabel="Itens por página"
                                        searchLabel="O que você procura?"
                                        paginationLabel={['Anterior', 'Próximo']}
                                        infoLabel={["exibindo", "de", "de", "itens"]}
                                        noRecordsFoundLabel="Nenhum dado encontrado. Por favor, refina sua busca!"
                                    />
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </div>
    )
}


export default Index;