import React, { useState, useEffect, useCallback } from 'react';
import Loader from 'components/Loader/Loader';
import toast from 'react-hot-toast';

import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';

import ImageService from '../../../../../services/ImageService';
import getCroppedImg from 'helpers/cropper';

import Cropper from "react-easy-crop";
import noImage from "../../../../../assets/img/no_background.png"
import imgHeaderHome from "../../../../../assets/img/home-header-1.png";
import { ChromePicker } from 'react-color';
import { Link } from 'react-router-dom';

const imageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

function Edit(props) {
    const id = props.match.params.id;

    const [loading, setLoading] = useState(true);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [croppedBanner, setCroppedBanner] = useState({binary: null, preview: null});
    const [banner, setBanner] = useState({});
    const [bannerPreview, setBannerPreview] = useState("");
    const [bannerData, setBannerData] = useState({});

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    useEffect(() => {
        getBannerById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const getBannerById = () => {
        ImageService.getDepartmentBannerById(id).then((data) => {
            setBanner(data);
            setLoading(false);
        }, (error) => {
            toast.remove();
            setLoading(false);
            if (error.response.status === 404) {
                toast.error('Banner não encontrado');
                setTimeout(() => {
                    props.history.push('/admin/imagens/banner-departamento');
                }, 2000);
                return;
            }
            toast.error(error.response.data.message);
        });
    }

    const handleFileUpload = (event) => {
        try {
            const imageFile = event.target.files[0];

            setBannerData(imageFile);
            setBannerPreview(URL.createObjectURL(imageFile));
        } catch (error) {
            console.log(error);
        }
    }

    const getImageUrl = bannerData => {
        const imagePath = bannerData?.path;
        const imageName = bannerData?.name;

        return imageUrl + imagePath + imageName;
    }

    const handleBanner = () => {
        const logoId = props.match.params.id;
        
        if(croppedBanner?.binary === null) {
            toast.error('Selecione uma imagem');
            return;
        }

        let form = new FormData();
        form.append('id', logoId);
        form.append('banner', croppedBanner?.binary);

        toast.promise(ImageService.updateDepartmentBannerById(form), {
            loading: 'Salvando...',
            success: 'Imagem salva com sucesso!',
            error: "Não foi possível salvar o banner"
        }).then(() => {
            setTimeout(() => {
                props.history.push('/admin/imagens/banner-departamento');
            }, 1500)
        });
    }

    const cropImage = useCallback(async () => {
        try {
           getCroppedImg(bannerPreview, croppedAreaPixels, bannerData)
           .then(image => {
               setCroppedBanner({
                   preview: image?.previewImage,
                   binary: image?.binaryImage
               });

               toast.success("Imagem cortada com sucesso!");

           });
        } catch (error) {
            toast.error("Selecione uma imagem");
        }
    }, [croppedAreaPixels, bannerPreview]);

    return (
        <div className="content">
            {loading ?
                <Loader loading={loading} />
                :
                <Row>
                    <Col>
                        <h3 className="title">Editar banner</h3>
                        <Card>
                            <CardHeader>
                                <p className="category">*Editando o banner com ID nº {id}</p>
                            </CardHeader>
                            <CardBody>
                                <hr className='mtn-10' />
                                <Row className="mb-5">
                                    <Col>
                                        <Link to="/admin/imagens/banner-departamento">
                                            <Button>
                                                Voltar para listagem de produto
                                            </Button>
                                        </Link>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className="content-preview-img-form">
                                            {!croppedBanner?.src &&
                                                <div className="cropper-bg"></div>
                                            }

                                            <Cropper 
                                                crop={crop}
                                                showGrid={true}
                                                onCropChange={setCrop}
                                                image={bannerPreview}
                                                onCropComplete={onCropComplete}
                                                aspect={100 / 20}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="6" className="d-flex align-items-center">
                                        <div className="content-preview-img-form">
                                            <img src={imgHeaderHome} style={{ width: "100%" }} alt="Imagem do 'header home'" />
                                            <div style={{ width: "100%", height: '96px', backgroundSize: "cover", backgroundImage: `url(${croppedBanner?.preview ? croppedBanner?.preview :  getImageUrl(banner)})` }} alt="Imagem cortada" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mt-4">
                                    <Col className="d-flex justify-content-center">
                                        <h5>* É recomendado utilzar uma imagem Full HD (1920x1080)</h5>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col md="4">
                                        <FormGroup>
                                            <Input 
                                                type="file"
                                                className="form-control"
                                                name="image_preview_url"
                                                accept="image/*"
                                                onLoad={(event) => { event.target.value = null }} 
                                                onChange={handleFileUpload} 
                                                style={{ border: '1px solid rgba(0, 0, 0, 0.5)', borderRadius: '5px', fontSize: '13px', padding: '10px', marginTop: '4px', width: '100%', float: 'left', opacity: 1, position: 'initial' }} 
                                                required 
                                            />
                                        </FormGroup>
                                    </Col> 
                                    <Col md="2">
                                        <Button 
                                            className="btn btn-primary mt-2"
                                            onClick={cropImage}
                                            style={{ width: '140px', fontWeight: '600' }}
                                        >
                                            Cortar
                                        </Button>
                                    </Col>
                                </Row> 
                                <hr />
                                <Row className="d-flex justify-content-center">
                                    <Col md="2">
                                        <Button onClick={handleBanner} style={{ width: '100%' }}>Salvar</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            }
        </div>
    )
}


export default Edit;