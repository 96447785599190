import React, { useState, useEffect } from 'react';
// Services
import CeltaProductVariantService from 'services/CeltaProductVariantService';
// Components
import Switch from "react-switch";
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import Loader from 'components/Loader/Loader';
import { Button, Card, CardBody, CardHeader, Col, Row, Table, FormGroup, Input, Label, Form } from 'reactstrap';
import NotFoundImage from '../../../assets/img/no-product-image.jpeg'

const pagesAvailable = [10, 20, 50, 100];

function Variants(props) {
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [productsCount, setProductsCount] = useState(0);
    const [resultsPerPage, setResultsPerPage] = useState(10);
    const maxPages = Math.ceil(productsCount / resultsPerPage);
    const [orderBy, setOrderBy] = useState("color_code");
    const [descendingOrder, setDescendingOrder] = useState("desc");

    const code = props.match.params.code;

    useEffect(() => {
        getVariantsProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, resultsPerPage, orderBy, descendingOrder]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getVariantsProducts = () => {
        const current_page = page;
        const query_filter = query;
        const per_page = resultsPerPage;


        toast.promise(CeltaProductVariantService.getMainProductsVariants(code, current_page, per_page, query_filter, orderBy, descendingOrder), {
            loading: 'Carregando...',
            success: 'Pronto!'
        }).then((response) => {
            setProducts(response.data.data);
            setProductsCount(response.data.total);
            setLoading(false);
        });
    }

    return(
        <div className="content">
        {loading ?
            <Loader loading={loading} />
            :
            <Row>
                <Col>
                    <h3 className="title">Variantes do produto </h3>
                    <Card>
                        <CardHeader>
                            <p className="category">*Lista de todas as variantes do produto #<u>{code}</u> cadastrados</p>
                        </CardHeader>
                        <CardBody>
                            <hr className='mtn-10' />
                            <Form onSubmit={(e) => {
                                e.preventDefault();
                                getVariantsProducts();
                            }}>
                                <Label>
                                    <h5 style={{ fontSize: '20px' }}><b>Filtro</b></h5>
                                </Label>
                                <Row>
                                    <Col md="10">
                                        <FormGroup>
                                            <Label>
                                                <h6>Pesquisar variante de produto</h6>
                                            </Label>
                                            <Input type="text" name="product_variant_name_code" placeholder="Busque por nome ou código da variante do produto" onChange={(e) => setQuery(e.target.value)} />
                                        </FormGroup>
                                    </Col>
                                    <Col md="2">
                                        <FormGroup>
                                            <Button className="btn btn-primary" type="submit" title="Pesquisar" style={{ width: '100%', marginTop: '30px' }}>Filtrar</Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Form>
                            <Row>
                                <Col md="4">
                                        <div className="d-flex align-items-baseline">
                                            <Label><h6>Ordernar por:</h6></Label>
                                            <select
                                                defaultValue="color_code"
                                                className="custom-select custom-select-sm form-control form-control-sm ml-2"
                                                style={{ width: '200px', position: 'relative', float: 'left', marginRight: '10px' }}
                                                onChange={(e) => setOrderBy(e.target.value)}
                                            >
                                                <option value="color_code">Código cor</option>
                                                <option value="color_name">Nome cor</option>
                                                <option value="product_name">Nome do produto</option>
                                            </select>
                                        </div>
                                </Col>
                                <Col md="5">
                                        <div className="d-flex align-items-baseline">
                                            <Label><h6>Ordem:</h6></Label>
                                            <select
                                                defaultValue="desc"
                                                className="custom-select custom-select-sm form-control form-control-sm ml-2"
                                                style={{ width: '200px', position: 'relative', float: 'left', marginRight: '10px' }}
                                                onChange={(e) => setDescendingOrder(e.target.value)}
                                            >
                                                <option value="desc">Decrescente</option>
                                                <option value="asc">Crescente</option>
                                            </select>
                                        </div>
                                </Col>
                            </Row>
                            {products?.length > 0 &&
                                <>
                                    <hr />
                                    <Table hover striped responsive>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '5%' }}>Código</th>
                                                <th style={{width: '25%', textAlign: "center"}}>Imagem do produto</th>
                                                <th style={{ width: '60%' }}>Nome</th>
                                                <th style={{width: '25%',  textAlign: "center" }}>Código cor</th>
                                                <th style={{width: '35%',  textAlign: "center" }}>Nome cor</th>
                                                <th style={{ textAlign: "center" }}>Editar</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {products?.map((product, index) => { 
                                                const imagePath = process.env.REACT_APP_IMAGE_BASE_URL;
                                                const imageName = product?.image?.path + product?.image?.name;

                                                const imageSrc = imagePath + imageName;

                                                return (
                                                    <tr key={product?.CodigoInterno_Produto}>
                                                        <td>{product?.CodigoInterno_Produto}</td>
                                                        <td className="d-flex justify-content-center">
                                                            <img width={200} height={135} src={imageName ? imageSrc : NotFoundImage} />
                                                        </td>
                                                        <td>{product?.Nome_Produto}</td>
                                                        <td style={{textAlign: 'center'}}>{product?.Abreviacao_Cor}</td>
                                                        <td style={{textAlign: 'center'}}>{product?.Nome_Cor}</td>
                                                        <td style={{ textAlign: 'center' }}>
                                                            <Link to={`/admin/produtos/variantes/${product?.CodigoInterno_Produto}/editar`}>
                                                                <Button
                                                                    title="Editar produto principal"
                                                                    className="btn btn-icon btn-primary lanamaria-icons icon-pencil" />
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>

                                    <select
                                        className="custom-select custom-select-sm form-control form-control-sm"
                                        defaultValue="1"
                                        style={{ width: '200px', position: 'relative', float: 'left', marginRight: '10px' }}
                                        onChange={(e) => setResultsPerPage(parseInt(e.target.value))}>
                                        {pagesAvailable?.map((p, index) =>
                                            <option key={index} value={p}>{p} resultados de {productsCount}</option>
                                        )}
                                    </select>

                                    <ul data-test="pagination" className="pagination m-0" style={{ paddingTop: '3px' }}>
                                        <li data-test="page-item" className={"page-item " + (page === 1 ? 'disabled' : '')}>
                                            <button data-test="page-link" aria-label="Anterior" className="page-link page-link" onClick={() => setPage(Math.max(page - 1, 1))}>
                                                <span><i className="fas fa-chevron-left"></i></span>
                                            </button>
                                        </li>
                                        <span style={{ marginLeft: '5px', marginRight: '5px' }}>
                                            {page}
                                        </span>
                                        <li data-test="page-item" className={"page-item " + (page === maxPages ? 'disabled' : '')}>
                                            <button data-test="page-link" aria-label="Próximo" className="page-link page-link" onClick={() => setPage(Math.min(page + 1, maxPages))}>
                                                <span><i className="fas fa-chevron-right"></i></span>
                                            </button>
                                        </li>
                                    </ul>
                                </>
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        }
    </div>

    );
}

export default Variants;