import { useState } from "react";
import toast from "react-hot-toast";
import { Button, Col, Input, Row } from "reactstrap";

import HomePageService from "services/HomePageService";



function SingleBannerSection() {
	const [bannerUrl, setBannerUrl] = useState("");
	const [bannerBlob, setBannerBlob] = useState({});

	const handleBannerUpload = event => {
		const file = event.target.files[0];
		const fileReader = new FileReader();

		fileReader.readAsArrayBuffer(file);
		fileReader.onload = function() {
			const blob = new Blob([fileReader.result]);
			const imageUrl = URL.createObjectURL(blob);

			setBannerBlob(blob);
			setBannerUrl(imageUrl);
		}
    }

	const createCustomSection = () => {
		let form = new FormData();
		
		form.append('section_type', 3);
		form.append('is_active', false);
		form.append('main_banner', bannerBlob);

		toast.promise(HomePageService.createCustomSection(form), {
			loading: 'Criando seção customizada',
			success: 'Pronto!',
			error: 'Falha na criação da seção customizada.'
		});
	}

	return(
		<>
			<Row>
				<Col md="12" className="d-flex justify-content-center">
					<Col md="10" className="p-0" style={{backgroundColor: !bannerUrl && "#e9ecef", maxWidth: "820px"}}>
						{bannerUrl 
							? <img width="100%" height="400px" src={bannerUrl} /> 
							: 
								<div className="custom-section-banner">
									<p>Banner principal</p>
								</div>
						}
					</Col>
				</Col>
			</Row>
			<Row className="mt-5">
				<Col md="12" className="d-flex justify-content-center mt-5">
                    <h5>* É recomendado o uso de uma imagem Full HD (1920x1080)</h5>
                </Col>
				<Col md="6">
					<Col md="6">
						<label htmlFor="main-banner">
							<h6>Banner principal:</h6>
						</label>    
					</Col>
					<Col>
						<Input 
							required 
							type="file"
							accept="image/*"
							id="main-banner"
							name="image_preview_url"
							className="form-control"
							onChange={event => handleBannerUpload(event)}
						/>
					</Col>
				</Col>
			</Row> 
			<hr />
		    <Row className="d-flex justify-content-center">
				<Col md="2">
					<Button onClick={createCustomSection}>
						Salvar
					</Button>
				</Col>
			</Row>
		</>
	);
}

export default SingleBannerSection;