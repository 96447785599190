import { useEffect } from "react";
import { useState } from "react";
import Switch from "react-switch";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";

import HomePageService from "services/HomePageService";

const imagePath = process.env.REACT_APP_IMAGE_BASE_URL;

function ListCustomSections() {
    const [customSections, setCustomSections] = useState([]);

    useEffect(() => {
        getCustomSections();
    }, []);

    const getCustomSections = () => {
        HomePageService.getAllCustomSections().then(response => {
            const customSections = response.data;
            setCustomSections(customSections);
        });
    }
    const handleCustomSectionActive = (customSectionId, isActive) => {
        let successMessage = 'Seção não será mais visível na página inicial.';

        if(isActive) {
            successMessage = 'Seção visível na página inicial.'
        }

        toast.promise(HomePageService.setCustomSectionIsActive(customSectionId, isActive), {
            loading: 'Atualizando a visibilidade da seção customizada',
            error: 'Não foi possível atualizar a seção customizada',
            success: successMessage
        }).then(response => {
            const oldCustomSection = response.data.old_active_section;
            const updatedCustomSection = response.data.new_active_section;

            setCustomSections(prevState => (
                prevState.map(section => {
                    if(section.id === customSectionId) {
                        return {
                            ...section,
                            is_active: updatedCustomSection.is_active
                        }
                    }

                    if(section.id === oldCustomSection?.id && oldCustomSection !== null) {
                        return {
                            ...section,
                            is_active: oldCustomSection.is_active
                        }
                    }

                    return section;
                })
            ));
        });;
    }

    const handleDeleteCustomSection = customSectionId => {
        toast.promise(HomePageService.deleteCustomSection(customSectionId), {
            loading: "Excluindo seção customizada",
            error: "Não foi possível deletar a seção customizada",
            success: "Pronto!"
        }).then(() => {
            getCustomSections();
        });
    }

    return(
        <Row className="d-flex justify-content-center">
            <Col md="12" className="mt-2 mb-5">
                <Link to="pagina-principal/secao-customizada">
                    <Button>Criar uma nova seção customizada</Button>
                </Link>
            </Col>

            {customSections?.map(customSection => (
                <Col md="3" className="border mx-4 my-5" key={customSection?.id}>
                    {customSection?.section_type === 2 &&
                       <>
                            <Row>
                                <Col md="12" className="p-0">
                                    <img 
                                        src={imagePath + customSection?.main_banner?.path  + customSection?.main_banner?.name}
                                    />
                                </Col>
                            </Row>
                            <Row style={{ height: "fit-content"}}>
                                <Col md="7" style={{backgroundColor: customSection?.background_color}}>
                                    <img 
                                        className="p-2"
                                        src={imagePath + customSection?.secondary_banner?.path  + customSection?.secondary_banner?.name}
                                    />
                                </Col>
                                <Col md="5" className="d-flex flex-column justify-content-center" style={{backgroundColor: customSection?.background_color}}>
                                    {customSection?.banner_product?.map(product => (
                                        <img 
                                            src={`${process.env.REACT_APP_IMAGE_BASE_URL}${product?.product_data?.image?.path}${product?.product_data?.image?.name}`}
                                            key={product?.product_data?.CodigoInterno_Produto}
                                            style={{maxWidth: 50, maxHeight: 50}}
                                            className="my-1"
                                        />
                                    ))}
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md="5" className="p-0 mr-4">
                                    <Link to={`pagina-principal/secao-customizada/${customSection?.id}/editar`}>
                                        <Button className="btn btn-success">
                                            Editar
                                        </Button>
                                    </Link>
                                </Col>

                                <Col md="5" className="p-0">
                                    <Button 
                                        className="btn btn-danger"
                                        onClick={() => handleDeleteCustomSection(customSection?.id)}
                                    >
                                        Excluir
                                    </Button>
                                </Col>
                            </Row>

                            <Row className="d-flex justify-content-center mr-5">
                                <Col md="3" className="d-flex align-items-center">
                                    <label htmlFor="is-custom-section-active" className="mt-3">
                                        <h6> Ativo </h6>
                                    </label>
                                    <Switch
                                        width={45}
                                        height={20}
                                        className="ml-2"
                                        onColor="#8c5899"
                                        id="is-custom-section-active"
                                        checked={customSection?.is_active}
                                        onChange={value => handleCustomSectionActive(customSection?.id, value)}                                                
                                    />
                                </Col>
                            </Row>

                       </>
                    }
                    {customSection.section_type ===  1 &&
                        <>
                            <Row>
                                <Col md="12" className="p-0">
                                    <img
                                        src={imagePath + customSection?.main_banner?.path  + customSection?.main_banner?.name}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12"
                                    className="d-flex align-items-center"
                                    style={{backgroundColor: customSection?.background_color, height: 200}}
                                >
                                    <Row>
                                        {customSection?.banner_product?.map(product => (
                                            <Col md="4" key={product?.product_data?.CodigoInterno_Produto}>
                                                <img 
                                                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}${product?.product_data?.image?.path}${product?.product_data?.image?.name}`}
                                                    style={{maxWidth: 50, maxHeight: 50}}
                                                    className="my-1"
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md="5" className="p-0 mr-4">
                                    <Link to={`pagina-principal/secao-customizada/${customSection?.id}/editar`}>
                                        <Button className="btn btn-success">
                                            Editar
                                        </Button>
                                    </Link>
                                </Col>

                                <Col md="5" className="p-0">
                                    <Button
                                        className="btn btn-danger"
                                        onClick={() => handleDeleteCustomSection(customSection?.id)} 
                                    >
                                        Excluir
                                    </Button>
                                </Col>
                            </Row>

                            <Row className="d-flex justify-content-center mr-5">
                                <Col md="3" className="d-flex align-items-center">
                                    <label htmlFor="is-custom-section-active" className="mt-3">
                                        <h6> Ativo </h6>
                                    </label>
                                    <Switch
                                        width={45}
                                        height={20}
                                        className="ml-2"
                                        onColor="#8c5899"
                                        id="is-custom-section-active"
                                        checked={customSection?.is_active}
                                        onChange={value => handleCustomSectionActive(customSection?.id, value)}                                                
                                    />
                                </Col>
                            </Row>
                        </>
                    }

                    {customSection.section_type ===  3 &&
                        <>
                            <Row>
                                <Col md="12" className="p-0">
                                    <img 
                                        src={imagePath + customSection?.main_banner?.path  + customSection?.main_banner?.name}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md="5" className="p-0 mr-4">
                                    <Link to={`pagina-principal/secao-customizada/${customSection?.id}/editar`}>
                                        <Button className="btn btn-success">
                                            Editar
                                        </Button>
                                    </Link>
                                </Col>

                                <Col md="5" className="p-0">
                                    <Button
                                        className="btn btn-danger"
                                        onClick={() => handleDeleteCustomSection(customSection?.id)} 
                                    >
                                        Excluir
                                    </Button>
                                </Col>
                            </Row>

                            <Row className="d-flex justify-content-center mr-5">
                                <Col md="3" className="d-flex align-items-center">
                                    <label htmlFor="is-custom-section-active" className="mt-3">
                                        <h6> Ativo </h6>
                                    </label>
                                    <Switch
                                        width={45}
                                        height={20}
                                        className="ml-2"
                                        onColor="#8c5899"
                                        id="is-custom-section-active"
                                        checked={customSection?.is_active}
                                        onChange={value => handleCustomSectionActive(customSection?.id, value)}                                                
                                    />
                                </Col>
                            </Row>
                        </>
                    }
                </Col>
            ))}
        </Row>
    );
}

export default ListCustomSections;