import api from './api';

class PageService {
    /**
     * Get all Pages
     * @returns {Promise}
     */
    async getAllPages() {
        const response = await api.get('admin/pages/index');
        return response.data;
    }

    /**
     * Get page by id
     * @returns {Promise}
     */
    async getPageById(id) {
        const response = await api.get(`admin/pages/${id}/show`);
        return response.data;
    }

    /**
    * Save page by id
     * @returns {Promise}
     */
    async updatePageById(pageId, pageData) {
        const response = await api.post(`admin/pages/${pageId}/update`, pageData);
        return response.data;
    }
}

export default new PageService();
